import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import { AiTwotoneFlag } from 'react-icons/ai';
import { BsFillGearFill } from 'react-icons/bs';
import { GiCargoCrane } from 'react-icons/gi';
import { BsFillFunnelFill, BsCalendarFill } from 'react-icons/bs';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

const images = [
  'https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550223640-23097fc71cb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550353175-a3611868086b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550330039-a54e15ed9d33?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549737328-8b9f3252b927?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549833284-6a7df91c1f65?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549985908-597a09ef0a7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
];

const SimilarVessels = () => {
  return (
    <Carousel
      itemClass='carousel-item-padding-40-px'
      swipeable
      draggable
      responsive={responsive}
    >
      {images.slice(0, 5).map((image) => {
        return (
          <div className='item'>
            <div className='vessel_item' style={{ marginBottom: '40px' }}>
              <div className='image'>
                <a href='#.'>
                  <img
                    src={image}
                    alt='similar vessel'
                    style={{ width: '100%', height: '224px' }}
                    className='img-responsive'
                  />
                </a>
                <span className='tag_t'>For Sale</span>
              </div>
              <div className='vessel_content'>
                <div className='vessel_text'>
                  <h3>
                    <a href='#.'>Historic Town House</a>
                  </h3>
                </div>
                <div className='vessel_meta transparent'>
                  <span>
                    <BsFillGearFill /> 4800 sq ft
                  </span>
                  <span>
                    <GiCargoCrane /> Apapa
                  </span>
                  <span>
                    <AiTwotoneFlag /> Nigeria
                  </span>
                </div>
                <div className='vessel_meta' style={{ marginBottom: '30px' }}>
                  <span>
                    <BsFillFunnelFill /> V Lounge
                  </span>
                </div>
                <div className='favroute clearfix'>
                  <p>
                    <BsCalendarFill /> 5 Days ago
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default SimilarVessels;
