import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { getAuthLetter } from '../../redux/actions';

function LetterOfAuth() {
  const dispatch = useDispatch();

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 1,
    maxSize: 1 * 1024 * 1024,
    accept: 'image/jpeg, image/png, application/pdf',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        dispatch(getAuthLetter(URL.createObjectURL(acceptedFiles[0])));
      } else {
        dispatch(getAuthLetter(''));
      }
    },
  });

  let list = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  useEffect(
    () => () => {
      URL.revokeObjectURL(acceptedFiles[0]);
    },
    [acceptedFiles]
  );

  return (
    <section className='container'>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {isDragAccept && <p>Drop it</p>}
        {isDragReject && <p>This file will be rejected</p>}
        {!isDragActive && (
          <p>Drag and drop your file here, or click to select file</p>
        )}
      </div>
      <aside>{list}</aside>
    </section>
  );
}

export default LetterOfAuth;
