import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Col, Row, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { GiShipWheel } from 'react-icons/gi';
import { CgProfile } from 'react-icons/cg';
import { FaShip } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import ChangePassword from '../Dashboard/ChangePassword';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';
import FileUploader from '../FileUploader';
import { withFirebase } from '../Firebase';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import { mainCollection } from '../../utils';
import TabBar from './TabBar';

const MyDetails = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [selectedFile, setSelectedFile] = useState(
    props.details.profile_picture || '/images/1.jpg'
  );
  const [rawFile, setRawFile] = useState();
  const [displayButton, setDisplayButton] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const updateUserData = async (URL) => {
    const details = props.details;
    details.profile_picture = URL;
    await localStorage.setItem('marexUser', JSON.stringify(details));
    setUploadLoading(false);
    toast.notify('Your profile picture has been set');
  };

  const uploadProfilePic = () => {
    setUploadLoading(true);
    setDisplayButton(false);
    const path = `users/${props.details.UID}/images/profilePicture.jpg`;
    const upload = props.firebase.storage.ref(path).put(rawFile);

    upload.on(
      'state_changed',
      () => {},
      () => {
        setUploadLoading(false);
        toast.notify('An error occured while uploading your picture', {
          duration: null,
        });
      },
      () => {
        upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
          const firestoreRef = props.firebase.firestore
            .collection(mainCollection.USERS)
            .doc(props.details.UID);

          firestoreRef
            .update({
              profile_picture: downloadURL,
            })
            .then(() => {
              setSelectedFile(downloadURL);
              updateUserData(downloadURL);
            });
        });
      }
    );
  };

  const details = props.details;
  return (
    <React.Fragment>
      <Breadcrumb title='My Profile' />
      <div className='dg__market__data pt--50 pb--140'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='market__title'>
                <h2>Dashboard</h2>
              </div>
              <TabBar />
              <Col>
                <Tab.Container defaultActiveKey='details'>
                  <Row>
                    <Col sm='3'>
                      <Nav
                        variant='pills'
                        className='flex-column market__sub_nav justify-content-center'
                      >
                        <Nav.Item>
                          <Nav.Link eventKey='details'>My details</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey='password'>
                            Change password
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm='6'>
                      <Tab.Content>
                        <Tab.Pane eventKey='details'>
                          <form onSubmit={handleSubmit(props.updateDetails)}>
                            <div className='currency__box'>
                              <div className='currency__form'>
                                <div className='input__box name'>
                                  <span>Firstname</span>
                                  <input
                                    disabled
                                    type='text'
                                    name='firstname'
                                    ref={register({
                                      required: true,
                                    })}
                                    defaultValue={details.firstname}
                                  />
                                </div>
                                <div className='input__box name'>
                                  <span>Lastname</span>
                                  <input
                                    disabled
                                    type='text'
                                    name='lastname'
                                    ref={register({
                                      required: true,
                                    })}
                                    defaultValue={details.lastname}
                                  />
                                </div>
                              </div>
                              <div className='currency__form'>
                                <div className='input__box name'>
                                  <span>Phone Number</span>
                                  <input
                                    type='text'
                                    name='phone'
                                    ref={register({
                                      required: true,
                                    })}
                                    defaultValue={details.phone}
                                  />
                                  {errors.phone && (
                                    <span className='errorText'>
                                      <i className='fas fa-exclamation-triangle'></i>{' '}
                                      This field is required
                                    </span>
                                  )}
                                  {/* <PhoneInput
                                            international
                                            defaultCountry="NG"
                                            countrySelectProps={{
                                                unicodeFlags: true,
                                            }}
                                        /> */}
                                </div>
                              </div>
                              <div className='currency__form'>
                                <div className='input__box col-sm-6'>
                                  <span>Account Type</span>
                                  <h4>{details.accountType}</h4>
                                </div>
                                {errors.accountType && (
                                  <span className='errorText'>
                                    <i className='fas fa-exclamation-triangle'></i>{' '}
                                    This field is required
                                  </span>
                                )}
                              </div>

                              <div className='currency__form'>
                                <div className='input__box name'>
                                  <span>Address</span>
                                  <input
                                    defaultValue={details.user_address}
                                    type='text'
                                    name='user_address'
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.user_address && (
                                    <span className='errorText'>
                                      <i className='fas fa-exclamation-triangle'></i>{' '}
                                      This field is required
                                    </span>
                                  )}
                                </div>
                                <div className='input__box name'>
                                  <span>City</span>
                                  <input
                                    type='text'
                                    ref={register({
                                      required: true,
                                    })}
                                    name='user_city'
                                    defaultValue={details.user_city}
                                  />
                                  {errors.user_city && (
                                    <span className='errorText'>
                                      <i className='fas fa-exclamation-triangle'></i>{' '}
                                      This field is required
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='currency__form'>
                                <div className='input__box name'>
                                  <span>Region/State/Province</span>
                                  <input
                                    defaultValue={details.user_state}
                                    type='text'
                                    name='user_state'
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.user_state && (
                                    <span className='errorText'>
                                      <i className='fas fa-exclamation-triangle'></i>{' '}
                                      This field is required
                                    </span>
                                  )}
                                </div>
                                <div className='input__box name'>
                                  <span>Country</span>
                                  <input
                                    defaultValue={details.user_country}
                                    type='text'
                                    name='user_country'
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.user_country && (
                                    <span className='errorText'>
                                      <i className='fas fa-exclamation-triangle'></i>{' '}
                                      This field is required
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='currency__form'>
                                <div className='input__box name'>
                                  <span>Company Name</span>
                                  <input
                                    defaultValue={details.company_name}
                                    type='text'
                                    name='company_name'
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.company_name && (
                                    <span className='errorText'>
                                      <i className='fas fa-exclamation-triangle'></i>{' '}
                                      This field is required
                                    </span>
                                  )}
                                </div>
                                <div className='input__box name'>
                                  <span>Company Address</span>
                                  <input
                                    defaultValue={details.company_address}
                                    type='text'
                                    name='company_address'
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.company_address && (
                                    <span className='errorText'>
                                      <i className='fas fa-exclamation-triangle'></i>{' '}
                                      This field is required
                                    </span>
                                  )}
                                </div>
                                <div className='input__box name'>
                                  <span>Company City</span>
                                  <input
                                    defaultValue={details.company_city}
                                    name='company_city'
                                    type='text'
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.company_city && (
                                    <span className='errorText'>
                                      <i className='fas fa-exclamation-triangle'></i>{' '}
                                      This field is required
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='currency__form'>
                                <div className='input__box name'>
                                  <span>Region/State/Province</span>
                                  <input
                                    defaultValue={details.company_state}
                                    type='text'
                                    name='company_state'
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.company_state && (
                                    <span className='errorText'>
                                      <i className='fas fa-exclamation-triangle'></i>{' '}
                                      This field is required
                                    </span>
                                  )}
                                </div>
                                <div className='input__box name'>
                                  <span>Country</span>
                                  <input
                                    defaultValue={details.company_country}
                                    type='text'
                                    name='company_country'
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.company_country && (
                                    <span className='errorText'>
                                      <i className='fas fa-exclamation-triangle'></i>{' '}
                                      This field is required
                                    </span>
                                  )}
                                </div>
                                <div className='input__box name'>
                                  <span>Designation</span>
                                  <input
                                    defaultValue={details.designation}
                                    type='text'
                                    name='designation'
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.designation && (
                                    <span className='errorText'>
                                      <i className='fas fa-exclamation-triangle'></i>{' '}
                                      This field is required
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='submit__btn'>
                                <button type='submit'>Update</button>
                              </div>
                            </div>
                          </form>
                        </Tab.Pane>
                        <Tab.Pane eventKey='password'>
                          <ChangePassword details={props.details} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                    <Col className='mt-3'>
                      <img
                        style={{ width: '207px', height: '207px' }}
                        src={selectedFile}
                        alt='profile pic'
                      />
                      <div className='white__btn mt-3'>
                        <FileUploader
                          onFileSelectSuccess={(file) => {
                            setSelectedFile(URL.createObjectURL(file));
                            setDisplayButton(true);
                            setRawFile(file);
                          }}
                          onFileSelectError={({ error }) => {
                            alert(error);
                            setDisplayButton(false);
                            setRawFile();
                            setSelectedFile('/images/1.jpg');
                          }}
                        />
                        {displayButton && (
                          <button className='mt-1' onClick={uploadProfilePic}>
                            Upload
                          </button>
                        )}
                        {uploadLoading && (
                          <Col sm={{ size: 6 }} className='mt-3'>
                            <Spinner color='primary' />
                          </Col>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withFirebase(MyDetails);
