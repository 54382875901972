import React, { Component } from 'react';
import blogGridData from '../../data/hero-sliders/blog.json';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Button } from 'reactstrap';
import { changeVesselsStatus } from '../../../src/redux/actions';
import { AiTwotoneFlag } from 'react-icons/ai';
import { GiCargoCrane } from 'react-icons/gi';
import { BsFillFunnelFill, BsCalendarFill } from 'react-icons/bs';

const errorType = 'Error occurred while fetching vessels.';
let checkLength = false;

class VesselsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.onFetchVessels();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vesselsData !== this.props.vesselsData) {
      this.onFetchVessels();
    }
  }

  onFetchVessels() {
    const { vesselsSearchStatus, vesselsData, vesselsSearchError } = this.props;

    const checkError = vesselsSearchError === '';
    const checkList = vesselsData.length === 0;

    if (checkError && !vesselsSearchStatus && checkList) {
      this.setState({ isLoading: true, errorMessage: vesselsSearchError });
    } else if (!checkError && vesselsSearchStatus && checkList) {
      this.setState({ isLoading: false, errorMessage: vesselsSearchError });
    } else if (!checkList) {
      this.setState({ isLoading: false });
    }
  }

  formatText = (text) => {
    if (text.length > 24) {
      checkLength = true;
      return text.slice(0, 20);
    } else {
      return text;
    }
  };

  formatType = (text) => {
    if (text.length > 20) {
      return text.slice(0, 17);
    } else {
      return text;
    }
  };

  handleReload() {
    this.setState({ isLoading: true });
    this.props.changeVesselsStatus(true);
  }

  render() {
    const { vesselsData } = this.props;
    const { errorMessage } = this.state;
    const checkError = errorType === errorMessage;

    return (
      <div className='col-md-9 col-sm-12 col-xs-12'>
        <div
          className='dg__blog__area bg--white'
          style={{ paddingTop: '50px' }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section__title--2'>
                  <h2>Recently Added Vessels</h2>
                </div>
              </div>
            </div>
            <div className='row mt--10'>
              {checkError && (
                <Col sm={{ size: 5, offset: 4 }}>
                  <p>
                    <strong>{errorMessage} </strong>
                    <br />
                    <Col sm={{ size: 12, offset: 3 }}>
                      <Button
                        type='button'
                        color='danger'
                        onClick={() => this.handleReload()}
                      >
                        Reload
                      </Button>
                    </Col>
                  </p>
                </Col>
              )}
              {/* {blogGridData &&
                blogGridData.map((data) => {
                  return (
                    <div className='col-lg-4 col-sm-4 col-12 col-md-4'>
                      <article className='blog__2'>
                        <div className='thumb'>
                          <Link to={data.url}>
                            <img src={data.image} alt='vessel img' />
                          </Link>
                        </div>
                        <span className='tag_t'>For Rent</span>
                        <div className='content'>
                          <ul className='meta'>
                            <li>{data.date}.</li>
                            <li>Speedboat</li>
                          </ul>
                          <h6>
                            <Link to={data.url}>
                              {this.formatText(data.title)}
                              {checkLength && '...'}
                            </Link>
                          </h6>
                        </div>
                      </article>
                    </div>
                  );
                })} */}
              {vesselsData &&
                vesselsData.map((data, idx) => {
                  return (
                    <div
                      key={idx}
                      className='col-lg-4 col-sm-4 col-12 col-md-4'
                    >
                      {/* <article className='blog__2'>
                        <div className='thumb'>
                          <Link to={{ pathname: `/vessel/${data.vesselId}` }}>
                            <img
                              style={{ height: '224px' }}
                              src={data.images[0]}
                              alt='a vessel'
                            />
                          </Link>
                        </div>
                        <span className='tag_t'>{data.status}</span>
                        <div className='content'>
                          <ul className='meta'>
                            <li>
                              {moment
                                .unix(data.createdAt.seconds)
                                .format('Do MMM')}
                              .
                            </li>
                            <li>
                              <strong>
                                {this.formatType(data.vesselType)}
                              </strong>
                            </li>
                          </ul>
                          <h6>
                            <Link to={{ pathname: `/vessel/${data.vesselId}` }}>
                              {this.formatText(data.vessel_name)}
                              {checkLength && '...'}
                            </Link>
                          </h6>
                        </div>
                      </article> */}
                      <div
                        className='vessel_item'
                        style={{ marginBottom: '40px' }}
                      >
                        <div className='image'>
                          <Link to={{ pathname: `/vessel/${data.vesselId}` }}>
                            <img
                              style={{ height: '224px', width: '100%' }}
                              src={data.images[0]}
                              alt='a vessel'
                            />
                          </Link>
                          <span className='tag_t'>{data.status}</span>
                        </div>
                        <div className='vessel_content'>
                          <div className='vessel_text'>
                            <h3>
                              <Link
                                to={{ pathname: `/vessel/${data.vesselId}` }}
                              >
                                {this.formatText(data.vessel_name)}
                                {checkLength && '...'}
                              </Link>
                            </h3>
                          </div>
                          <div className='vessel_meta'>
                            <span>
                              <GiCargoCrane /> {data.port}
                            </span>
                            <span>
                              <AiTwotoneFlag /> {data.flag}
                            </span>
                          </div>
                          <div
                            className='vessel_meta'
                            style={{ marginBottom: '30px' }}
                          >
                            <span>
                              <BsFillFunnelFill /> {data.vesselType}
                            </span>
                          </div>
                          <div className='favroute clearfix'>
                            <p>
                              <BsCalendarFill />{' '}
                              {moment.unix(data.createdAt.seconds).fromNow()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vesselsSearchError: state.vessel.vesselsSearchError,
  vesselsData: state.vessel.vesselsData,
  vesselsSearchStatus: state.vessel.vesselsSearchStatus,
});

const mapDispatchToProps = (dispatch) => ({
  changeVesselsStatus: (vesselsStatus) => {
    dispatch(changeVesselsStatus(vesselsStatus));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VesselsGrid);
