import React from 'react';
import MyVessels from '../../components/Dashboard/MyVessels';
import MetaTags from 'react-meta-tags';
import { withAuthorization } from '../../components/Session';
import MyAdverts from '../../components/Dashboard/MyAdverts';


class AdvertsContainer extends React.Component {
  constructor() {
    super();
    this.state = { details: JSON.parse(localStorage.getItem('marexUser')) };
  }

  componentDidMount() {
    if (!this.state.details) {
      // console.log('You need to fetch again');
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Manage Adverts | Marex</title>
          <meta
            name='description'
            content="Manage the adverts you have placed"
          />
        </MetaTags>
        <MyAdverts details={this.state.details} viewType="manage" />
      </React.Fragment>
    );
  }
}
const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(AdvertsContainer);
