import React, { useState, useEffect } from 'react';
import { Spinner, Col } from 'reactstrap';
import 'react-phone-number-input/style.css';
import { FormText } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import validate from '../../validation';
import FormInput from '../../components/FormInput';
import PhoneInput from 'react-phone-number-input';
import PasswordMask from 'react-password-mask';
import { useSelector } from 'react-redux';

const types = ['Ship Owner', 'Ship Broker'];
const renderAccountTypeSelector = ({ input, meta: { touched, error } }) => (
  <React.Fragment>
    <select {...input}>
      <option value=''>Select your account type...</option>
      {types.map((val) => (
        <option value={val} key={val}>
          {val}
        </option>
      ))}
    </select>
    {touched && error && (
      <FormText className='help-block error-color'>{error}</FormText>
    )}
  </React.Fragment>
);

const phoneSelector = ({ input, meta: { touched, error } }) => (
  <React.Fragment>
    <PhoneInput
      {...input}
      international
      defaultCountry='NG'
      countrySelectProps={{ unicodeFlags: true }}
      placeholder='Enter phone number'
    />

    {touched && error && (
      <FormText className='help-block errorText'>{error}</FormText>
    )}
  </React.Fragment>
);

const passwordSelector = ({ input, meta: { touched, error } }) => {
  return (
    <React.Fragment>
      <PasswordMask {...input} />
      {touched && error && (
        <FormText className='help-block errorText'>{error}</FormText>
      )}
    </React.Fragment>
  );
};

const GeneralForm = (props) => {
  const loadingStatus = useSelector((store) => store.auth.registrationLoading);
  const { handleSubmit, emailError, phoneError } = props;

  return (
    <form onSubmit={handleSubmit} style={{ marginTop: '15px' }}>
      <div className='row'>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field
              name='email'
              type='email'
              label='Email address'
              component={FormInput}
            />
            {emailError && (
              <FormText className='help-block errorText'>
                Email is registered to another account
              </FormText>
            )}
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='input__box'>
            <span>Phone Number</span>
            <Field name='phone' component={phoneSelector} />
            {phoneError && (
              <FormText className='help-block errorText'>
                Phone number is registered to another account
              </FormText>
            )}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-4'>
          <div className='input__box'>
            <span>Password</span>
            <Field name='password' component={passwordSelector} />
          </div>
        </div>
        <div className='col-sm-4'>
          <div className='input__box'>
            <span>Confirm Password</span>
            <Field name='password_confirmation' component={passwordSelector} />
          </div>
        </div>
        <div className='col-sm-7'>
          <div className='input__box'>
            <span>Account Type</span>
            <Field name='accountType' component={renderAccountTypeSelector} />
          </div>
        </div>
        {loadingStatus ? (
          <Col sm={{ size: 6, offset: 6 }} className='mt-3'>
            <Spinner color='primary' />
          </Col>
        ) : (
          <button
            className='account__btn'
            type='submit'
            style={{ marginTop: '21px', width: '100%' }}
          >
            Next
          </button>
        )}

        <Link className='forget__pass mt-3' to='/login'>
          Already with Oceanic Links? Login now
        </Link>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'wizardForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(GeneralForm);
