import React, { useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { useForm } from 'react-hook-form';
import { VESSELS } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { changeSearchVessel, changeSearchFilters } from '../../redux/actions';

export default function BasicSearch() {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchVessel = useSelector((store) => store.vessel.searchVessel);
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    let stringer = `vesselType:"${searchVessel}"`;
    if (data.grossMin) {
      stringer += ` AND gross_tonnage:${data.grossMin}`;
    }
    if (data.grossMin && data.grossMax) {
      stringer += ` TO ${data.grossMax}`;
    }
    if (!data.grossMin && data.grossMax) {
      stringer += ` AND gross_tonnage:0 TO ${data.grossMax}`;
    }

    const filters = {
      vesselType: searchVessel,
      grossMin: Number(data.grossMin),
      grossMax: Number(data.grossMax),
      configureFilter: stringer,
    };
    dispatch(changeSearchFilters(filters));
    history.push('/search');
  };

  const changing = (e) => {
    dispatch(changeSearchVessel(e));
  };

  return (
    <div className='container' id='searchBox'>
      <div className='main-search-form'>
        <div className='row'>
          <div className='col-sm-4'>
            <div className='input__box'>
              <span className='blueText'>Search Vessel Type</span>
              <SelectSearch
                options={VESSELS}
                onChange={changing}
                search
                filterOptions={fuzzySearch}
                emptyMessage='No result'
                value='Accommodation Jack Up'
                placeholder='Select a type of vessel'
              />
            </div>
          </div>
          <div className='col-sm-2'>
            <span className='blueText'>Gross Tonnage (Min)</span>
            <input
              type='number'
              name='grossMin'
              min={0}
              style={{ textAlign: 'center' }}
              className='search-input'
              ref={register({})}
            />
          </div>
          <div className='col-sm-2'>
            <span className='blueText'>Gross Tonnage (Max)</span>
            <input
              type='number'
              name='grossMax'
              min={0}
              style={{ textAlign: 'center' }}
              className='search-input'
              ref={register({})}
            />
          </div>
          <div className='col-sm-2 offset-sm-1'>
            <span></span>
            <Link
              style={{ width: '100%', marginTop: 24 }}
              onClick={handleSubmit(onSubmit)}
              className='yellowBtn'
            >
              Search Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
