import React, { useState } from 'react';
import { Spinner, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { withFirebase } from '../Firebase';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';

const ChangePassword = (props) => {
  const { register, handleSubmit, errors, watch, setError, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    const credential = props.firebase.emailProvider.credential(
      props.details.email,
      data.current
    );

    const currentUser = props.firebase.auth.currentUser;
    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        currentUser
          .updatePassword(data.new)
          .then(() => {
            setLoading(false);
            reset();
            toast.notify('Your password has been successfully changed', {
              duration: null,
            });
          })
          .catch((error) => {
            setLoading(false);
            toast.notify(`${error.message}`, {
              duration: null,
            });
            setError('confirm', {
              type: 'manual',
              message: 'An error occured',
            });
          });
      })
      .catch((err) => {
        setLoading(false);
        if (err.code === 'auth/too-many-requests') {
          toast.notify(`${err.message}`, {
            duration: null,
          });
          setError('confirm', {
            type: 'manual',
            message: `An error occured`,
          });
        } else {
          setError('current', {
            type: 'manual',
            message: 'An error occured',
          });
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='currency__box'>
        <div className='input__box name'>
          <span>Current password</span>
          <input
            type='password'
            name='current'
            ref={register({
              required: true,
            })}
          />
          {errors.current && errors.current.type === 'required' && (
            <span className='errorText'>
              <i className='fas fa-exclamation-triangle'></i> This field is
              required
            </span>
          )}
          {errors.current && errors.current.type === 'manual' && (
            <span className='errorText'>
              <i className='fas fa-exclamation-triangle'></i> Your current
              password is incorrect
            </span>
          )}
        </div>
        <div className='input__box name'>
          <span>New password</span>
          <input
            type='password'
            name='new'
            ref={register({
              required: true,
              pattern: /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z]).{8,}$/,
            })}
          />
          {errors.new && errors.new.type === 'required' && (
            <span className='errorText'>
              <i className='fas fa-exclamation-triangle'></i> This field is
              required
            </span>
          )}
          {errors.new && errors.new.type === 'pattern' && (
            <span className='errorText'>
              <i className='fas fa-exclamation-triangle'></i> Your password must
              be 8 characters long, contain a digit, lower and upper case letter
            </span>
          )}
        </div>
        <div className='input__box name'>
          <span>Confirm password</span>
          <input
            type='password'
            name='confirm'
            ref={register({
              required: true,
              pattern: /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z]).{6,}$/,
              validate: (value) =>
                value === watch('new') || "Passwords don't match",
            })}
          />
          {errors.confirm && errors.confirm.type === 'required' && (
            <span className='errorText'>
              <i className='fas fa-exclamation-triangle'></i> This field is
              required
            </span>
          )}
          {errors.confirm && errors.confirm.type === 'validate' && (
            <span className='errorText'>
              <i className='fas fa-exclamation-triangle'></i> Passwords don't
              match
            </span>
          )}
          {errors.confirm && errors.confirm.type === 'pattern' && (
            <span className='errorText'>
              <i className='fas fa-exclamation-triangle'></i> Your password must
              be 6 characters long, contain a digit, lower and upper case letter
            </span>
          )}
          {errors.confirm && errors.confirm.type === 'manual' && (
            <span className='errorText'>
              <i className='fas fa-exclamation-triangle'></i> An eror occured
            </span>
          )}
        </div>
        {loading ? (
          <Col sm={{ size: 6 }} className='mt-3'>
            <Spinner color='primary' />
          </Col>
        ) : (
          <div className='submit__btn'>
            <button>Change password</button>
          </div>
        )}
      </div>
    </form>
  );
};

export default withFirebase(ChangePassword);
