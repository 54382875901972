import React from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import ParticleTwo from '../particles/ParticleTwo';

const MainSliderSingle = ({ data }) => {
  const scroll = Scroll.animateScroll;
  return (
    <div
      className='slideFam d-flex align__center poss--relative'
      style={{
        backgroundImage: `url(${data.backgroundImage})`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
      }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-xl-7 col-md-12 col-sm-12 col-12 d-flex align-items-center'>
            <div className='slide__inner'>
              <h1>{data.title}</h1>
              <p>{data.text}</p>
              <Link
                className='slide__btn dg__btn btn--white btn--theme'
                to='#'
                onClick={() => scroll.scrollTo(700)}
              >
                View Vessels
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ParticleTwo />
    </div>
  );
};

export default MainSliderSingle;
