//For Authentication
export const GET_TOKEN = 'GET_TOKEN';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_TEST = 'SET_TEST';
export const CHANGE_REGISTRATION_LOADING = 'CHANGE_REGISTRATION_LOADING';
export const SHOW_OTP_MODAL = 'SHOW_OTP_MODAL';
export const SET_CAPTCHA_ID = 'SET_CAPTCHA_ID';

//FOR USER
export const USER_1 = 'USER_1';
export const USER_2 = 'USER_2';
export const USER_3 = 'USER_3';
export const USER_4 = 'USER_4';
export const USER_5 = 'USER_5';
export const USER_VESSELS_DATA = 'USER_VESSELS_DATA';
export const GET_USER_VESSELS_SEARCH_STATUS = 'GET_USER_VESSELS_SEARCH_STATUS';
export const GET_USER_VESSELS_SEARCH_ERROR = 'GET_USER_VESSELS_SEARCH_ERROR';
export const RELOAD_USER_VESSELS = 'RELOAD_USER_VESSELS';
export const LOAD_MORE_USER_VESSELS = 'LOAD_MORE_USER_VESSELS';

//For Vessel Registration
export const GET_VESSEL_REG_IMAGES = 'GET_VESSEL_REG_IMAGES';
export const GET_LETTER_OF_AUTH = 'GET_LETTER_OF_AUTH';

//FOR vessels
export const MY_VESSELS_DATA = 'MY_VESSELS_DATA';
export const GET_MY_VESSELS_SEARCH_STATUS = 'GET_MY_VESSELS_SEARCH_STATUS';
export const GET_MY_VESSELS_SEARCH_ERROR = 'GET_MY_VESSELS_SEARCH_ERROR';
export const RELOAD_MY_VESSELS = 'RELOAD_MY_VESSELS';
export const CHANGE_SEARCH_QUERY = 'CHANGE_SEARCH_QUERY';
export const CHANGE_BASIC_SEARCH_VESSEL = 'CHANGE_BASIC_SEARCH_VESSEL';
export const CHANGE_SEARCH_FILTERS = 'CHANGE_SEARCH_FILTERS';
export const CHANGE_SELECTED_DATA = 'CHANGE_SELECTED_DATA';
export const CHANGE_SELECTED_VESSEL = 'CHANGE_SELECTED_VESSEL';
export const CHANGE_FORMAT_TEXT_LENGTH = 'CHANGE_FORMAT_TEXT_LENGTH';
export const SET_VESSEL_DOC_URL = 'SET_VESSEL_DOC_URL';
export const GET_LAST_RECORD = 'GET_LAST_RECORD';

export const VESSELS_DATA = 'VESSELS_DATA';
export const GET_VESSELS_SEARCH_STATUS = 'GET_VESSELS_SEARCH_STATUS';
export const GET_VESSELS_SEARCH_ERROR = 'GET_VESSELS_SEARCH_ERROR';
export const RELOAD_VESSELS = 'RELOAD_VESSELS';

//for document uploads
export const GET_INCORP_CERT_BLOB_IMG = 'GET_INCORP_CERT_BLOB_IMG';
export const GET_CAC7_BLOB_IMG = 'GET_CAC7_BLOB_IMG';


// for adverts
export const GET_ADVERT_PACKAGES = "GET_ADVERT_PACKAGES";
export const GET_ADVERTS = "GET_ADVERTS";

export const GET_STICKY = 'GET_STICKY';