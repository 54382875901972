import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withFirebase } from '../Firebase';
import {
  Link,
  withRouter,
  useHistory,
  useParams,
  Redirect,
} from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { compose } from 'recompose';
import _ from 'lodash';
import moment from 'moment';
import { withAuthorization } from '../Session';
import { changeSelectedData } from '../../redux/actions';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import withReactContent from 'sweetalert2-react-content';
import { GiShipWheel } from 'react-icons/gi';
import MetaTags from 'react-meta-tags';
import { CgProfile } from 'react-icons/cg';
import { Row, Col, Spinner } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { FaShip } from 'react-icons/fa';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import ReactTooltip from 'react-tooltip';
import { FcAbout } from 'react-icons/fc';
import VesselImages from './RegisterVesselImage';
import LetterOfAuth from './LetterOfAuth';
import 'react-datepicker/dist/react-datepicker.css';
import Slidewithfade from '../../components/Ui/slider';
import { VESSELS } from '../../utils';

const MySwal = withReactContent(Swal);

const EditVessel = (props) => {
  const dispatch = useDispatch();
  const { uid } = useParams();
  const history = useHistory();
  const selectedData = useSelector((store) => store.vessel.selectedData);
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [processing, setProccessing] = useState(false);
  const [yearBuilt, setYearBuilt] = useState(null);
  const [engineYear, setEngineYear] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [details] = useState(JSON.parse(localStorage.getItem('marexUser')));

  const [brokerAcc, setBrokerAcc] = useState(false);
  const authLetterBlob = useSelector((store) => store.vessel.authLetter);
  let urls = [];
  let imageBlobs = [];
  let vesselImages = [];
  const accountType = details.accountType;

  useEffect(() => {
    if (accountType === 'Broker') {
      setBrokerAcc(true);
    }
  }, [accountType]);

  var getFileBlob = async function (url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.addEventListener('load', function () {
      cb(xhr.response);
    });
    xhr.send();
  };

  const uploadVesselImgsPromise = (imgFile, vesselId) => {
    const path = `vessels/${vesselId}/images/`;
    return new Promise((resolve, reject) => {
      if (!imgFile) return reject('No image(s) to upload');
      props.firebase.storage
        .ref(path + imgFile.name)
        .put(imgFile)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            vesselImages.push(downloadURL);
            resolve(downloadURL);
          });
        });
    });
  };

  const uploadLetter = async (imgURL, vesselId) => {
    return new Promise(async (resolve, reject) => {
      await getFileBlob(imgURL, (blob) => {
        let path = '';
        if (blob.type.endsWith('pdf')) {
          path = `vessels/${vesselId}/docs/authLetter.pdf`;
        } else if (blob.type.endsWith('jpeg') || blob.type.endsWith('png')) {
          path = `vessels/${vesselId}/docs/authLetter.jpeg`;
        }

        const upload = props.firebase.storage.ref(path).put(blob);
        upload.on(
          'state_changed',
          () => [],
          (err) => {
            reject(err);
          },
          async () => {
            await upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    });
  };

  const uploadVesselDocsPromise = (docFile, vesselId) => {
    const path = `vessels/${vesselId}/docs/`;
    return new Promise((resolve, reject) => {
      if (!docFile) return reject('No document to upload');
      props.firebase.storage
        .ref(path + docFile.name)
        .put(docFile)
        .then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then((downloadURL) => {
            urls.push(downloadURL);
            resolve(downloadURL);
          });
        });
    });
  };

  const deleteFolderContents = async (path) => {
    const ref = props.firebase.storage.ref(path);
    return ref
      .listAll()
      .then((dir) => {
        dir.items.forEach((fileRef) => {
          deleteFile(ref.fullPath, fileRef.name);
        });
        dir.prefixes.forEach((folderRef) => {
          deleteFolderContents(folderRef.fullPath);
        });
      })
      .catch((error) => {
        toast.notify(`${error.message}`, {
          duration: null,
        });
      });
  };

  const deleteFile = (pathToFile, fileName) => {
    const ref = props.firebase.storage.ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete();
  };

  const onSubmit = async (data) => {
    setProccessing(true);
    data.vesselId = selectedData.vesselId;
    if (brokerAcc && authLetterBlob) {
      await uploadLetter(authLetterBlob, selectedData.vesselId).then((url) => {
        data.authorization_letter = url;
      });
    } else {
      data.authorization_letter = selectedData.authorization_letter || '';
    }

    if (!_.isEmpty(acceptedFiles)) {
      const docPath = `vessels/${selectedData.vesselId}/docs/`;
      await deleteFolderContents(docPath).then(() => {
        Promise.all(
          acceptedFiles.map((item) =>
            uploadVesselDocsPromise(item, selectedData.vesselId)
          )
        )
          .then(() => {
            return (data.documents = urls);
          })
          .catch(() => {
            return toast.notify(
              `An error occured while uploading vessel documents`,
              {
                duration: null,
              }
            );
          });
      });
    } else {
      data.documents = selectedData.documents;
    }

    if (!_.isEmpty(imageBlobs)) {
      const imgPath = `vessels/${selectedData.vesselId}/images/`;
      await deleteFolderContents(imgPath).then(() => {
        return Promise.all(
          imageBlobs.map((imgItem) =>
            uploadVesselImgsPromise(imgItem, selectedData.vesselId)
          )
        )
          .then(() => {
            return (data.images = vesselImages);
          })
          .catch(() => {
            toast.notify(`An error occured while uploading vessel images`, {
              duration: null,
            });
            return false;
          });
      });
    } else {
      data.images = selectedData.images;
    }

    data.vessel_name = data.vessel_name.trim();
    data.disabled = true;
    data.accountId = details.UID;
    data.createdAt = selectedData.createdAt;
    data.year_built = Number(moment(yearBuilt).format('YYYY'));
    data.engines_year = Number(moment(engineYear).format('YYYY'));
    try {
      props.firebase
        .registerVessel(data)
        .then(() => {
          setProccessing(false);
          MySwal.fire({
            title: 'Vessel details Updated!',
            text:
              'Your vessel has been temporarily disabled, our team will verify these new details before it will be available on the platform.',
            confirmButtonText: 'Manage Fleet',
            icon: 'success',
          }).then(() => {
            files = [];
            history.push('/manage-fleet');
          });
        })
        .catch(async (error) => {
          const imgPath = `vessels/${selectedData.vesselId}/images/`;
          const docPath = `vessels/${selectedData.vesselId}/docs/`;
          await deleteFolderContents(imgPath);
          await deleteFolderContents(docPath);
          MySwal.fire({
            title: 'Error',
            text: `An error occured while trying to update your vessel. ${error.message}`,
            icon: 'error',
          });
        });
    } catch (e) {}
  };

  const handleVesselImagesChange = (images) => {
    imageBlobs = images;
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
    maxFiles: 2,
    maxSize: 1 * 1024 * 1024,
  });

  let files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  useEffect(() => {
    const fecthDetails = (uid) => {
      props.firebase
        .fetchVesselDetails(uid)
        .get()
        .then((doc) => {
          setIsLoading(false);
          if (doc.exists) {
            dispatch(changeSelectedData(doc.data()));
          } else {
            setNotFound(true);
          }
        });
    };
    if (!_.isEmpty(selectedData)) {
      //check if account id is d same as logged in user
      if (selectedData.accountId !== details.UID) {
        history.push('/manage-fleet');
      }
      //check if the id in selected data is d same as url id, if not fetch again
      else if (selectedData.vesselId !== uid) {
        setIsLoading(true);
        fecthDetails(uid);
      }
    }
    //check is selected data is empty, if it is fetch from db
    if (_.isEmpty(selectedData)) {
      setIsLoading(true);
      fecthDetails(uid);
    }
  }, [
    props.selectedData,
    details.UID,
    history,
    props.firebase,
    setNotFound,
    dispatch,
    selectedData,
    uid,
  ]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Vessel | Marex</title>
        <meta name='description' content='Edit your vessel' />
      </MetaTags>
      {notFound && <Redirect to='/pages-404' />}
      <div className='dg__market__data pt--130 pb--140'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='market__title'>
                <h2>Dashboard</h2>
              </div>
              <div className='market__filter'>
                <ul className='f-p-links margin_bottom'>
                  <li>
                    <Link to='/dashboard'>
                      <CgProfile />
                      <b> Profile</b>
                    </Link>
                  </li>
                  <li>
                    <Link to='/manage-fleet' className='active'>
                      <GiShipWheel />
                      <b>Manage Fleet</b>
                    </Link>
                  </li>
                  <li>
                    <Link to='/register-vessel'>
                      <FaShip />
                      <b>Register Vessel</b>
                    </Link>
                  </li>
                </ul>
              </div>
              {isLoading ? (
                <Row>
                  <Col sm='5' />
                  <Col sm='3'>
                    <Spinner
                      type='grow'
                      style={{ width: '9rem', height: '9rem' }}
                      color='primary'
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col sm='3'>
                    {selectedData.images && (
                      <Slidewithfade images={selectedData.images} />
                    )}
                  </Col>
                  <Col sm={{ size: 9 }}>
                    <h4>Flag and Class Info</h4>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Row className='currency__box'>
                        <Col sm='6'>
                          <div className='input__box name'>
                            <span>Vessel Name</span>
                            <input
                              type='text'
                              name='vessel_name'
                              ref={register({
                                required: true,
                              })}
                              defaultValue={selectedData.vessel_name}
                            />
                            {errors.vessel_name && (
                              <span className='errorText'>
                                <i className='fas fa-exclamation-triangle'></i>{' '}
                                This field is required
                              </span>
                            )}
                          </div>
                        </Col>
                        <Col sm='6'>
                          <div className='input__box name'>
                            <span>Port of Registry</span>
                            <input
                              type='text'
                              name='port'
                              ref={register({
                                required: true,
                              })}
                              defaultValue={selectedData.port}
                            />
                            {errors.port && (
                              <span className='errorText'>
                                <i className='fas fa-exclamation-triangle'></i>{' '}
                                This field is required
                              </span>
                            )}
                          </div>
                        </Col>
                        <Col sm='6'>
                          <div className='input__box name'>
                            <span>Flag</span>
                            <input
                              type='text'
                              name='flag'
                              ref={register({
                                required: true,
                              })}
                              defaultValue={selectedData.flag}
                            />
                            {errors.flag && errors.flag.type === 'required' && (
                              <span className='errorText'>
                                <i className='fas fa-exclamation-triangle'></i>{' '}
                                This field is required
                              </span>
                            )}
                          </div>
                        </Col>
                        <Col sm='6'>
                          <div className='input__box name'>
                            <span>Official Number</span>
                            <input
                              type='text'
                              name='official_number'
                              ref={register({
                                required: true,
                              })}
                              defaultValue={selectedData.official_number}
                            />
                            {errors.official_number &&
                              errors.official_number.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='6'>
                          <div className='input__box name'>
                            <span>Type of Vessel</span>
                            <select
                              className='dropdown'
                              name='vesselType'
                              ref={register({
                                required: true,
                              })}
                              defaultValue={selectedData.vesselType}
                            >
                              <option value=''>- Select -</option>
                              {VESSELS.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.vesselType &&
                              errors.vesselType.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='6'>
                          <div className='input__box name'>
                            <span>Status</span>
                            <select
                              className='dropdown'
                              name='status'
                              ref={register({
                                required: true,
                              })}
                              defaultValue={selectedData.status}
                            >
                              <option value=''>- Select -</option>
                              <option value='For Lease'>For Lease</option>
                              <option value='For Sale'>For Sale</option>
                            </select>
                            {errors.status &&
                              errors.status.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='6'>
                          <div className='input__box name'>
                            <span>Call Sign</span>
                            <input
                              type='text'
                              name='call_sign'
                              ref={register({
                                required: true,
                              })}
                              defaultValue={selectedData.call_sign}
                            />
                            {errors.call_sign &&
                              errors.call_sign.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='6'>
                          <div className='input__box name'>
                            <span>IMO Number</span>
                            <input
                              type='text'
                              name='imo_number'
                              ref={register({
                                required: true,
                              })}
                              defaultValue={selectedData.imo_number}
                            />
                            {errors.imo_number && (
                              <span className='errorText'>
                                <i className='fas fa-exclamation-triangle'></i>{' '}
                                This field is required
                              </span>
                            )}
                          </div>
                        </Col>
                        <Col sm='6'>
                          <div className='input__box name'>
                            <span>Previous Name</span>
                            <input
                              type='text'
                              name='previous_name'
                              ref={register()}
                              defaultValue={selectedData.previous_name}
                            />
                          </div>
                        </Col>
                        <Col sm='6'>
                          <div className='input__box name'>
                            <span>Previous Flag</span>
                            <input
                              type='text'
                              name='previous_flag'
                              ref={register()}
                              defaultValue={selectedData.previous_flag}
                            />
                          </div>
                        </Col>
                        <Col sm='6'>
                          <div className='input__box name'>
                            <span>Classification Society (if any)</span>
                            <input
                              type='text'
                              name='class_society'
                              ref={register()}
                              defaultValue={selectedData.class_society}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className='currency__box'>
                        <Col sm='12'>
                          <h4>Vessel documents</h4>
                        </Col>
                        <Col sm='12'>
                          <div className='input__box name'>
                            <span>
                              Update the images of the Vessel (this will replace
                              all already uploaded images){' '}
                              <FcAbout data-tip='a maximum of 5 images of less than 1MB each can be uploaded'></FcAbout>
                              <ReactTooltip
                                place='top'
                                type='dark'
                                effect='solid'
                              />
                            </span>
                            <VesselImages
                              onVesselImagesChange={handleVesselImagesChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className='currency__box'>
                        <Col sm='12'>
                          <div className='input__box name'>
                            <span>
                              Certificate of Flag Registry and Classification
                              Society Certificate (if any){' '}
                              <FcAbout data-tip='only images or pdf files of less than 1MB are allowed'></FcAbout>
                              <ul>
                                {selectedData.documents &&
                                  selectedData.documents.map((item) => {
                                    return (
                                      <li key={item}>
                                        <a
                                          href={item}
                                          rel='noopener noreferrer'
                                          target='_blank'
                                        >
                                          Uploaded Document
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                              <ReactTooltip
                                place='top'
                                type='dark'
                                effect='solid'
                              />
                            </span>
                            <section className='container'>
                              <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                {isDragAccept && <p>Drop it</p>}
                                {isDragReject && (
                                  <p>This file will be rejected</p>
                                )}
                                {!isDragActive && (
                                  <p>
                                    Drag and drop your documents here, or click
                                    to select files
                                  </p>
                                )}
                              </div>
                              <aside>
                                <ul>{files}</ul>
                              </aside>
                            </section>
                          </div>
                        </Col>
                      </Row>
                      {brokerAcc && (
                        <Row className='currency__box'>
                          <Col sm='12'>
                            <div className='input__box name'>
                              <span>
                                Re-upload Letter of authorisation{' '}
                                <FcAbout data-tip='only images or pdf files of less than 1MB are allowed'></FcAbout>
                                <ul>
                                  <li key={selectedData.authorization_letter}>
                                    <a
                                      href={selectedData.authorization_letter}
                                      rel='noopener noreferrer'
                                      target='_blank'
                                    >
                                      Uploaded Document
                                    </a>
                                  </li>
                                </ul>
                                <ReactTooltip
                                  place='top'
                                  type='dark'
                                  effect='solid'
                                />
                              </span>
                              <LetterOfAuth />
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row className='currency__box'>
                        <Col sm='12'>
                          <h4>Build Info</h4>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>Number of Decks</span>
                            <input
                              type='number'
                              min={0}
                              max={100}
                              name='decks'
                              ref={register({ required: true })}
                              defaultValue={selectedData.decks}
                            />
                            {errors.decks && errors.decks.type === 'required' && (
                              <span className='errorText'>
                                <i className='fas fa-exclamation-triangle'></i>{' '}
                                This field is required
                              </span>
                            )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>Number of Masts</span>
                            <input
                              type='number'
                              min={0}
                              max={100}
                              name='masts'
                              ref={register({ required: true })}
                              defaultValue={selectedData.masts}
                            />
                            {errors.masts && errors.masts.type === 'required' && (
                              <span className='errorText'>
                                <i className='fas fa-exclamation-triangle'></i>{' '}
                                This field is required
                              </span>
                            )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>
                              Year Built{' '}
                              <b>
                                <i>{selectedData.year_built}</i>
                              </b>
                            </span>
                            {/* <input
                              type='text'
                              name='year_built'
                              ref={register({ required: true })}
                              defaultValue={selectedData.year_built}
                            /> */}
                            <DatePicker
                              onChange={(date) => setYearBuilt(date)}
                              selected={yearBuilt}
                              maxDate={new Date()}
                              showYearPicker
                              dateFormat='yyyy'
                              value={yearBuilt}
                              name='year_built'
                              ref={register({ required: true })}
                            />
                            {errors.year_built &&
                              errors.year_built.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>Builders</span>
                            <input
                              type='text'
                              name='builders'
                              ref={register({ required: true })}
                              defaultValue={selectedData.builders}
                            />
                            {errors.builders &&
                              errors.builders.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>Material of Build</span>
                            <input
                              type='text'
                              name='build_material'
                              ref={register({ required: true })}
                              defaultValue={selectedData.build_material}
                            />
                            {errors.build_material &&
                              errors.build_material.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>Number of Engines</span>
                            <input
                              type='number'
                              min={0}
                              max={20}
                              name='engines'
                              ref={register({ required: true })}
                              defaultValue={selectedData.engines}
                            />
                            {errors.engines &&
                              errors.engines.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>Type of Engines</span>
                            <input
                              type='text'
                              name='engine_type'
                              ref={register({ required: true })}
                              defaultValue={selectedData.engine_type}
                            />
                            {errors.engine_type &&
                              errors.engine_type.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>
                              Year of Engines Built{' '}
                              <b>
                                <i>{selectedData.engines_year}</i>
                              </b>
                            </span>
                            {/* <input
                              type='text'
                              name='engines_year'
                              ref={register({ required: true })}
                              defaultValue={selectedData.engines_year}
                            /> */}
                            <DatePicker
                              onChange={(date) => setEngineYear(date)}
                              selected={engineYear}
                              maxDate={new Date()}
                              showYearPicker
                              dateFormat='yyyy'
                              value={engineYear}
                              name='engines_year'
                              ref={register({ required: true })}
                            />
                            {errors.engines_year &&
                              errors.engines_year.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>Place of Engines Built</span>
                            <input
                              type='text'
                              name='engines_place_built'
                              ref={register({ required: true })}
                              defaultValue={selectedData.engines_place_built}
                            />
                            {errors.engines_place_built &&
                              errors.engines_place_built.type ===
                                'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>Engine Make</span>
                            <input
                              type='text'
                              name='engine_make'
                              ref={register({ required: true })}
                              defaultValue={selectedData.engine_make}
                            />
                            {errors.engine_make &&
                              errors.engine_make.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>
                              Engine Output <i>(kW)</i>
                            </span>
                            <input
                              type='number'
                              name='engineOutput'
                              ref={register({ required: true })}
                              defaultValue={selectedData.engineOutput}
                            />
                            {errors.engineOutput &&
                              errors.engineOutput.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Row className='currency__box'>
                        <Col sm='12'>
                          <h4>Tonnage</h4>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>
                              Length Overall <i>(m)</i>
                            </span>
                            <input
                              type='number'
                              name='length'
                              ref={register({ required: true })}
                              defaultValue={selectedData.length}
                            />
                            {errors.length &&
                              errors.length.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>
                              Breadth <i>(m)</i>
                            </span>
                            <input
                              type='number'
                              name='breadth'
                              ref={register({ required: true })}
                              defaultValue={selectedData.breadth}
                            />
                            {errors.breadth &&
                              errors.breadth.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='4'>
                          <div className='input__box name'>
                            <span>
                              Moulded Depth <i>(m)</i>
                            </span>
                            <input
                              type='number'
                              name='moulded_depth'
                              ref={register({ required: true })}
                              defaultValue={selectedData.moulded_depth}
                            />
                            {errors.moulded_depth &&
                              errors.moulded_depth.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='3'>
                          <div className='input__box name'>
                            <span>Gross Tonnage</span>
                            <input
                              type='number'
                              name='gross_tonnage'
                              defaultValue={selectedData.gross_tonnage}
                              ref={register({ required: true })}
                            />
                            {errors.gross_tonnage &&
                              errors.gross_tonnage.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='3'>
                          <div className='input__box name'>
                            <span>Net Tonnage</span>
                            <input
                              type='number'
                              name='net_tonnage'
                              ref={register({ required: true })}
                              defaultValue={selectedData.net_tonnage}
                            />
                            {errors.net_tonnage &&
                              errors.net_tonnage.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='3'>
                          <div className='input__box name'>
                            <span>Deadweight</span>
                            <input
                              type='number'
                              name='deadweight'
                              ref={register({ required: true })}
                              defaultValue={selectedData.deadweight}
                            />
                            {errors.deadweight &&
                              errors.deadweight.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm='3'>
                          <div className='input__box name'>
                            <span>Lightship Weight</span>
                            <input
                              type='number'
                              name='lightship_weight'
                              ref={register({ required: true })}
                              defaultValue={selectedData.lightship_weight}
                            />
                            {errors.lightship_weight &&
                              errors.lightship_weight.type === 'required' && (
                                <span className='errorText'>
                                  <i className='fas fa-exclamation-triangle'></i>{' '}
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        {processing ? (
                          <Col sm={{ size: 6 }} className='mt-3'>
                            <Spinner color='primary' />
                          </Col>
                        ) : (
                          <Col>
                            <div className='submit__btn'>
                              <button type='submit'>Update</button>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </form>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withRouter,
  withAuthorization(condition),
  withFirebase
)(EditVessel);
