import { isPossiblePhoneNumber } from "react-phone-number-input";

const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
        errors.firstname = "First Name Required";
    }

    if (!values.lastname) {
        errors.lastName = "Last Name Required";
    }

    if (!values.email) {
        errors.email = "Email Address Required";
    }

    if (!values.phone) {
        errors.phone = "Phone Number Required";
    }

    // if (values.phone) {
    //     isPossiblePhoneNumber(values.phone)
    //         ? ""
    //         : (errors.phone = "Wrong Phone Number Format");
    // }

    if (!values.userName) {
        errors.userName = "Username Required";
    }

    if (!values.password) {
        errors.password = "Password Required";
    }

    if (!values.password_confirmation) {
        errors.password_confirmation = "Passwords must match";
    }

    if (values.password_confirmation !== values.password) {
        errors.password_confirmation = "Passwords must match";
    }

    const passwordRegex = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z]).{6,}$/;
    if (!passwordRegex.test(values.password)) {
        errors.password =
            "Your password must be 6 characters long, contain a digit, lower and upper case letter";
    }

    if (!values.accountType) {
        errors.accountType = "Please select an Account Type";
    }

    if (!values.user_address) {
        errors.user_address = "Address is Required";
    }

    if (!values.user_state) {
        errors.user_state = "Region/State/Province is Required";
    }

    if (!values.user_city) {
        errors.user_city = "City is Required";
    }

    if (!values.company_state) {
        errors.company_state = "Company Region/State/Province is Required";
    }

    if (!values.user_country) {
        errors.user_country = "Country is Required";
    }

    if (!values.company_name) {
        errors.company_name = "Company Name is Required";
    }
    if (!values.company_address) {
        errors.company_address = "Company's Address is Required";
    }
    if (!values.company_country) {
        errors.company_country = "Company's Country is Required";
    }
    if (!values.designation) {
        errors.designation = "Designation is Required";
    }
    if (!values.otp) {
        errors.otp = "Please enter the OTP sent to your phone number";
    }
    const otpRegex = /^(?=.*[\d]).{6}$/;
    if (!otpRegex.test(values.otp)) {
        errors.otp =
            "OTP must be 6 digits";
    }

    return errors;
};

export default validate;
