import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { compose } from 'recompose';
import { FETCH_VESSELS_LIMIT } from '../../utils';
import {
  changeUserVesselsData,
  changeUserVesselsStatus,
  getUserVesselsSearchError,
  getMoreUserVesselsData,
  getUserVesselsSearchStatus,
  getLastRecord,
} from '../../redux/actions';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../components/Firebase';

var lastFetchedVessel = null;
let vesselsStart = null;

class UserVessels extends Component {
  constructor(props) {
    super(props);
    this.unsubscribeFetch = [];
  }

  componentDidUpdate(prevProps) {
    const { userVesselsStatus, loadMoreUserVessels } = this.props;

    if (prevProps.userVesselsStatus !== userVesselsStatus) {
      if (userVesselsStatus) {
        this.fetchUserVessels();
      }
    }
    if (prevProps.loadMoreUserVessels !== loadMoreUserVessels) {
      console.log('i dey');
      if (loadMoreUserVessels) {
        this.fetchMoreUserVessels();
      }
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeFetch !== null) {
      this.unsubscribeFetch = [];
    }
  }

  fetchUserVessels = async () => {
    this.props.getUserVesselsSearchError('');
    this.props.getUserVesselsSearchStatus(false);
    this.props.changeUserVesselsStatus(false);

    try {
      this.props.firebase
        .fetchUserVerifiedVessels(this.props.match.params.uid)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()
        .then(async (snapshot) => {
          if (snapshot.empty) {
            this.props.getUserVesselsSearchError(
              'This user has no registered vessels.'
            );
            this.props.getUserVesselsSearchStatus(true);
            this.props.changeUserVesselsData([]);
          }
          vesselsStart = snapshot.docs[snapshot.docs.length - 1];

          const listner_v_1 = await this.props.firebase
            .fetchUserVerifiedVessels(this.props.match.params.uid)
            .orderBy('createdAt', 'desc')
            .endAt(vesselsStart)
            .onSnapshot(
              async (querySnapshot) => {
                await querySnapshot.docChanges().forEach(
                  async (change) => {
                    const doc = change.doc;
                    const item = doc.data();
                    item.docId = doc.id;
                    const pastList = [...this.props.userVesselsData, item];

                    lastFetchedVessel =
                      querySnapshot.docs[querySnapshot.docs.length - 1];

                    if (change.type === 'added') {
                      const filterList = _.uniqBy(pastList, 'docId');
                      await this.props.changeUserVesselsData(filterList);
                      this.props.getUserVesselsSearchStatus(true);
                    } else if (change.type === 'removed') {
                      const newData = pastList.filter(
                        (task) => task.docId !== doc.id
                      );
                      const filterList = _.uniqBy(newData, 'docId');
                      await this.props.changeUserVesselsData(filterList);
                    } else if (change.type === 'modified') {
                      const newData = pastList.filter(
                        (task) => task.docId !== doc.id
                      );
                      const newList = [item, ...newData];
                      const filterList = _.uniqBy(newList, 'docId');
                      await this.props.changeUserVesselsData(filterList);
                    }
                  },
                  (err) => {
                    this.props.getUserVesselsSearchError(
                      `Error occurred while fetching vessels.`
                    );
                    this.props.getUserVesselsSearchStatus(true);
                  }
                );
              },
              (err) => {
                this.props.getUserVesselsSearchError(
                  `Error occurred while fetching vessels.`
                );
                this.props.getUserVesselsSearchStatus(true);
              }
            );

          const listner_v_2 = await this.props.firebase
            .fetchUserVerifiedVessels(this.props.match.params.uid)
            .orderBy('createdAt', 'desc')
            .limit(FETCH_VESSELS_LIMIT)
            .startAfter(vesselsStart)
            .onSnapshot(
              async (querySnapshot) => {
                if (querySnapshot.empty) {
                  this.props.getLastRecord('Last User Vessel Record');
                }
                lastFetchedVessel =
                  querySnapshot.docs[querySnapshot.docs.length - 1];
                await querySnapshot.docChanges().forEach(
                  async (change) => {
                    const doc = change.doc;
                    const item = doc.data();
                    item.docId = doc.id;
                    const pastList = [...this.props.userVesselsData, item];
                    if (change.type === 'added') {
                      const filterList = _.uniqBy(pastList, 'docId');
                      await this.props.changeUserVesselsData(filterList);
                      this.props.getUserVesselsSearchStatus(true);
                    } else if (change.type === 'removed') {
                      const newData = pastList.filter(
                        (task) => task.docId !== doc.id
                      );
                      const filterList = _.uniqBy(newData, 'docId');
                      await this.props.changeUserVesselsData(filterList);
                    } else if (change.type === 'modified') {
                      const newData = pastList.filter(
                        (task) => task.docId !== doc.id
                      );
                      const newList = [item, ...newData];
                      const filterList = _.uniqBy(newList, 'docId');
                      await this.props.changeUserVesselsData(filterList);
                    }
                  },
                  (err) => {
                    this.props.getUserVesselsSearchError(
                      `Error occurred while fetching vessels.`
                    );
                    this.props.getUserVesselsSearchStatus(true);
                  }
                );

                if (this.unsubscribeFetch < 2) {
                  return this.unsubscribeFetch.push(listner_v_1, listner_v_2);
                }
              },
              (err) => {
                this.props.getUserVesselsSearchError(
                  `Error occurred while fetching vessels.`
                );
                this.props.getUserVesselsSearchStatus(true);
              }
            );
        });
    } catch (e) {
      this.props.getUserVesselsSearchError(
        `Error occurred while fetching vessels. ${e}`
      );
      this.props.getUserVesselsSearchStatus(true);
    }
  };

  fetchMoreUserVessels = async () => {
    this.props.getUserVesselsSearchError('');
    this.props.getUserVesselsSearchStatus(false);
    this.props.changeUserVesselsStatus(false);
    this.props.getMoreUserVesselsData(false);

    try {
      const listner_v_2 = await this.props.firebase
        .fetchUserVerifiedVessels(this.props.match.params.uid)
        .orderBy('createdAt', 'asc')
        .endBefore(lastFetchedVessel)
        .limit(FETCH_VESSELS_LIMIT)
        .onSnapshot(
          async (querySnapshot) => {
            lastFetchedVessel = querySnapshot.docs[0];
            if (querySnapshot.empty) {
              this.props.getLastRecord('Last User Vessel Record');
              this.props.getUserVesselsSearchStatus(true);
            }
            await querySnapshot.docChanges().forEach(
              async (change) => {
                const doc = change.doc;
                const item = doc.data();
                item.docId = doc.id;
                const pastList = [...this.props.userVesselsData, item];
                if (change.type === 'added') {
                  const filterList = _.uniqBy(pastList, 'docId');
                  await this.props.changeUserVesselsData(filterList);
                  this.props.getUserVesselsSearchStatus(true);
                } else if (change.type === 'removed') {
                  const newData = pastList.filter(
                    (task) => task.docId !== doc.id
                  );
                  const filterList = _.uniqBy(newData, 'docId');
                  await this.props.changeUserVesselsData(filterList);
                } else if (change.type === 'modified') {
                  const newData = pastList.filter(
                    (task) => task.docId !== doc.id
                  );
                  const newList = [item, ...newData];
                  const filterList = _.uniqBy(newList, 'docId');
                  await this.props.changeUserVesselsData(filterList);
                }
              },
              (err) => {
                console.log('ifir', err);
                this.props.getUserVesselsSearchError(
                  `Error occurred while fetching vessels.`
                );
                this.props.getUserVesselsSearchStatus(true);
              }
            );
          },
          (err) => {
            console.log('is there eoor here', err);
            this.props.getUserVesselsSearchError(
              `Error occurred while fetching vessels.`
            );
            this.props.getUserVesselsSearchStatus(true);
          }
        );
      return this.unsubscribeFetch.push(listner_v_2);
    } catch (e) {
      console.log('second error', e);
      this.props.getSearchErrorMessage(
        `Error occurred while fetching user's vessels. ${e}`
      );
      this.props.getUserVesselsSearchStatus(true);
      this.props.changeUserVesselsData([]);
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  userVesselsData: state.user.userVesselsData,
  userVesselsStatus: state.user.userVesselsStatus,
  loadMoreUserVessels: state.user.loadMoreUserVessels,
});

const mapDispatchToProps = (dispatch) => ({
  changeUserVesselsData: (userVesselsData) => {
    dispatch(changeUserVesselsData(userVesselsData));
  },
  getUserVesselsSearchError: (userVesselsSearchError) => {
    dispatch(getUserVesselsSearchError(userVesselsSearchError));
  },
  getUserVesselsSearchStatus: (userVesselsSearchStatus) => {
    dispatch(getUserVesselsSearchStatus(userVesselsSearchStatus));
  },
  changeUserVesselsStatus: (userVesselsStatus) => {
    dispatch(changeUserVesselsStatus(userVesselsStatus));
  },
  getLastRecord: (lastRecord) => {
    dispatch(getLastRecord(lastRecord));
  },
  getMoreUserVesselsData: (loadMoreUserVessels) => {
    dispatch(getMoreUserVesselsData(loadMoreUserVessels));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withFirebase
)(UserVessels);
