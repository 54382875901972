import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Link, withRouter, useParams } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { MdDirectionsBoat } from 'react-icons/md';
import { GiBlackBridge, GiPadlockOpen, GiNotebook } from 'react-icons/gi';
import { VscDebugDisconnect } from 'react-icons/vsc';
import Breadcrumb from '../components/breadcrumbs/Breadcrumb';

function About() {
  const { section } = useParams();
  const scroll = Scroll.animateScroll;

  useEffect(() => {
    if (section === 'our-obj') {
      scroll.scrollTo(1100);
    }
    if (section === 'core') {
      scroll.scrollTo(1600);
    }
    if (section === 'who') {
      scroll.scrollTo(500);
    }
    if (section === 'what') {
      scroll.scrollTo(800);
    }
    console.log('section', section);
  }, [section, scroll]);

  return (
    <Fragment>
      <MetaTags>
        <title>MarApp</title>
        <meta name='description' content='About Maritime Exchange' />
      </MetaTags>

      <Breadcrumb title='About Us' />
      <div className='about__dgtaka about--2 pt--10 pb--70'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
              <div className='dg__secure__inner'>
                <h3 className='section__title--4'>Who We Are</h3>
                <p>
                  A group of professionals with several years of cognate
                  experiences across various divisions of the maritime sector.
                </p>
                <p>
                  Oceanic Links fuses technology with great partnerships to
                  provide cutting-edge solutions that address the concerns of
                  Shipping practice and enhance the development of the Maritime
                  Industry at large, in the Sub-Sahara.
                </p>
              </div>
            </div>
            <div className='col-lg-5 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--40'>
              <div className='dg__secure__thumb'>
                <img
                  src={process.env.PUBLIC_URL + '/images/about/real.png'}
                  alt='mockup pic'
                />
              </div>
            </div>
            <div
              className='col-lg-7 col-md-12 col-sm-12 col-12'
              id='what-we-do'
            >
              <div className='dg__secure__inner'>
                <h3 className='section__title--4'>What We Do</h3>
                <p>
                  We have created an innovation that addresses Marine and
                  Shipping needs by providing a safe and secured ground for the
                  Ship owners and Maritime Service providers to meet their
                  customers. To this end we are dedicated to imbibing and
                  continuously improving the landscape to ensure integrity and
                  smooth exchange of product and services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='dg__service__area pb--70' id='our-obj'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section__title--6'>
                <h2>Our Objectives</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='space__dec'>
          <div className='custom__service__width'>
            <div className='service__2'>
              <div className='icon'>
                {/* <img src='/images/service/icon/1.png' alt='service icon' /> */}
                <h1>
                  <MdDirectionsBoat />
                </h1>
              </div>
              <div className='content'>
                <p>
                  To provide a platform for maritime professionals to expose
                  their services to a wider reach while networking with other
                  service providers for better synergy
                </p>
              </div>
            </div>
            <div className='service__2'>
              <div className='icon'>
                {/* <img src='/images/service/icon/2.png' alt='service icon' /> */}
                <h1>
                  <GiBlackBridge />
                </h1>
              </div>
              <div className='content'>
                <p>
                  To bridge the gap between Ship Owner, Managers and Cargo
                  Owners, thereby drastically reducing cargo lag time and the
                  accompanying risks.
                </p>
              </div>
            </div>
            <div className='service__2'>
              <div className='icon'>
                {/* <img src='/images/service/icon/3.png' alt='service icon' /> */}
                <h1>
                  <GiPadlockOpen />
                </h1>
              </div>
              <div className='content'>
                <p>
                  To enable a Ship Owner or Manager improve his opportunities in
                  the Worldwide Maritime Market, using our premium services.
                </p>
              </div>
            </div>
            <div className='service__2'>
              <div className='icon'>
                {/* <img src='/images/service/icon/4.png' alt='service icon' /> */}
                <h1>
                  <VscDebugDisconnect />
                </h1>
              </div>
              <div className='content'>
                <p>
                  To connect Ship Owners and Managers to Seafarers, who seek
                  opportunities for Sea going jobs
                </p>
              </div>
            </div>
            <div className='service__2'>
              <div className='icon'>
                {/* <img src='/images/service/icon/5.png' alt='service icon' /> */}
                <h1>
                  <GiNotebook />
                </h1>
              </div>
              <div className='content'>
                <p>
                  To provide relevant information and synergise with Maritime
                  Professionals and Flag States for effective Co-ordination.
                </p>
              </div>
            </div>
            <div className='service__2'></div>
          </div>
        </div>
      </section>
      <section className='dg__service__area pb--70'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section__title--6'>
                <h2>Core Values</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='space__dec'>
          <div className='custom__service__width'>
            <div className='service__2'>
              <div className='content'>
                <h4>
                  <Link style={{ cursor: 'text' }} to='#'>
                    Integrity
                  </Link>
                </h4>
              </div>
            </div>
            <div className='service__2'>
              <div className='content'>
                <h4>
                  <Link style={{ cursor: 'text' }} to='#'>
                    Commitment
                  </Link>
                </h4>
              </div>
            </div>
            <div className='service__2'>
              <div className='content'>
                <h4>
                  <Link style={{ cursor: 'text' }} to='#'>
                    Responsibility
                  </Link>
                </h4>
              </div>
            </div>
            <div className='service__2'>
              <div className='content'>
                <h4>
                  <Link style={{ cursor: 'text' }} to='#'>
                    Leadership
                  </Link>
                </h4>
              </div>
            </div>
            <div className='service__2'>
              <div className='content'>
                <h4>
                  <Link style={{ cursor: 'text' }} to='#'>
                    Efficiency
                  </Link>
                </h4>
              </div>
            </div>
            <div className='service__2'>
              <div className='content'>
                <h4>
                  <Link style={{ cursor: 'text' }} to='#'>
                    Customer Satisfaction
                  </Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default withRouter(About);
