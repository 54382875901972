import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Logo from '../../components/header/Logo';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from '../../components/header/Navigation';
import { getSticky } from '../../redux/actions';
import HeaderBtn from '../../components/header/HeaderBtn';
import MobileMenu from '../../components/header/MobileMenu';

const Header = ({ theme }) => {
  const dispatch = useDispatch();
  const shouldStick = useSelector((store) => store.user.shouldStick);
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const pathname = window.location.pathname;

  useEffect(() => {
    const header = document.querySelector('header');
    setHeaderTop(header.offsetTop);
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      pathname.startsWith('userpage', 1) ||
      pathname.startsWith('vessel/', 1)
    ) {
      dispatch(getSticky(true));
    } else {
      dispatch(getSticky(false));
    }
  }, [pathname, dispatch]);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <header
      className={`dg__header header--absolute space-right-left ${
        scroll > headerTop || shouldStick ? 'stick' : ''
      }`}
    >
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='col-lg-2 col-xl-2 col-6'>
            {/* logo */}
            <Logo
              image={
                theme === 'white'
                  ? '/images/logo/OL-2.png'
                  : '/images/logo/OL-dark.png'
              }
            />
          </div>
          <div className='col-lg-7 col-xl-8 d-none d-lg-block'>
            {/* navigation */}
            <Navigation />
          </div>
          <div className='col-lg-3 col-xl-2 col-6'>
            {/* header buttons */}
            <HeaderBtn />
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <MobileMenu />
    </header>
  );
};

Header.propTypes = {
  theme: PropTypes.string,
};

export default Header;
