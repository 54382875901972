import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import _ from 'lodash';

import { mainCollection } from '../../utils';

const isDev = process.env.NODE_ENV === 'development';

var appConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

class Firebase {
  constructor() {
    this.initialize();
  }

  initialize() {
    firebase.initializeApp(appConfig);
    this.auth = firebase.auth();
    this.firestore = firebase.firestore();
    this.functions = firebase.functions();
    this.storage = firebase.storage();
    this.emailProvider = firebase.auth.EmailAuthProvider;
    this.fireAuth = firebase.auth;
    this.phoneVerify = new firebase.auth.PhoneAuthProvider();
    this.phone = firebase.auth.PhoneAuthProvider;
    this.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    // if (isDev) this.functions.useFunctionsEmulator("http://localhost:5001");
  }

  signIn = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  createUser = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  resetPassword = (email) => this.auth.sendPasswordResetEmail(email);

  updatePassword = (password) => this.auth.currentUser.updatePassword(password);

  // *** User API ***
  fetchUserDetails = (uid) =>
    this.firestore.collection(mainCollection.USERS).doc(uid);

  registerAccount = async (details) => {
    const UID = this.auth.currentUser.uid;
    const firestoreRef = this.firestore
      .collection(mainCollection.USERS)
      .doc(UID);

    const userData = {
      firstname: _.startCase(details.firstname.toLowerCase()),
      lastname: _.startCase(details.lastname.toLowerCase()),
      phone: details.phone,
      email: details.email,
      user_address: details.user_address,
      user_city: details.user_city,
      user_country: details.user_country,
      user_state: details.user_state,
      company_name: details.company_name,
      company_address: details.company_address,
      company_city: details.company_city,
      company_state: details.company_state,
      company_country: details.company_country,
      UID,
      username: details.userName,
      designation: details.designation,
      accountType: details.accountType,
      CreatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      profile_picture: null,
      incorp_cert: null,
      cac7: null,
    };
    return firestoreRef.set(userData);
  };

  registerVessel = (data) => {
    const firestoreRef = this.firestore
      .collection(mainCollection.VESSELS)
      .doc(data.vesselId);

    return firestoreRef.set(data);
  };

  checkVessel = (imo_number) => {
    const firestoreRef = this.firestore
      .collection(mainCollection.VESSELS)
      .where('imo_number', '==', imo_number);

    return firestoreRef.get().then((querySnapshot) => {
      if (querySnapshot.empty) {
        return false;
      }
      return true;
    });
  };
  checkAdvert = (data) => {
    const collectionRef = this.firestore.collection(mainCollection.ADVERTS);

    return (
      collectionRef
        // check for existing active Ads
        .where('vesselId', '==', data.vesselId)
        .where('expired', '==', false)
        .where('order_confirmed', '==', 1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            return false;
          } else {
            return true;
          }
        })
    );
  };

  createVesselAd = (data) => {
    data.view_count = 0;
    data.views = [];
    data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();

    const collectionRef = this.firestore.collection(mainCollection.ADVERTS);

    return (
      collectionRef
        // check for existing active Ads
        .where('vesselId', '==', data.vesselId)
        .where('expired', '==', false)
        .where('order_confirmed', '==', 0)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            const firestoreRef = collectionRef.doc(data.id);

            return firestoreRef.set(data);
          } else {
            throw new Error("You can't create duplicate Ads");
          }
        })
    );
  };

  fetchYourVessels = (uid) => {
    const collectionRef = this.firestore.collection(mainCollection.VESSELS);
    const queryRef = collectionRef.where('accountId', '==', uid);
    return queryRef;
  };

  deleteVessel = (id) =>
    this.firestore.collection(mainCollection.VESSELS).doc(id).delete();

  fetchMyAdverts = (uid) => {
    return this.firestore
      .collection(mainCollection.ADVERTS)
      .where('user_id', '==', uid);
  };

  fetchUserVerifiedVessels = (uid) => {
    const collectionRef = this.firestore.collection(mainCollection.VESSELS);
    const queryRef = collectionRef
      .where('accountId', '==', uid)
      .where('disabled', '==', false);
    return queryRef;
  };

  fetchVerifiedVessels = () => {
    const collectionRef = this.firestore.collection(mainCollection.VESSELS);
    const queryRef = collectionRef.where('disabled', '==', false);
    return queryRef;
  };

  fetchVesselDetails = (uid) =>
    this.firestore.collection(mainCollection.VESSELS).doc(uid);

  fetchAdPackageData = () => {
    return this.firestore
      .collection(mainCollection.ADVERTS_PACKAGES)
      .where('disabled', '==', false)
      .orderBy('id', 'asc');
  };

  // fetch Adverts to display from database
  fetchAdverts = (data) => {
    try {
      const getAdverts = this.functions.httpsCallable('getAdverts');
      return getAdverts(data);
    } catch (e) {
      return null;
    }
  };

  // fetch Adverts to display from database
  increaseAdvertViewCount = (data) => {
    try {
      const func = this.functions.httpsCallable('increaseAdvertViewCount');
      return func(data);
    } catch (e) {
      return null;
    }
  };

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((marexUser) => {
      if (marexUser) {
        this.fetchUserDetails(marexUser.uid)
          .get()
          .then((doc) => {
            const dbUser = doc.data();
            //default empty roles
            // if (!dbUser.permissions) {
            //   dbUser.permissions = [];
            // }
            //merge auth and db user
            marexUser = {
              ...dbUser,
            };
            next(marexUser);
          });
      } else {
        fallback();
      }
    });
}

export default Firebase;
