import React from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { Link as ScrollLink } from 'react-scroll';
import { IoIosArrowDown } from 'react-icons/io';
import HomeNav from './HomeNav';
import { useSelector } from 'react-redux';
import UserVessels from '../../containers/user-vessels';

export default function Navigation() {
  const searchRef = useSelector((store) => store.vessel.searchRef);
  const scroll = Scroll.animateScroll;

  const handleClick = () => {
    if (searchRef) {
      console.log('it is here');
      searchRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className='mainmenu__nav'>
      <ul className='mainmenu'>
        {/* <HomeNav /> */}
        <UserVessels />
        <li className='drop'>
          <Link to='/about/who'>
            Who We Are <IoIosArrowDown />
          </Link>
          <ul className='dropdown'>
            <li>
              <Link to='/about/our-obj'>Our Objectives</Link>
              {/* <li>
                <ScrollLink to='searchBox'>Our Objectives</ScrollLink>
              </li> */}
            </li>
            <li>
              <Link to='/about/core'>Core Values</Link>
            </li>
          </ul>
        </li>
        <li className='drop'>
          <Link to='/about/what'>
            What We Do <IoIosArrowDown />
          </Link>
          <ul className='dropdown'>
            <li>
              <Link to='/about'>Bridging The Gap</Link>
            </li>
            <li>
              <Link to='/about'>Vessel Matching</Link>
            </li>
            <li>
              <Link to='/about'>Seafarer's Community</Link>
            </li>
          </ul>
        </li>
        {/* <li>
          <Link onClick={() => scroll.scrollTo(700)}>
              Vessels Search
          </Link>
        </li> */}
        <li>
          <Link to='/search'>Registered Vessels</Link>
        </li>
      </ul>
    </nav>
  );
}
