import {
  GET_TOKEN,
  SET_TEST,
  SET_TOKEN,
  GET_INCORP_CERT_BLOB_IMG,
  GET_CAC7_BLOB_IMG,
  CHANGE_REGISTRATION_LOADING,
  SHOW_OTP_MODAL,
  SET_CAPTCHA_ID,
} from './types';

export const getSerialToken = (token) => {
  return {
    type: GET_TOKEN,
    payload: token,
  };
};

export const getIncorpCertBlob = (incorpCertBlob) => {
  return {
    type: GET_INCORP_CERT_BLOB_IMG,
    payload: incorpCertBlob,
  };
};

export const setCaptchaId = (captchaId) => {
  return {
    type: SET_CAPTCHA_ID,
    payload: captchaId,
  };
};

export const changeRegLoading = (registrationLoading) => {
  return {
    type: CHANGE_REGISTRATION_LOADING,
    payload: registrationLoading,
  };
};

export const changeOTPModal = (showOTPModal) => {
  return {
    type: SHOW_OTP_MODAL,
    payload: showOTPModal,
  };
};

export const getCAC7Blob = (cac7Blob) => {
  return {
    type: GET_CAC7_BLOB_IMG,
    payload: cac7Blob,
  };
};

export const testSet = (test) => {
  return {
    type: SET_TEST,
    payload: test,
  };
};

export const setToken = (serialToken) => {
  return {
    type: SET_TOKEN,
    payload: serialToken,
  };
};
