import {
  GET_VESSEL_REG_IMAGES,
  GET_LETTER_OF_AUTH,
  RELOAD_MY_VESSELS,
  GET_MY_VESSELS_SEARCH_ERROR,
  GET_MY_VESSELS_SEARCH_STATUS,
  MY_VESSELS_DATA,
  CHANGE_SELECTED_DATA,
  RELOAD_VESSELS,
  GET_VESSELS_SEARCH_ERROR,
  GET_VESSELS_SEARCH_STATUS,
  VESSELS_DATA,
  CHANGE_SELECTED_VESSEL,
  SET_VESSEL_DOC_URL,
  CHANGE_FORMAT_TEXT_LENGTH,
  GET_LAST_RECORD,
  CHANGE_SEARCH_QUERY,
  CHANGE_BASIC_SEARCH_VESSEL,
  CHANGE_SEARCH_FILTERS,
} from './types';

export const getVesselImages = (vesselImages) => {
  return {
    type: GET_VESSEL_REG_IMAGES,
    payload: vesselImages,
  };
};

export const changeSearchQuery = (searchQuery) => {
  return {
    type: CHANGE_SEARCH_QUERY,
    payload: searchQuery,
  };
};

export const changeSearchVessel = (searchVessel) => {
  return {
    type: CHANGE_BASIC_SEARCH_VESSEL,
    payload: searchVessel,
  };
};

export const changeSearchFilters = (searchFilters) => {
  return {
    type: CHANGE_SEARCH_FILTERS,
    payload: searchFilters,
  };
};

export const getAuthLetter = (authLetter) => {
  return {
    type: GET_LETTER_OF_AUTH,
    payload: authLetter,
  };
};

export const changeMyVesselsData = (myVesselsData) => {
  return {
    type: MY_VESSELS_DATA,
    payload: myVesselsData,
  };
};

export const changeVesselsData = (vesselsData) => {
  return {
    type: VESSELS_DATA,
    payload: vesselsData,
  };
};

export const changeFormatTextLength = (formatTextLength) => {
  return {
    type: CHANGE_FORMAT_TEXT_LENGTH,
    payload: formatTextLength,
  };
};

export const getLastRecord = (lastRecord) => ({
  type: GET_LAST_RECORD,
  payload: lastRecord,
});

export const getMyVesselsSearchStatus = (myVesselsSearchStatus) => ({
  type: GET_MY_VESSELS_SEARCH_STATUS,
  payload: myVesselsSearchStatus,
});

export const getMyVesselsSearchError = (myVesselsSearchError) => ({
  type: GET_MY_VESSELS_SEARCH_ERROR,
  payload: myVesselsSearchError,
});

export const changeMyVesselsStatus = (myVesselsStatus) => ({
  type: RELOAD_MY_VESSELS,
  payload: myVesselsStatus,
});

export const getVesselsSearchStatus = (vesselsSearchStatus) => ({
  type: GET_VESSELS_SEARCH_STATUS,
  payload: vesselsSearchStatus,
});

export const getVesselsSearchError = (vesselsSearchError) => ({
  type: GET_VESSELS_SEARCH_ERROR,
  payload: vesselsSearchError,
});

export const changeVesselsStatus = (vesselsStatus) => ({
  type: RELOAD_VESSELS,
  payload: vesselsStatus,
});

export const changeSelectedData = (selectedData) => {
  return {
    type: CHANGE_SELECTED_DATA,
    payload: selectedData,
  };
};

export const changeSelectedVessel = (selectedVessel) => {
  return {
    type: CHANGE_SELECTED_VESSEL,
    payload: selectedVessel,
  };
};

export const setVesselURL = (vesselURL) => {
  return {
    type: SET_VESSEL_DOC_URL,
    payload: vesselURL,
  };
};
