import React, { Fragment, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Button, Spinner, Col, Row } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { withFirebase } from '../components/Firebase';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function Forgot(props) {
  const [error, setError] = useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  function onSubmit(event, values) {
    setLoading(true);
    props.firebase
      .resetPassword(values.email)
      .then(() => {
        setLoading(false);
        MySwal.fire({
          title: 'Email Sent',
          text:
            'Your password reset link has been sent to your email. Enter your new password with the link and then you can',
          confirmButtonText: 'Login',
          icon: 'success',
        }).then(() => {
          history.push('/login');
        });
      })
      .catch(() => {
        setError({
          message: 'This email is not linked to any account.',
        });
        setLoading(false);
      });
    event.persist();
  }

  return (
    <Fragment>
      <MetaTags>
        <title>Forgot Password - Oceanic Links</title>
        <meta
          name='description'
          content='Reset the password to your oceanic links account'
        />
      </MetaTags>
      <div className='dg__account section-padding--xl'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='single__account'>
                <h3>Reset Password</h3>
                {error && <Alert color='danger'>{error.message}</Alert>}

                <div className='row'>
                  <div className='col-sm-12'>
                    <AvForm onValidSubmit={onSubmit}>
                      <AvField
                        name='email'
                        label='Email Address'
                        type='email'
                        required
                        className='input__box'
                      />
                      <Row>
                        <Col sm='6'></Col>
                        {loading ? (
                          <Col sm='6'>
                            <Spinner color='primary' />
                          </Col>
                        ) : (
                          <Col sm='12'>
                            <Button className='account__btn'>Reset</Button>
                          </Col>
                        )}
                      </Row>
                      <Link
                        className='forget__pass'
                        style={{ marginTop: 20 }}
                        to='/login'
                      >
                        Remembered it? Login instead
                      </Link>
                    </AvForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default withFirebase(Forgot);
