import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import toast from 'toasted-notes';
import { FaGoogle, FaFacebook, FaTwitter } from 'react-icons/fa';
import 'toasted-notes/src/styles.css';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { Spinner, Col, Button } from 'reactstrap';
import { BsFillFunnelFill } from 'react-icons/bs';
import {
  changeUserVesselsStatus,
  changeUser1,
  changeUser2,
  changeUser3,
  changeUser4,
  changeUser5,
  getMoreUserVesselsData,
} from '../../../src/redux/actions';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      selectedUser: {},
    };
    this.unsubscribePosterFetch = null;
  }

  componentDidMount() {
    this.onFetchVessels();
  }

  componentWillUnmount() {
    this.unsubscribePosterFetch = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userVesselsData !== this.props.userVesselsData) {
      this.onFetchVessels();
    }
    if (prevProps.lastRecord !== this.props.lastRecord) {
      if (this.props.lastRecord === 'Last User Vessel Record') {
        this.setState({ isLoading: false });
      }
    }
  }

  checkCache = (type, doc) => {
    //check if the account details of the poster has already been cached, if not fetch from db if mounting or store in cache if fetching poster
    if (this.props.user1.UID === this.props.match.params.uid) {
      return this.setState({
        selectedUser: this.props.user1,
        isLoading: false,
      });
    } else if (this.props.user2.UID === this.props.match.params.uid) {
      return this.setState({
        selectedUser: this.props.user2,
        isLoading: false,
      });
    } else if (this.props.user3.UID === this.props.match.params.uid) {
      return this.setState({
        selectedUser: this.props.user3,
        isLoading: false,
      });
    } else if (this.props.user4.UID === this.props.match.params.uid) {
      return this.setState({
        selectedUser: this.props.user4,
        isLoading: false,
      });
    } else if (this.props.user5.UID === this.props.match.params.uid) {
      return this.setState({
        selectedUser: this.props.user5,
        isLoading: false,
      });
    } else {
      if (type === 'mount/details') {
        return this.fetchPoster();
      } else if (type === 'poster') {
        //deciding which cache slot to store the newly fetched user details
        if (_.isEmpty(this.props.user1)) {
          this.props.changeUser1(doc);
          this.setState({ selectedUser: doc });
        } else if (_.isEmpty(this.props.user2)) {
          this.props.changeUser2(doc);
          this.setState({ selectedUser: doc });
        } else if (_.isEmpty(this.props.user3)) {
          this.props.changeUser3(doc);
          this.setState({ selectedUser: doc });
        } else if (_.isEmpty(this.props.user4)) {
          this.props.changeUser4(doc);
          this.setState({ selectedUser: doc });
        } else if (_.isEmpty(this.props.user5)) {
          this.props.changeUser5(doc);
          this.setState({ selectedUser: doc });
        }
        //if every slot is filled, replace slot 1
        else {
          this.props.changeUser1(doc);
          this.setState({ selectedUser: doc });
        }
        this.setState({
          isLoading: false,
        });
      }
    }
  };

  handleAditional() {
    this.setState({ isLoading: true });
    this.props.getMoreUserVesselsData(true);
  }

  fetchPoster = () => {
    try {
      this.unsubscribePosterFetch = this.props.firebase
        .fetchUserDetails(this.props.match.params.uid)
        .onSnapshot(
          (doc) => {
            if (doc.exists) {
              if (doc.metadata.hasPendingWrites) {
                return;
              }
              //deciding which cache slot to store the newly fetched user details
              this.checkCache('poster', doc.data());
            }
          },
          (err) => {
            toast.notify('Error while updating user', {
              duration: 3000,
            });
            this.setState({ isLoading: false });
          }
        );
    } catch (e) {
      this.setState({ isLoading: false });
      toast.notify(`Error while fetching user ${e}`, {
        duration: 10000,
      });
    }
  };

  onFetchVessels() {
    const {
      userVesselsSearchStatus,
      userVesselsData,
      userVesselsSearchError,
    } = this.props;

    const checkError = userVesselsSearchError === '';
    const checkList = userVesselsData.length === 0;

    if (checkError && !userVesselsSearchStatus && checkList) {
      this.setState({ isLoading: true, errorMessage: userVesselsSearchError });
      setTimeout(() => this.props.changeUserVesselsStatus(true), 3000);
    } else if (!checkError && userVesselsSearchStatus && checkList) {
      this.setState({ isLoading: false, errorMessage: userVesselsSearchError });
    } else if (!checkList) {
      this.checkCache('mount/details');
    }
  }

  render() {
    const { userVesselsData, lastRecord } = this.props;
    const { errorMessage, selectedUser, isLoading } = this.state;
    const checkError = errorMessage !== '';
    const checkLast = lastRecord === 'Last User Vessel Record';

    return (
      <Fragment>
        {isLoading ? (
          <div className='row' style={{ paddingTop: '100px' }}>
            <div className='col-sm-5'></div>
            <div className='col-sm-3'>
              <Spinner
                type='grow'
                style={{ width: '9rem', height: '9rem' }}
                color='primary'
              />
            </div>
          </div>
        ) : (
          <div className='dg__blog__area bg--white section-padding--xl'>
            <div className='container'>
              {checkError && (
                <Col sm={{ size: 5, offset: 4 }}>
                  <p>
                    <strong>{errorMessage} </strong>
                    <br />
                    <Col sm={{ size: 12, offset: 3 }}>
                      <Button
                        type='button'
                        color='danger'
                        onClick={() => window.location.reload()}
                      >
                        Reload
                      </Button>
                    </Col>
                  </p>
                </Col>
              )}
              {!_.isEmpty(selectedUser) && (
                <div className='row'>
                  <div className='col-sm-6 mb--40'>
                    <div className='agent_wrap'>
                      <h3>
                        {selectedUser.firstname} {selectedUser.lastname}
                      </h3>
                      <table className='table'>
                        <tbody>
                          <tr>
                            <td>
                              <strong>Account</strong>
                            </td>
                            <td className='text-right'>
                              {selectedUser.accountType}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Phone Number:</strong>
                            </td>
                            <td className='text-right'>{selectedUser.phone}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Email Address:</strong>
                            </td>
                            <td className='text-right'>
                              <a href={`mailto:${selectedUser.email}`}>
                                {selectedUser.email}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Company Name:</strong>
                            </td>
                            <td className='text-right'>
                              {selectedUser.company_name}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className='blog__pagination'>
                        <ul className='bl__dtl__tag'>
                          <li>
                            <a
                              href='//rss.com'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <FaFacebook />
                            </a>
                          </li>
                          <li>
                            <a
                              href='//pinterest.com'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <FaTwitter />
                            </a>
                          </li>
                          <li>
                            <a
                              href='//gogole.com'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <FaGoogle />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-3 offset-2 mb--40'>
                    <div className='agent_wrap'>
                      <div className='image'>
                        {selectedUser.profile_picture ? (
                          <img
                            src={selectedUser.profile_picture}
                            alt='user pic'
                            style={{
                              width: '360px',
                              height: '312px',
                            }}
                          />
                        ) : (
                          <img
                            src='images/unknown.png'
                            alt='user pic'
                            style={{
                              width: '360px',
                              height: '312px',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!_.isEmpty(userVesselsData) && (
                <Fragment>
                  <div className='col-md-12' style={{ marginTop: '50px' }}>
                    {!_.isEmpty(selectedUser) && (
                      <h2>{selectedUser.firstname}'s Vessels</h2>
                    )}
                  </div>
                  <div className='row'>
                    {userVesselsData.map((data, idx) => {
                      return (
                        <div className='col-sm-4' key={idx}>
                          <div className='listing_full'>
                            <div className='image'>
                              <img
                                alt='vessel'
                                src={data.images[0]}
                                style={{ width: '364px', height: '254px' }}
                              />
                              <span className='tag_t' style={{ right: '20px' }}>
                                {data.status}
                              </span>
                            </div>
                            <div className='listing_full_bg'>
                              <div className='listing_inner_full'>
                                <Link
                                  to={{ pathname: `/vessel/${data.vesselId}` }}
                                >
                                  <h3>{data.vessel_name}</h3>
                                </Link>
                                <div className='favroute clearfix'>
                                  <div className='property_meta'>
                                    <span>
                                      <BsFillFunnelFill /> {data.vesselType}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              )}
              {checkLast && (
                <h6 style={{ color: 'red' }}>
                  You have reached the last vessel registered under this user
                </h6>
              )}
              {!isLoading && !checkLast && (
                <Col sm={{ size: 2 }}>
                  {!isLoading && !checkLast && (
                    <Button
                      type='button'
                      color='info'
                      onClick={() => this.handleAditional()}
                    >
                      Load More...
                    </Button>
                  )}
                </Col>
              )}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userVesselsSearchError: state.user.userVesselsSearchError,
  userVesselsData: state.user.userVesselsData,
  userVesselsSearchStatus: state.user.userVesselsSearchStatus,
  user1: state.user.user1,
  user2: state.user.user2,
  user3: state.user.user3,
  user4: state.user.user4,
  user5: state.user.user5,
  lastRecord: state.vessel.lastRecord,
});

const mapDispatchToProps = (dispatch) => ({
  changeUserVesselsStatus: (userVesselsStatus) => {
    dispatch(changeUserVesselsStatus(userVesselsStatus));
  },
  changeUser1: (user1) => {
    dispatch(changeUser1(user1));
  },
  changeUser2: (user2) => {
    dispatch(changeUser2(user2));
  },
  changeUser3: (user3) => {
    dispatch(changeUser3(user3));
  },
  changeUser4: (user4) => {
    dispatch(changeUser4(user4));
  },
  changeUser5: (user5) => {
    dispatch(changeUser5(user5));
  },
  getMoreUserVesselsData: (loadMoreUserVessels) => {
    dispatch(getMoreUserVesselsData(loadMoreUserVessels));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withFirebase
)(UserProfile);
