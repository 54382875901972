import {
  USER_1,
  USER_2,
  USER_3,
  USER_4,
  USER_5,
  GET_USER_VESSELS_SEARCH_ERROR,
  GET_USER_VESSELS_SEARCH_STATUS,
  RELOAD_USER_VESSELS,
  USER_VESSELS_DATA,
  GET_STICKY,
  LOAD_MORE_USER_VESSELS,
} from './types';

export const changeUser1 = (user1) => {
  return {
    type: USER_1,
    payload: user1,
  };
};

export const changeUser2 = (user2) => {
  return {
    type: USER_2,
    payload: user2,
  };
};

export const changeUser3 = (user3) => {
  return {
    type: USER_3,
    payload: user3,
  };
};

export const changeUser4 = (user4) => {
  return {
    type: USER_4,
    payload: user4,
  };
};

export const changeUser5 = (user5) => {
  return {
    type: USER_5,
    payload: user5,
  };
};

export const changeUserVesselsData = (userVesselsData) => {
  return {
    type: USER_VESSELS_DATA,
    payload: userVesselsData,
  };
};

export const getUserVesselsSearchStatus = (userVesselsSearchStatus) => ({
  type: GET_USER_VESSELS_SEARCH_STATUS,
  payload: userVesselsSearchStatus,
});

export const getUserVesselsSearchError = (userVesselsSearchError) => ({
  type: GET_USER_VESSELS_SEARCH_ERROR,
  payload: userVesselsSearchError,
});

export const changeUserVesselsStatus = (userVesselsStatus) => ({
  type: RELOAD_USER_VESSELS,
  payload: userVesselsStatus,
});

export const getMoreUserVesselsData = (loadMoreUserVessels) => ({
  type: LOAD_MORE_USER_VESSELS,
  payload: loadMoreUserVessels,
});

export const getSticky = (shouldStick) => {
  return {
    type: GET_STICKY,
    payload: shouldStick,
  };
};
