import { GET_ADVERTS, GET_ADVERT_PACKAGES } from "./types";

export const getAdvertPackages = (payload) => {
  return {
    type: GET_ADVERT_PACKAGES,
    payload,
  };
};

export const fetchMyAdverts = (payload) => {
  return {
    type: GET_ADVERTS,
    payload,
  };
};
