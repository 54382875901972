
import React from 'react';
import { GiShipWheel } from "react-icons/gi";
import { CgProfile } from "react-icons/cg";
import { FaShip } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Fragment } from 'react';

const TabBar = () => (
  <Fragment>
    <div className="market__filter">
      <ul className="f-p-links margin_bottom">
        <li>
          <Link to="/dashboard">
            <CgProfile />
            <b> Profile</b>
          </Link>
        </li>
        <li>
          <Link to="/manage-fleet">
            <GiShipWheel />
            <b>Manage Fleet</b>
          </Link>
        </li>
        <li>
          <Link to="/manage-adverts">
            <GiShipWheel />
            <b>Manage Adverts</b>
          </Link>
        </li>
        <li>
          <Link to="/register-vessel">
            <FaShip />
            <b>Register Vessel</b>
          </Link>
        </li>
      </ul>
    </div>
  </Fragment>
);

export default TabBar;