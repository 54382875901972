import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';

// Carousel images
// import smimg1 from '../../../images/small/img-1.jpg';
// import smimg2 from '../../../images/small/img-2.jpg';
// import smimg3 from '../../../images/small/img-3.jpg';

// const items = [
//   {
//     src: smimg1,
//     altText: 'First slide',
//     caption: 'Slide 1',
//   },
//   {
//     src: smimg2,
//     altText: 'Second slide',
//     caption: 'Slide 2',
//   },
//   {
//     src: smimg3,
//     altText: 'Third slide',
//     caption: 'Slide 3',
//   },
// ];

// const items = this.props.images;

class Slidewithfade extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.images.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.images.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    const checkType = this.props.type === 'large';

    const slides = this.props.images.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item}
        >
          {checkType ? (
            <img src={item} className='d-block img-fluid' alt='vessel images' />
          ) : (
            <img
              src={item}
              className='d-block img-fluid'
              style={{ width: '444px', height: '296px' }}
              alt='vessel images'
            />
          )}
        </CarouselItem>
      );
    });

    return (
      <React.Fragment>
        <Carousel
          activeIndex={activeIndex}
          fade={true}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators
            items={this.props.images}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          <CarouselControl
            direction='prev'
            directionText='Previous'
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction='next'
            directionText='Next'
            onClickHandler={this.next}
          />
        </Carousel>
      </React.Fragment>
    );
  }
}

export default Slidewithfade;
