import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthLayout from './AuthLayout';
import NoAuthLayout from './NoAuthLayout';

function Layout(props) {
  // render if Auth Layout
  const AuthLayoutContent = (props) => {
    return <AuthLayout theme='white'>{props.children}</AuthLayout>;
  };

  // render if Non-Auth Layout
  const NonAuthLayoutContent = (props) => {
    return <NoAuthLayout theme='white'>{props.children}</NoAuthLayout>;
  };

  const LayoutContent = (props)=>{
    return <AuthLayout theme='white'>{props.children}</AuthLayout>
  }

  return (
    <LayoutContent {...props} />
  );
}

export default withRouter(Layout);