import React, { Component } from 'react';
import Stepper from 'react-stepper-horizontal';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import GeneralForm from './GeneralForm';
import MoreDetailsForm from './MoreDetailsForm';
import CompanyInfoForm from './CompanyInfoForm';
import { withFirebase } from '../../components/Firebase';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { changeRegLoading } from '../../redux/actions';
import { connect } from 'react-redux';

const MySwal = withReactContent(Swal);

class Form extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.verifyEmail = this.verifyEmail.bind(this);
    this.state = {
      emailError: false,
      phoneError: false,
      page: 0,
      steps: [
        { title: 'Account Registration' },
        { title: 'Personal Info' },
        { title: 'Company Details' },
      ],
    };
  }

  verifyPhone(values) {
    try {
      const checkPhone = this.props.firebase.functions.httpsCallable(
        'checkPhoneNumber'
      );
      checkPhone({ phoneNumber: values.phone })
        .then((result) => {
          if (result.data !== null) {
            this.props.changeRegLoading(false);
            this.setState({ phoneError: true });
          }
        })
        .catch(() => {
          this.props.changeRegLoading(false);
          this.setState({ page: this.state.page + 1 });
        });
    } catch (e) {
      MySwal.fire({
        icon: 'error',
        text: 'An error occurred while validating your phone number',
      });
    }
  }

  verifyEmail(values) {
    this.setState({ emailError: false });
    this.props.changeRegLoading(true);

    try {
      const checkEmail = this.props.firebase.functions.httpsCallable(
        'checkEmailAddress'
      );
      checkEmail({ emailAddress: values.email })
        .then((result) => {
          if (result.data !== null) {
            this.props.changeRegLoading(false);
            this.setState({ emailError: true });
          }
        })
        .catch(() => {
          this.verifyPhone(values);
        });
    } catch (e) {
      MySwal.fire({
        icon: 'error',
        text: 'An error occurred while validating your email',
      });
    }
  }
  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  render() {
    const { onSubmit } = this.props;
    const { page, steps, emailError, phoneError } = this.state;

    return (
      <div className='dg__account section-padding--xs'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='single__account'>
                <h3>Register Account</h3>
                <p>
                  Register below for unlimited access to goods and services in
                  the maritime industry.
                </p>
                <Stepper
                  steps={steps}
                  activeStep={page}
                  completeColor='#002556'
                />
                {page === 0 && (
                  <GeneralForm
                    onSubmit={this.verifyEmail}
                    emailError={emailError}
                    phoneError={phoneError}
                  />
                )}
                {page === 1 && (
                  <MoreDetailsForm
                    previousPage={this.previousPage}
                    onSubmit={this.nextPage}
                  />
                )}
                {page === 2 && (
                  <CompanyInfoForm
                    previousPage={this.previousPage}
                    onSubmit={onSubmit}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeRegLoading: (registrationLoading) => {
    dispatch(changeRegLoading(registrationLoading));
  },
});

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withFirebase
)(Form);
