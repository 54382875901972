import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from '../../components/Firebase';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  changeMyVesselsData,
  getMyVesselsSearchStatus,
  getMyVesselsSearchError,
  changeMyVesselsStatus,
} from '../../redux/actions';

class ProfileNav extends Component {
  constructor(props) {
    super(props);
    this.state = { details: JSON.parse(localStorage.getItem('marexUser')) };
    this.unsubscribeMyVessels = null;
  }

  componentDidUpdate(prevProps) {
    const { myVesselsStatus } = this.props;

    if (prevProps.myVesselsStatus !== myVesselsStatus) {
      if (myVesselsStatus) {
        this.onFetchMyVessels();
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribeMyVessels = null;
  }

  onFetchMyVessels = async () => {
    this.props.getMyVesselsSearchError('');
    this.props.getMyVesselsSearchStatus(false);
    this.props.changeMyVesselsStatus(false);

    try {
      this.unsubscribeMyVessels = this.props.firebase
        .fetchYourVessels(this.state.details.UID)
        .orderBy('createdAt', 'desc')
        .onSnapshot(
          async (querySnapshot) => {
            if (querySnapshot.empty) {
              this.props.getMyVesselsSearchError(
                'You have no registered vessels.'
              );
              this.props.getMyVesselsSearchStatus(true);
              this.props.changeMyVesselsData([]);
            }
            await querySnapshot.docChanges().forEach(
              async (change) => {
                const doc = change.doc;
                const item = doc.data();
                item.docId = doc.id;
                const pastList = [...this.props.myVesselsData, item];

                if (change.type === 'added') {
                  const filterList = _.uniqBy(pastList, 'docId');
                  await this.props.changeMyVesselsData(filterList);
                  this.props.getMyVesselsSearchStatus(true);
                } else if (change.type === 'removed') {
                  const newData = pastList.filter(
                    (task) => task.docId !== doc.id
                  );
                  const filterList = _.uniqBy(newData, 'docId');
                  await this.props.changeMyVesselsData(filterList);
                } else if (change.type === 'modified') {
                  const newData = pastList.filter(
                    (task) => task.docId !== doc.id
                  );
                  const newList = [...newData, item];
                  const filterList = _.uniqBy(newList, 'docId');
                  await this.props.changeMyVesselsData(filterList);
                }
              },
              (err) => {
                this.props.getMyVesselsSearchError(
                  `Error occurred while fetching your vessels. ${err}`
                );
                this.props.getMyVesselsSearchStatus(true);
                this.props.changeMyVesselsData([]);
              }
            );
          },
          (err) => {
            this.props.getMyVesselsSearchError(
              `Error occurred while fetching your vessels. ${err}`
            );
            this.props.getMyVesselsSearchStatus(true);
            this.props.changeMyVesselsData([]);
          }
        );
    } catch (e) {
      this.props.getMyVesselsSearchError(
        `Error occurred while fetching your vessels. ${e}`
      );
      this.props.getMyVesselsSearchStatus(true);
      this.props.changeMyVesselsData([]);
    }
  };

  render() {
    return (
      <li>
        <Link to="/dashboard">
          Profile
        </Link>
      </li>
    );
  }
}
const mapStateToProps = (state) => ({
  myVesselsData: state.vessel.myVesselsData,
  myVesselsStatus: state.vessel.myVesselsStatus,
});

const mapDispatchToProps = (dispatch) => ({
  changeMyVesselsData: (myVesselsData) => {
    dispatch(changeMyVesselsData(myVesselsData));
  },
  getMyVesselsSearchError: (myVesselsSearchError) => {
    dispatch(getMyVesselsSearchError(myVesselsSearchError));
  },
  getMyVesselsSearchStatus: (myVesselsSearchStatus) => {
    dispatch(getMyVesselsSearchStatus(myVesselsSearchStatus));
  },
  changeMyVesselsStatus: (myVesselsStatus) => {
    dispatch(changeMyVesselsStatus(myVesselsStatus));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withFirebase
)(ProfileNav);
