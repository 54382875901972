import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import MainSlider from '../containers/hero-sliders/main';
import VesselsGrid from '../containers/vessels-grid/VesselsGrid';
import Slider from '../components/Slider';
import SmallAds from '../containers/small-ads';
import MediumAd from '../containers/medium-ad';
import BasicSearch from '../containers/search/BasicSearch';
import { useDispatch } from 'react-redux';
import { changeFormatTextLength } from '../redux/actions';

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeFormatTextLength(22));
  }, [dispatch]);

  return (
    <Fragment>
      <MetaTags>
        <title>Oceanic Links</title>
        <meta name='description' content='Homepage of Maritime Exchange' />
      </MetaTags>
      <MainSlider />
      <BasicSearch/>
      {/* <Slider/> */}
      <div className='container'>
        <div className='row'>
          <VesselsGrid />
          <aside className='col-md-3 col-xs-12 mt--50'>
            <SmallAds />
            <MediumAd />
          </aside>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(Home);
