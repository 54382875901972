import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaPhone,
  FaRegEnvelope,
  FaUserAlt,
  FaUserAltSlash,
} from 'react-icons/fa';
import { AuthUserContext } from '../../Session';
import ProfileNav from '../ProfileNav';

const AuthNav = () => (
  <AuthUserContext.Consumer>
    {(marexUser) => (marexUser ? <AuthButtons /> : <NonAuthButtons />)}
  </AuthUserContext.Consumer>
);

const NonAuthButtons = () => (
  <li>
    <FaUserAlt /> <Link to={'/login'}>Login</Link> /{' '}
    <Link to={'/register'}>Register</Link>
  </li>
);

const AuthButtons = () => (
  <React.Fragment>
    <li>
      <FaUserAlt /> <ProfileNav />
    </li>
    <li>
      <FaUserAltSlash /> <Link to={'/logout'}>Logout</Link>
    </li>
  </React.Fragment>
);

const MobileWidgets = () => {
  return (
    <div className='offcanvasWidgetArea'>
      <div className='offCanvasContactWidget'>
        <div className='headerContactInfo'>
          <ul className='headerContactInfoList'>
            <AuthNav />
            <li>
              <FaPhone /> <a href='tel://12452456012'>(1245) 2456 012 </a>
            </li>
            <li>
              <FaRegEnvelope />{' '}
              <a href='mailto:info@yourdomain.com'>info@yourdomain.com</a>
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      <div className='offCanvasWidgetSocial'>
        <a
          href='//twitter.com'
          target='_blank'
          rel='noopener noreferrer'
          title='Twitter'
        >
          <FaTwitter />
        </a>
        <a
          href='//instagram.com'
          target='_blank'
          rel='noopener noreferrer'
          title='Instagram'
        >
          <FaInstagram />
        </a>
        <a
          href='//facebook.com'
          target='_blank'
          rel='noopener noreferrer'
          title='Facebook'
        >
          <FaFacebookF />
        </a>
      </div>
      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;
