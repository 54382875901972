// import Dashboard from './pages/Dashboard';
import MyDetailsContainer from './containers/dashboard/MyDetailsContainer';
import VesselsContainer from './containers/dashboard/VesselsContainer';
import RegisterVessel from './components/Dashboard/RegisterVessel';
import EditVessel from './components/Dashboard/EditVessel';
import VesselDetails from './components/Vessel/Details';
import UserVessels from './containers/user-vessels';
import Search from './pages/Search';
import UserProfile from './components/User/Profile';

import Login from './pages/Login';
import Logout from './pages/Auth/Logout';
import Register from './pages/Register';
import Forgot from './pages/Forgot';
import About from './pages/About';
import Home from './pages/Home';
import CreateAdvert from './components/Dashboard/CreateAdvert';
import AdvertsContainer from './containers/dashboard/AdvertsContainer';

const routes = [
  // public Routes
  { path: '/login', component: Login, ispublic: true },
  { path: '/register', component: Register, ispublic: true },
  { path: '/logout', component: Logout, ispublic: true },
  { path: '/forgotpassword', component: Forgot, ispublic: true },

  // Dashnoard
  { path: '/dashboard', component: MyDetailsContainer },
  { path: '/manage-fleet', component: VesselsContainer },
  { path: '/manage-adverts', component: AdvertsContainer },
  { path: '/register-vessel', component: RegisterVessel },
  { path: '/create-advert/:uid', component: CreateAdvert },
  { path: '/edit-vessel/:uid', component: EditVessel },
  { path: '/vessel/:uid', component: VesselDetails },
  { path: '/userpage/:uid', component: UserProfile },
  { path: '/home', component: Home },
  { path: '/about/:section', component: About },
  { path: '/about', component: About },
  { path: '/search', component: Search },
  { path: '/', component: Home, ispublic: true },
];

export default routes;
