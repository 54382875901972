import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosMenu } from 'react-icons/io';
import { AuthUserContext } from '../Session';
import ProfileNav from './ProfileNav';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {(marexUser) => (marexUser ? <AuthButtons /> : <NonAuthButtons />)}
  </AuthUserContext.Consumer>
);

const AuthButtons = () => (
  <ul className='accounts d-none d-lg-flex'>
    <ProfileNav />
    <li className='active'>
      <Link to='/logout'>Logout</Link>
    </li>
  </ul>
);

const NonAuthButtons = () => (
  <ul className='accounts d-none d-lg-flex'>
    <li className='active'>
      <Link to='/register'>Join us</Link>
    </li>
    <li>
      <Link to='/login'>Log in</Link>
    </li>
  </ul>
);

const HeaderBtn = () => {
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      '#offcanvas-mobile-menu'
    );
    offcanvasMobileMenu.classList.add('active');
  };
  return (
    <div className='header-btn-wrapper'>
      <Navigation />
      <div className='mobile-button-wrapper d-block d-lg-none text-right'>
        <button
          className='mobile-aside-button'
          onClick={() => triggerMobileMenu()}
        >
          <IoIosMenu />
        </button>
      </div>
    </div>
  );
};

export default HeaderBtn;
