import React, { Component } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { FaEdit, FaAd } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { withFirebase } from '../Firebase';
import { GiShipWheel } from 'react-icons/gi';
import { CgProfile } from 'react-icons/cg';
import { FaShip } from 'react-icons/fa';
import { Link, withRouter } from 'react-router-dom';
import { withAuthorization } from '../Session';
import { Button } from 'reactstrap';
import {
  changeMyVesselsData,
  changeMyVesselsStatus,
  changeSelectedData,
} from '../../redux/actions';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TabBar from './TabBar';

const MySwal = withReactContent(Swal);

const errorType = 'Error occurred while fetching your vessels.';

class MyVessels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.onFetchMyVessels();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.myVesselsData !== this.props.myVesselsData) {
      this.onFetchMyVessels();
    }
  }

  handleReload() {
    this.setState({ isLoading: true });
    this.props.changeMyVesselsStatus(true);
  }

  handleEdit(item) {
    this.props.changeSelectedData(item);
    this.props.history.push(`/edit-vessel/${item.docId}`);
  }

  handleDelete(item) {
    MySwal.fire({
      title: `Are you sure you want to delete the ${item.vessel_name}?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.props.firebase
          .deleteVessel(item.docId)
          .then(() => {
            MySwal.fire('Deleted!', 'The vessel has been deleted.', 'success');
          })
          .catch(() => {
            MySwal.fire(
              'Error',
              'An error occurred. Please try again',
              'error'
            );
          });
      }
    });
  }

  onCreateAdvert = (vessel) => {
    this.props.changeSelectedData(vessel);
    this.props.history.push(`/create-advert/${vessel.docId}`, vessel);
  };

  onFetchMyVessels = () => {
    const {
      myVesselsSearchError,
      myVesselsSearchStatus,
      myVesselsData,
    } = this.props;
    const checkError = myVesselsSearchError === '';
    const checkList = myVesselsData.length === 0;

    if (checkError && !myVesselsSearchStatus && checkList) {
      this.setState({ isLoading: true, errorMessage: myVesselsSearchError });
      setTimeout(() => this.props.changeMyVesselsStatus(true), 3000);
    } else if (!checkError && myVesselsSearchStatus && checkList) {
      this.setState({ isLoading: false, errorMessage: myVesselsSearchError });
    } else if (!checkList) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { errorMessage } = this.state;
    const checkError = errorType === errorMessage;
    const checkAny = errorMessage !== '';
    return (
      <React.Fragment>
        <Breadcrumb title='My Vessels' />
        <div className='dg__market__data pt--50 pb--140'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='market__title'>
                  <h2>Dashboard</h2>
                </div>
                <TabBar />
                {checkError && (
                  <Col sm={{ size: 5, offset: 4 }}>
                    <p>
                      <strong>{errorMessage} </strong>
                      <br />
                      <Col sm={{ size: 12, offset: 3 }}>
                        <Button
                          type='button'
                          color='danger'
                          onClick={() => this.handleReload()}
                        >
                          Reload
                        </Button>
                      </Col>
                    </p>
                  </Col>
                )}
                {this.state.isLoading ? (
                  <Row>
                    <Col sm='5' />
                    <Col sm='3'>
                      <Spinner
                        type='grow'
                        style={{ width: '9rem', height: '9rem' }}
                        color='primary'
                      />
                    </Col>
                  </Row>
                ) : checkAny ? (
                  <Col sm={{ size: 5, offset: 4 }}>
                    <h4 style={{ textAlign: 'center' }}>{errorMessage}</h4>
                    <br />
                    <Col
                      sm={{ size: 12, offset: 2 }}
                      style={{ textAlign: 'center' }}
                    >
                      <button
                        className='btn'
                        onClick={() =>
                          this.props.history.push('/register-vessel')
                        }
                      >
                        Register One
                      </button>
                    </Col>
                  </Col>
                ) : (
                  <div className='container list-t-border'>
                    {this.props.myVesselsData.map((vessel, idx) => {
                      return (
                        <div key={idx} className='bg-hover'>
                          <div className='my-pro-list'>
                            <Row>
                              <div className='col-sm-2 col-md-2 col-xs-12'>
                                <img src={vessel.images[0]} alt='vesselImage' />
                              </div>
                              <div className='col-sm-8 col-md-8 col-xs-12'>
                                <div>
                                  <h4>{vessel.vessel_name}</h4>
                                  <p>{vessel.vesselType}</p>
                                  <span>
                                    <b>Status:</b> {vessel.status} <br />
                                    <strong>Verification:</strong>{' '}
                                    <span
                                      className={
                                        vessel.disabled
                                          ? 'color-danger'
                                          : 'color-success'
                                      }
                                    >
                                      {vessel.disabled ? 'Pending' : 'Verified'}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div className='col-md-2 col-sm-2 col-xs-12'>
                                <div className='select-pro-list'>
                                  <span
                                    onClick={() => this.handleEdit(vessel)}
                                    className='controls-list'
                                  >
                                    <FaEdit />
                                  </span>
                                  <span
                                    onClick={() => this.handleDelete(vessel)}
                                    className='controls-list'
                                  >
                                    <AiFillDelete />
                                  </span>
                                  {!vessel.disabled && (
                                    <span
                                      onClick={() =>
                                        this.onCreateAdvert(vessel)
                                      }
                                      className='controls-list'
                                    >
                                      <FaAd />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Row>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  myVesselsData: state.vessel.myVesselsData,
  myVesselsSearchStatus: state.vessel.myVesselsSearchStatus,
  myVesselsSearchError: state.vessel.myVesselsSearchError,
});

const mapDispatchToProps = (dispatch) => ({
  changeMyVesselsData: (myVesselsData) => {
    dispatch(changeMyVesselsData(myVesselsData));
  },
  changeMyVesselsStatus: (myVesselsStatus) => {
    dispatch(changeMyVesselsStatus(myVesselsStatus));
  },
  changeSelectedData: (selectedData) => {
    dispatch(changeSelectedData(selectedData));
  },
});

const condition = (authUser) => !!authUser;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withAuthorization(condition),
  withFirebase
)(MyVessels);
