import React from 'react';
import MyDetails from '../../components/Dashboard/MyDetails';
import MetaTags from 'react-meta-tags';
import { withAuthorization } from '../../components/Session';

class MyDetailsContainer extends React.Component {
  constructor() {
    super();
    this.state = { details: JSON.parse(localStorage.getItem('marexUser')) };
  }

  componentDidMount() {
    if (!this.state.details) {
    }
  }

  updateDetailsHandler = (data) => {
  };

  uploadProfilePicHandler = (file) => {
  };

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Dashboard | Marex</title>
          <meta
            name='description'
            content="Your account's dashboard gives you control over your account settings and more"
          />
        </MetaTags>
        <MyDetails
          details={this.state.details}
          updateDetails={this.updateDetailsHandler}
          uploadProfilePic={this.uploadProfilePicHandler}
        />
      </React.Fragment>
    );
  }
}
const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(MyDetailsContainer);
