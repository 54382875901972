import React, { Component, Fragment, createRef } from 'react';
import {
  changeSelectedVessel,
  setVesselURL,
  changeUser1,
  changeUser2,
  changeUser3,
  changeUser4,
  changeUser5,
  changeFormatTextLength,
  changeSearchQuery,
} from '../../../src/redux/actions';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { Spinner } from 'reactstrap';
import _ from 'lodash';
import { withRouter, Redirect, Link } from 'react-router-dom';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import Slidewithfade from '../Ui/slider';
import { FaGoogle, FaFacebook, FaTwitter } from 'react-icons/fa';
import SmallAds from '../../containers/small-ads';
import { MdSearch } from 'react-icons/md';
import SimilarVessels from '../../containers/similar-vessels';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      notFound: false,
      selectedUser: {},
    };
    this.textInput = createRef();
    this.unsubscribeFetch = null;
    this.unsubscribePosterFetch = null;
  }

  componentWillUnmount() {
    this.unsubscribeFetch = null;
    this.unsubscribePosterFetch = null;
  }

  checkCache = (type, doc) => {
    const selectedVessel = this.props.selectedVessel;
    //check if the account details of the poster has already been cached, if not fetch from db if mounting or store in cache if fetching poster
    if (this.props.user1.UID === selectedVessel.accountId) {
      return this.setState({
        selectedUser: this.props.user1,
        isLoading: false,
      });
    } else if (this.props.user2.UID === selectedVessel.accountId) {
      return this.setState({
        selectedUser: this.props.user2,
        isLoading: false,
      });
    } else if (this.props.user3.UID === selectedVessel.accountId) {
      return this.setState({
        selectedUser: this.props.user3,
        isLoading: false,
      });
    } else if (this.props.user4.UID === selectedVessel.accountId) {
      return this.setState({
        selectedUser: this.props.user4,
        isLoading: false,
      });
    } else if (this.props.user5.UID === selectedVessel.accountId) {
      return this.setState({
        selectedUser: this.props.user5,
        isLoading: false,
      });
    } else {
      if (type === 'mount/details') {
        return this.fetchPoster();
      } else if (type === 'poster') {
        //deciding which cache slot to store the newly fetched user details
        if (_.isEmpty(this.props.user1)) {
          this.props.changeUser1(doc);
          this.setState({ selectedUser: doc });
        } else if (_.isEmpty(this.props.user2)) {
          this.props.changeUser2(doc);
          this.setState({ selectedUser: doc });
        } else if (_.isEmpty(this.props.user3)) {
          this.props.changeUser3(doc);
          this.setState({ selectedUser: doc });
        } else if (_.isEmpty(this.props.user4)) {
          this.props.changeUser4(doc);
          this.setState({ selectedUser: doc });
        } else if (_.isEmpty(this.props.user5)) {
          this.props.changeUser5(doc);
          this.setState({ selectedUser: doc });
        }
        //if every slot is filled, replace slot 1
        else {
          this.props.changeUser1(doc);
          this.setState({ selectedUser: doc });
        }
        this.setState({
          isLoading: false,
        });
      }
    }
  };

  componentDidMount() {
    this.props.changeFormatTextLength(33);
    const selectedVessel = this.props.selectedVessel;
    this.props.setVesselURL(`${this.props.match.params.uid}`);

    if (!_.isEmpty(selectedVessel)) {
      if (selectedVessel.vesselId !== this.props.match.params.uid) {
        this.fetchDetails();
      } else {
        //check if the account details of the poster has already been cached, if not fetch from db
        this.checkCache('mount/details');
      }
    } else {
      this.fetchDetails();
    }
  }

  fetchDetails = () => {
    try {
      this.unsubscribeFetch = this.props.firebase
        .fetchVesselDetails(this.props.match.params.uid)
        .onSnapshot(
          (doc) => {
            if (doc.exists) {
              if (doc.metadata.hasPendingWrites) {
                return;
              }
              this.props.changeSelectedVessel(doc.data());
              //after fetching, check if the user details is already cached, if not fetch
              this.checkCache('mount/details');
            } else {
              this.setState({ notFound: true });
            }
          },
          (err) => {
            toast.notify(`Error while updating: ${err}`, {
              duration: 3000,
            });
            this.setState({ isLoading: false });
          }
        );
    } catch (e) {
      this.setState({ isLoading: false });
      toast.notify(`Error while fetching: ${e}`, {
        duration: null,
      });
    }
  };

  fetchPoster = () => {
    try {
      this.unsubscribePosterFetch = this.props.firebase
        .fetchUserDetails(this.props.selectedVessel.accountId)
        .onSnapshot(
          (doc) => {
            if (doc.exists) {
              if (doc.metadata.hasPendingWrites) {
                return;
              }
              //deciding which cache slot to store the newly fetched user details
              this.checkCache('poster', doc.data());
            }
          },
          (err) => {
            toast.notify('Error while updating user', {
              duration: 3000,
            });
            this.setState({ isLoading: false });
          }
        );
    } catch (e) {
      this.setState({ isLoading: false });
      toast.notify('Error while fetching user', {
        duration: 300,
      });
    }
  };

  render() {
    const { isLoading, notFound, selectedUser } = this.state;
    const selected = this.props.selectedVessel;

    if (notFound) {
      return <Redirect to='/' />;
    }

    return (
      <Fragment>
        {isLoading ? (
          <div className='row' style={{ paddingTop: '100px' }}>
            <div className='col-sm-5'></div>
            <div className='col-sm-3'>
              <Spinner
                type='grow'
                style={{ width: '9rem', height: '9rem' }}
                color='primary'
              />
            </div>
          </div>
        ) : (
          <div className='dg__blog__area bg--white section-padding--xl'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
                  <div className='dg__blog__details right--sidebar'>
                    <div className='dg__blog__dtl'>
                      <div className='thumb'>
                        <h2>{selected.vessel_name}</h2>
                        {selected.images && (
                          <Slidewithfade
                            images={selected.images}
                            type='large'
                          />
                        )}
                      </div>
                      <div>
                        <h2 className='blog__title'>Quick Summary</h2>
                        <div className='row'>
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <table
                              className='table table-striped table-responsive'
                              style={{ display: 'table' }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Vessel Name</b>
                                  </td>
                                  <td className='text-right'>
                                    {selected.vessel_name}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Flag</b>
                                  </td>
                                  <td className='text-right'>
                                    {selected.flag}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>IMO Number</b>
                                  </td>
                                  <td className='text-right'>
                                    {selected.imo_number}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Type of Vessel</b>
                                  </td>
                                  <td className='text-right'>
                                    {selected.vesselType}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Status</b>
                                  </td>
                                  <td className='text-right'>
                                    {selected.status}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Port</b>
                                  </td>
                                  <td className='text-right'>
                                    {selected.port}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <table
                              className='table table-striped table-responsive'
                              style={{ display: 'table' }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Decks</b>
                                  </td>
                                  <td className='text-right'>
                                    {selected.decks}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Engines</b>
                                  </td>
                                  <td className='text-right'>
                                    {selected.engines}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Gross Tonage</b>
                                  </td>
                                  <td className='text-right'>
                                    {selected.gross_tonnage}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Year & Place Built</b>
                                  </td>
                                  <td className='text-right'>
                                    {selected.year_place_built}
                                  </td>
                                </tr>
                                {selected.previous_flag && (
                                  <tr>
                                    <td>
                                      <b>Previous Flag</b>
                                    </td>
                                    <td className='text-right'>
                                      {selected.previous_flag}
                                    </td>
                                  </tr>
                                )}
                                {selected.previous_name && (
                                  <tr>
                                    <td>
                                      <b>Previous Name</b>
                                    </td>
                                    <td className='text-right'>
                                      {selected.previous_name}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {selectedUser && (
                          <Fragment>
                            <h2 className='blog__title'>Contact the Poster</h2>
                            <div className='row'>
                              <div className='col-sm-6'>
                                <div className='agent_wrap'>
                                  <div className='image'>
                                    {selectedUser.profile_picture ? (
                                      <img
                                        src={selectedUser.profile_picture}
                                        alt='user pic'
                                        style={{
                                          width: '360px',
                                          height: '383px',
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src='images/unknown.png'
                                        alt='user pic'
                                        style={{
                                          width: '360px',
                                          height: '383px',
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-6'>
                                <div className='agent_wrap'>
                                  <h3>
                                    <Link
                                      to={{
                                        pathname: `/userpage/${selectedUser.UID}`,
                                      }}
                                    >
                                      {selectedUser.firstname}{' '}
                                      {selectedUser.lastname}
                                    </Link>
                                  </h3>
                                  <table className='table'>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Account</strong>
                                        </td>
                                        <td className='text-right'>
                                          {selectedUser.accountType}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Phone Number:</strong>
                                        </td>
                                        <td className='text-right'>
                                          {selectedUser.phone}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Email Address:</strong>
                                        </td>
                                        <td className='text-right'>
                                          <a
                                            href={`mailto:${selectedUser.email}`}
                                          >
                                            {selectedUser.email}
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <strong>Company Name:</strong>
                                        </td>
                                        <td className='text-right'>
                                          {selectedUser.company_name}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <h2
                              className='blog__title'
                              style={{ marginTop: '30px' }}
                            >
                              Similar Vessels
                            </h2>
                            <div className='row'>
                              <div className='col-sm-12'>
                                <SimilarVessels />
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                    <div className='blog__pagination'>
                      <ul className='bl__dtl__tag'>
                        <li>
                          <a
                            href='//rss.com'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <FaFacebook />
                          </a>
                        </li>
                        <li>
                          <a
                            href='//pinterest.com'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <FaTwitter />
                          </a>
                        </li>
                        <li>
                          <a
                            href='//gogole.com'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <FaGoogle />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-12 col-sm-12 col-12 mt--50'>
                  <div className='single__widget search'>
                    <input
                      ref={this.textInput}
                      type='text'
                      placeholder='search vessels'
                    />
                    <button
                      onClick={() => {
                        this.props.changeSearchQuery(
                          this.textInput.current.value
                        );
                        this.props.history.push('/search');
                      }}
                    >
                      <MdSearch />
                    </button>
                  </div>
                  <SmallAds />
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedVessel: state.vessel.selectedVessel,
  user1: state.user.user1,
  user2: state.user.user2,
  user3: state.user.user3,
  user4: state.user.user4,
  user5: state.user.user5,
});

const mapDispatchToProps = (dispatch) => ({
  changeFormatTextLength: (formatTextLength) => {
    dispatch(changeFormatTextLength(formatTextLength));
  },
  changeSearchQuery: (searchQuery) => {
    dispatch(changeSearchQuery(searchQuery));
  },
  changeSelectedVessel: (selectedVessel) => {
    dispatch(changeSelectedVessel(selectedVessel));
  },
  setVesselURL: (vesselURL) => {
    dispatch(setVesselURL(vesselURL));
  },
  changeUser1: (user1) => {
    dispatch(changeUser1(user1));
  },
  changeUser2: (user2) => {
    dispatch(changeUser2(user2));
  },
  changeUser3: (user3) => {
    dispatch(changeUser3(user3));
  },
  changeUser4: (user4) => {
    dispatch(changeUser4(user4));
  },
  changeUser5: (user5) => {
    dispatch(changeUser5(user5));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withFirebase
)(Details);
