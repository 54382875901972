import { GET_ADVERTS, GET_ADVERT_PACKAGES } from "../actions/types";

const initialState = {
  advertPackages: [],
  adverts: [],
};

const AdvertReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADVERT_PACKAGES:
      return { ...state, advertPackages: action.payload };
    case GET_ADVERTS:
      return { ...state, adverts: action.payload };
    default:
      return state;
  }
};

export default AdvertReducer;
