import React, { Fragment, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Alert, Button, Spinner, Col, Row, Modal } from 'reactstrap';
import moment from 'moment';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { withFirebase } from '../components/Firebase';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PasswordMask from 'react-password-mask';
import Breadcrumb from '../components/breadcrumbs/Breadcrumb';
import { changeOTPModal, setCaptchaId } from '../redux/actions';

const MySwal = withReactContent(Swal);

function Login(props) {
  const [error, setError] = useState(null);
  const [maskedNo, setMaskedNo] = useState('');
  const { register, handleSubmit, errors, reset } = useForm();
  const dispatch = useDispatch();
  const showOTPModal = useSelector((store) => store.auth.showOTPModal);
  const captchaId = useSelector((store) => store.auth.captchaId);

  const [loading, setLoading] = useState(false);
  const [eventDate, setEventDate] = useState(
    moment.duration().add({ seconds: 59 })
  );
  const [countdownSeconds, setCountdownSeconds] = useState(59);
  const history = useHistory();
  let appVerifier = null;

  function onSubmit(event, values) {
    setLoading(true);
    props.firebase
      .signIn(values.email, values.password)
      .then(async (userCred) => {
        history.push('/dashboard');

        // console.log('i got in');
        // dispatch(changeOTPModal(true));
        // const no = userCred.user.phoneNumber;
        // setMaskedNo(no.slice(no.length - 10));
        // window.appVerifier = new props.firebase.fireAuth.RecaptchaVerifier(
        //   'recaptcha-container',
        //   {
        //     size: 'invisible',
        //   }
        // );
        // const captchaVerificationId = await props.firebase.phoneVerify.verifyPhoneNumber(
        //   values.phone,
        //   appVerifier
        // );
        // dispatch(setCaptchaId(captchaVerificationId));
        // let x = setInterval(() => {
        //   if (eventDate <= 0) {
        //     clearInterval(x);
        //   } else {
        //     const newEventDate = eventDate.subtract(1, 's');
        //     const countdownSeconds = newEventDate.seconds();
        //     setEventDate(newEventDate);
        //     setCountdownSeconds(countdownSeconds);
        //   }
        // }, 1000);
        // props.firebase.signOut();
      })
      .catch((err) => {
        if (err.code === 'auth/user-disabled') {
          setError({ message: 'Sorry, this email has been disabled' });
          setLoading(false);
        } else if (
          err.code === 'auth/user-not-found' ||
          err.code === 'auth/wrong-password'
        ) {
          setError({ message: 'Incorrect email address or password' });
          setLoading(false);
        } else if (err.code === 'auth/too-many-requests') {
          setError({
            message:
              'Too many unsuccessful login attempts, please try again later',
          });
          setLoading(false);
        }
      });
    event.persist();
  }

  const checkCode = async (values) => {
    console.log('values', values);
    const credential = props.firebase.phone.credential(captchaId, values.otp);

    await props.firebase.auth
      .signInWithCredential(credential)
      .then(() => {
        history.push('/dashboard');
      })
      .catch(() => {
        MySwal.fire({
          icon: 'error',
          text: `Invalid OTP entered! Please re-enter code`,
        });
      });
  };

  const resendOTP = async () => {
    const captchaVerificationId = await props.firebase.phoneVerify.verifyPhoneNumber(
      maskedNo,
      appVerifier
    );
    dispatch(setCaptchaId(captchaVerificationId));
    setEventDate(moment.duration().add({ seconds: 59 }));
    let x = setInterval(() => {
      if (eventDate <= 0) {
        console.log('cleared');
        clearInterval(x);
      } else {
        const newEventDate = eventDate.subtract(1, 's');
        const countdownSeconds = newEventDate.seconds();
        console.log('counting', countdownSeconds);
        setEventDate(newEventDate);
        setCountdownSeconds(countdownSeconds);
      }
    }, 1000);
  };

  const checkNumber = countdownSeconds > 0;
  return (
    <Fragment>
      <MetaTags>
        <title>Login</title>
        <meta name='description' content='Login to your Oceanic Links account' />
      </MetaTags>
      <Breadcrumb title='Login/Register'/>
      <div className='dg__account section-padding--xs'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='single__account'>
                <h3>Login</h3>
                {error && <Alert color='danger'>{error.message}</Alert>}

                <div className='row'>
                  <div className='col-sm-12'>
                    <AvForm onValidSubmit={onSubmit}>
                      <AvField
                        name='email'
                        label='Email Address'
                        type='email'
                        required
                        className='input__box'
                      />
                      <AvField
                        name='password'
                        label='Password'
                        type='password'
                        required
                        className='input__box'
                        tag={[PasswordMask]}
                      />
                      <Link className='forget__pass' to='/forgotpassword'>
                        Lost your password?
                      </Link>
                      <Row>
                        <Col sm='6'></Col>
                        {loading ? (
                          <Col sm='6'>
                            <Spinner color='primary' />
                          </Col>
                        ) : (
                          <Col sm='12'>
                            <Button
                              id='recaptcha-container'
                              name='recaptcha-container'
                              className='account__btn'
                            >
                              Login
                            </Button>
                          </Col>
                        )}
                      </Row>
                      <Link className='forget__pass mt-3' to='/register'>
                        Not yet on Oceanic Links? Register here
                      </Link>
                    </AvForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showOTPModal}
          onClose={() => dispatch(changeOTPModal(false))}
        >
          <div className='content'>
            <div style={{ padding: '2rem' }}>
              <h2>Enter OTP</h2>
              <p>
                Enter the 6 digit code sent to your email address and phone
                number ending with {maskedNo}
              </p>
              <div className='col-sm-12'>
                <div className='input__box'>
                  <input
                    type='password'
                    name='otp'
                    placeholder='6-digit code'
                    ref={register({
                      required: true,
                      minLength: 6,
                      maxLength: 6,
                    })}
                  />
                  {errors.otp && errors.otp.type === 'required' && (
                    <span className='errorText'>
                      <i className='fas fa-exclamation-triangle'></i> This field
                      is required
                    </span>
                  )}
                  {errors.otp &&
                    (errors.otp.type === 'minLength' ||
                      errors.otp.type === 'maxLength') && (
                      <span className='errorText'>
                        <i className='fas fa-exclamation-triangle'></i> The OTP
                        code must be 6 digits
                      </span>
                    )}
                </div>
              </div>
              <div className='col-sm-12'>
                {checkNumber ? (
                  <p className='resendText'>{countdownSeconds}s</p>
                ) : (
                  <Button
                    color='primary'
                    onClick={resendOTP}
                    className='linkButton account__btn'
                  >
                    Resend
                  </Button>
                )}
              </div>
              <div className='col-sm-12'>
                <Button
                  color='primary'
                  className='account__btn'
                  onClick={handleSubmit(checkCode)}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </Fragment>
  );
}

export default withFirebase(Login);
