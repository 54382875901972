import {
  USER_1,
  USER_2,
  USER_3,
  USER_4,
  USER_5,
  GET_USER_VESSELS_SEARCH_ERROR,
  GET_USER_VESSELS_SEARCH_STATUS,
  RELOAD_USER_VESSELS,
  USER_VESSELS_DATA,
  GET_STICKY,
  LOAD_MORE_USER_VESSELS,
} from '../actions/types';

const initialState = {
  user1: {},
  user2: {},
  user3: {},
  user5: {},
  user4: {},
  userVesselsSearchStatus: false,
  userVesselsSearchError: '',
  userVesselsStatus: false,
  userVesselsData: [],
  loadMoreUserVessels: false,
  shouldStick: false,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_1:
      return {
        ...state,
        user1: action.payload,
      };
    case USER_2:
      return {
        ...state,
        user2: action.payload,
      };
    case USER_3:
      return {
        ...state,
        user3: action.payload,
      };
    case USER_4:
      return {
        ...state,
        user4: action.payload,
      };
    case USER_5:
      return {
        ...state,
        user5: action.payload,
      };
    case GET_USER_VESSELS_SEARCH_STATUS:
      return {
        ...state,
        userVesselsSearchStatus: action.payload,
      };
    case GET_USER_VESSELS_SEARCH_ERROR:
      return {
        ...state,
        userVesselsSearchError: action.payload,
      };
    case USER_VESSELS_DATA:
      return Object.assign({}, state, {
        ...state,
        userVesselsData: action.payload.slice(),
      });
    case LOAD_MORE_USER_VESSELS:
      return {
        ...state,
        loadMoreUserVessels: action.payload,
      };
    case RELOAD_USER_VESSELS:
      return {
        ...state,
        userVesselsStatus: action.payload,
      };
    case GET_STICKY:
      return {
        ...state,
        shouldStick: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
