import React, { useState, useEffect, useRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Spinner, Col } from 'reactstrap';
import moment from 'moment';
import validate from '../../validation';
import FormInput from '../../components/FormInput';
import { Button } from 'reactstrap';
import { compose } from 'recompose';
import { FaCheck } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { withFirebase } from '../../components/Firebase';
import { FcAbout } from 'react-icons/fc';
import IncorpCert from '../../components/FileUploader/IncorpCert';
import CAC7 from '../../components/FileUploader/CAC7';
import Modal from '../../components/Modal/modal';
import {
  changeOTPModal,
  setCaptchaId,
  changeRegLoading,
} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';

const CompanyInfoForm = (props) => {
  const dispatch = useDispatch();
  const { handleSubmit, previousPage } = props;
  const [eventDate, setEventDate] = useState(
    moment.duration().add({ seconds: 59 })
  );
  const [countdownSeconds, setCountdownSeconds] = useState(59);
  let appVerifier = null;
  // const x = useRef(null);
  const loadingStatus = useSelector((store) => store.auth.registrationLoading);
  const showOTPModal = useSelector((store) => store.auth.showOTPModal);
  const values = useSelector((store) => store.form.wizardForm.values);

  const resendOTP = async ()=>{
    const captchaVerificationId = await props.firebase.phoneVerify.verifyPhoneNumber(
      values.phone,
      appVerifier
    );
    dispatch(setCaptchaId(captchaVerificationId));
    setEventDate(moment.duration().add({ seconds: 59 }));
    let x = setInterval(() => {
      if (eventDate <= 0) {
        console.log('cleared');
        clearInterval(x);
      } else {
        const newEventDate = eventDate.subtract(1, 's');
        const countdownSeconds = newEventDate.seconds();
        console.log('counting', countdownSeconds);
        setEventDate(newEventDate);
        setCountdownSeconds(countdownSeconds);
      }
    }, 1000);
  }

  const captcha = async () => {
    window.appVerifier = new props.firebase.fireAuth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      }
    );
    appVerifier = window.appVerifier;
    dispatch(changeOTPModal(true));
    const captchaVerificationId = await props.firebase.phoneVerify.verifyPhoneNumber(
      values.phone,
      appVerifier
    );
    dispatch(setCaptchaId(captchaVerificationId));
    let x = setInterval(() => {
      if (eventDate <= 0) {
        clearInterval(x);
      } else {
        const newEventDate = eventDate.subtract(1, 's');
        const countdownSeconds = newEventDate.seconds();
        setEventDate(newEventDate);
        setCountdownSeconds(countdownSeconds);
      }
    }, 1000);
  };

  const checkNumber = countdownSeconds > 0;
  return (
    <form onSubmit={handleSubmit} style={{ marginTop: '15px' }}>
      <div className='row'>
        <div className='col-sm-6'>
          <div className='input__box'>
            <span>Company Name</span>
            <Field name='company_name' type='text' component={FormInput} />
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field
              name='company_address'
              type='text'
              component={FormInput}
              label='Company Address'
            />
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field
              name='company_city'
              type='text'
              component={FormInput}
              label='City'
            />
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field
              name='company_state'
              type='text'
              component={FormInput}
              label='Region/State/Province'
            />
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field
              name='company_country'
              type='text'
              component={FormInput}
              label='Country'
            />
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field
              name='designation'
              type='text'
              component={FormInput}
              label='Designation'
            />
          </div>
        </div>
        {/* <div className='col-sm-12'>
          <div className='input__box mb-4'>
            <span>
              Certificate of Incorporation{' '}
              <FcAbout data-tip='only images or pdf files of less than 1MB are allowed'></FcAbout>
              <ReactTooltip place='top' type='dark' effect='solid' />
            </span>
            <IncorpCert />
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='input__box mb-5'>
            <span>
              Form CAC 7 (Particulars of Directors){' '}
              <FcAbout data-tip='only images or pdf files of less than 1MB are allowed'></FcAbout>
              <ReactTooltip place='top' type='dark' effect='solid' />
            </span>
            <CAC7 />
          </div>
        </div> */}
        {loadingStatus ? (
          <Col sm={{ size: 6, offset: 6 }} className='mt-3'>
            <Spinner color='primary' />
          </Col>
        ) : (
          <React.Fragment>
            <div className='col-sm-6'>
              <Button
                color='primary'
                className='account__btn'
                onClick={previousPage}
              >
                <i className='fas fa-chevron-left' />
                &nbsp; Previous
              </Button>
            </div>
            <div className='col-sm-6'>
              <Button
                id='recaptcha-container'
                name='recaptcha-container'
                disabled={showOTPModal}
                color='primary'
                className='account__btn'
                onClick={captcha}
              >
                Continue &nbsp;
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>
      <Modal
        show={showOTPModal}
        onClose={() => dispatch(changeOTPModal(false))}
      >
        <div className='content'>
          <div style={{ padding: '2rem' }}>
            <h2>Verify your phone number</h2>
            <p>
              Enter the 6 digit code we sent to <b>{values.phone}</b>
            </p>
            <div className='col-sm-12'>
              <div className='input__box'>
                <Field
                  name='otp'
                  type='text'
                  component={FormInput}
                  label='6-digit code'
                />
              </div>
            </div>
            <div className='col-sm-12'>
              {checkNumber ? (
                <p className='resendText'>{countdownSeconds}s</p>
              ) : (
                <Button
                  color='primary'
                  onClick={resendOTP}
                  className='linkButton account__btn'
                >
                  Resend
                </Button>
              )}
            </div>
            <div className='col-sm-12'>
              <Button color='primary' className='account__btn' type='submit'>
                Finish &nbsp;
                <FaCheck />
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </form>
  );
};

export default compose(
  reduxForm({
    form: 'wizardForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  }),
  withFirebase
)(CompanyInfoForm);
