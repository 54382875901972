import React, { useRef, Fragment } from 'react';
import algoliasearch from 'algoliasearch/lite';
import _ from 'lodash';
import { AiTwotoneFlag } from 'react-icons/ai';
import { BsFillGearFill } from 'react-icons/bs';
import MetaTags from 'react-meta-tags';
import { GiCargoCrane } from 'react-icons/gi';
import { BsFillFunnelFill, BsCalendarFill } from 'react-icons/bs';
import { MdRefresh } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  InstantSearch,
  Highlight,
  Stats,
  connectSearchBox,
  connectHits,
  RefinementList,
  Pagination,
  Configure,
  connectCurrentRefinements,
  connectStateResults,
  connectRefinementList,
} from 'react-instantsearch-dom';
import { changeSearchFilters } from '../redux/actions';
import Breadcrumb from '../components/breadcrumbs/Breadcrumb';

const searchClient = algoliasearch(
  'SAYCIOCNVM',
  process.env.REACT_APP_ALGOLIA_PUBLIC
);

const Search = (props) => {
  const dispatch = useDispatch();
  const textInput = useRef(null);
  const searchQuery = useSelector((store) => store.vessel.searchQuery);
  const searchFilters = useSelector((store) => store.vessel.searchFilters);

  const ClearRefinements = ({ items, refine }) => (
    <Link
      className='new-search'
      style={{ fontSize: 18, fontWeight: 700 }}
      onClick={() => clearer(items, refine)}
      to='#'
    >
      <MdRefresh /> New Search
    </Link>
  );

  const clearer = (items, refine) => {
    refine(items);
    dispatch(changeSearchFilters());
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    let stringer = '';

    if (searchFilters && !searchFilters.configureFilter) {
      stringer = `year_built = ${value}`;
    }

    const filters = {
      selectValue: value,
      configureFilter: stringer,
    };
    dispatch(changeSearchFilters(filters));
  };

  const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

  const SearcherBox = ({ isSearchStalled, refine }) => (
    <div className='ais-SearchBox'>
      <form noValidate className='ais-SearchBox-form' action='' role='search'>
        {/* <input
        type='search'
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
      /> */}
        {isSearchStalled ? 'Loading...' : ''}
        <input
          type='search'
          placeholder='search vessels'
          required
          className='ais-SearchBox-input'
          ref={textInput}
          // value={currentRefinement}
          onChange={(event) => event.currentTarget.value}
        />
        <button
          type='submit'
          className='ais-SearchBox-submit'
          onClick={(e) => {
            e.preventDefault();
            refine(textInput.current.value);
          }}
        >
          <svg
            className='ais-SearchBox-submitIcon'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
          >
            <path d='M26.804 29.01'></path>
          </svg>
        </button>
      </form>
    </div>
  );

  const CustomSearchBox = connectSearchBox(SearcherBox);

  const StateResults = ({ searchState, searchResults }) => {
    if (searchResults && searchResults.nbHits === 0) {
      return (
        <div className='search-not-found'>
          <div className='row'>
            <div className='col-sm-1'>
              <i className='search-not-found-icon'>i</i>
            </div>
            <div className='col-sm-11'>
              <div className='search-not-found-content'>
                <p className='search-not-found-title'>
                  Sorry, no results found that match your search criteria:{' '}
                  {searchState.query || null}
                  {searchFilters && <span>{searchFilters.vesselType}</span>}
                  {searchFilters && searchFilters.grossMin > 0 && (
                    <span>
                      , Minimum Gross Tonnage: {searchFilters.grossMin}
                    </span>
                  )}
                  {searchFilters && searchFilters.grossMax > 0 && (
                    <span>
                      , Maximum Gross Tonnage: {searchFilters.grossMax}
                    </span>
                  )}
                </p>
                <p className='search-not-found-subtitle'>Suggestions:</p>
                <ul className='search-not-found-list'>
                  <li className='search-not-found-list-item'>
                    Try the search bar above
                  </li>
                  <li className='search-not-found-list-item'>
                    Try to use filters on the left side of the page
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const CustomStateResults = connectStateResults(StateResults);

  const RefineList = ({ items }) => (
    <select
      style={{ marginBottom: 10 }}
      defaultValue={searchFilters.selectValue || 'All'}
      onChange={handleSelectChange}
    >
      <option value='All'>All</option>
      {items.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );

  const CustomRefinementList = connectRefinementList(RefineList);

  const Hits = ({ hits }) => (
    <div className='row'>
      {hits.map((hit) => (
        <div className='col-sm-4' key={hit.objectID}>
          <div className='vessel_item' style={{ marginBottom: '40px' }}>
            <div className='image'>
              <Link to={{ pathname: `/vessel/${hit.vesselId}` }}>
                <img
                  src={hit.images[0]}
                  alt='vessel'
                  style={{ width: '100%', height: '224px' }}
                  className='img-responsive'
                />
              </Link>
              <span className='tag_t'>{hit.status}</span>
            </div>
            <div className='vessel_content'>
              <div className='vessel_text'>
                <h3>
                  <Link to={{ pathname: `/vessel/${hit.vesselId}` }}>
                    <Highlight attribute='vessel_name' hit={hit} />
                  </Link>
                </h3>
              </div>
              <div className='vessel_meta transparent'>
                <span>
                  <BsFillGearFill /> {hit.builders}
                </span>
                <span>
                  <GiCargoCrane /> {hit.port}
                </span>
                <span>
                  <AiTwotoneFlag /> {hit.flag}
                </span>
              </div>
              <div className='vessel_meta' style={{ marginBottom: '30px' }}>
                <span>
                  <BsFillFunnelFill /> {hit.vesselType}
                </span>
              </div>
              <div className='favroute clearfix'>
                <p>
                  <BsCalendarFill />{' '}
                  {moment.unix(hit.createdAt._seconds).fromNow()}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const CustomHits = connectHits(Hits);

  const Sidebar = () => {
    const Results = connectStateResults(
      ({ searchResults }) =>
        searchResults &&
        searchResults.nbHits !== 0 && (
          <Fragment>
            <h3 style={{ marginTop: 20 }}>Vessel Type</h3>
            <RefinementList attribute='vesselType' />
            <h3>Status</h3>
            <RefinementList attribute='status' />
            <h3>Year Built</h3>
            <RefinementList attribute='year_built' />
            <h3>Flag</h3>
            <RefinementList attribute='flag' />
          </Fragment>
        )
    );
    return (
      <div className='sidebar'>
        <CustomClearRefinements />
        <Results />
      </div>
    );
  };

  const Content = () => {
    return (
      <div className='container'>
        <div className='row'>
          <CustomHits />
        </div>
        <div className='row'>
          <CustomStateResults />
          <Stats />
        </div>
        <div className='row'>
          <Pagination showFirst={false} showLast={false} />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Vessel Search</title>
        <meta name='description' content='search for vessels' />
      </MetaTags>
      <Breadcrumb title='search' />
      <div className='dg__blog__area bg--white'>
        <InstantSearch searchClient={searchClient} indexName='vessels_search'>
          {!_.isEmpty(searchFilters) && (
            <Configure filters={searchFilters.configureFilter} />
          )}
          <div className='container'>
            <div className='row'>
              <div className='col-sm-5 offset-sm-4'>
                <CustomSearchBox defaultRefinement={searchQuery} />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 col-xs-12 mt--20'>
                <Sidebar />
              </div>
              <div className='col-sm-9'>
                <Content />
              </div>
            </div>
          </div>
        </InstantSearch>
      </div>
    </Fragment>
  );
};

export default Search;
