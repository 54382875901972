import React, { Component, Fragment } from 'react';
import Slidewithfade from '../../components/Ui/slider';
import blogGridData from '../../data/hero-sliders/blog.json';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { withFirebase } from '../../components/Firebase';

class mediumAd extends Component {
  constructor() {
    super();
    this.state = {
      adList: [],
    };
  }
  componentDidMount() {
    try {
      this.props.firebase
        .fetchAdverts({ size: 'medium', limit: 5 })
        .then((snapshots) => {
          if (!snapshots.data.length) return;
          this.setState({ adList: snapshots.data });
        });
    } catch (e) {}
  }
  render() {
    return (
      <div className='row mt--70'>
        <div className='col-md-12'>
          {this.props.selectedData.images && (
            <Slidewithfade images={this.props.selectedData.images} />
          )}
          {this.state.adList.length > 0 &&
            this.state.adList.map((data, idx) => {
              return (
                <Fragment>
                  <Slidewithfade key={idx} images={data.images} />{' '}
                  <Link
                    style={{ width: '100%' }}
                    to={`/vessel/${data.vesselId}`}
                    className='yellowBtn mt--20'
                  >
                    View
                  </Link>
                </Fragment>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedData: state.vessel.selectedData,
  firebase: state.firebase,
});

const enhannce = compose(connect(mapStateToProps), withFirebase);

export default enhannce(mediumAd);
