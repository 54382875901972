import React, { useRef } from 'react';

const FileUploader = ({
  onFileSelect,
  onFileSelectError,
  onFileSelectSuccess,
}) => {
  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 1024 * 1024)
        onFileSelectError({ error: 'File size cannot exceed more than 1MB' });
      if (!file.type.startsWith('image'))
        onFileSelectError({ error: 'File must be an image' });
      onFileSelectSuccess(file);
    } else {
      onFileSelectError({ error: 'Please select an image' });
    }
  };

  return (
    <div>
      <input
        type='file'
        ref={fileInput}
        style={{ display: 'none' }}
        onChange={handleFileInput}
      />
      <button onClick={() => fileInput.current && fileInput.current.click()}>
        Select Profile Picture
      </button>
    </div>
  );
};

export default FileUploader;
