import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
// import blogGridData from "../../data/hero-sliders/blog.json";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { withFirebase } from "../../components/Firebase";

const AdItem = ({ ad, firebase }) => {
  // Advert observer
  const { ref, inView } = useInView({
    delay: 500,
    trackVisibility: true,
  });

  const [viewed, setViewed] = useState(false);
  const [isLoading, setLoading] = useState(false);

  let checkLength = false;
  const formatText = (text) => {
    if (text.length > 22) {
      checkLength = true;
      return text.slice(0, 20);
    } else {
      return text;
    }
  };

  async function reportAdView() {
    setViewed(true);
    try {
      const res = await firebase.increaseAdvertViewCount({ id: ad.id });
      setLoading(false);
      if(!res || !res.data) setViewed(false);
    } catch (e) {
      setViewed(false)
    }
  }

  if (!isLoading && inView && !viewed) {
    setLoading(true)
    reportAdView();
  }

  return (
    <div ref={ref} className="row bottom20">
      <div className="col-md-5 col-sm-4 col-xs-6 p-image">
        <img src={ad.images[0]} alt="smallPic" />
      </div>
      <div className="col-md-7 col-sm-8 col-xs-6">
        <div className="feature-p-text">
          <h6
            style={{
              marginBottom: "0px",
            }}
          >
            {formatText(ad.vessel_name)}
            {checkLength && "..."}
          </h6>
          <p style={{ marginBottom: "0px" }}>{ad.vesselType}</p>
          <Link to={`/userpage/${ad.user_id}`}>Contact</Link>
        </div>
      </div>
    </div>
  );
};

const SmallAds = (props) => {
  // state
  const [adList, setAdList] = useState([]);

  useEffect(() => {
    try {
      props.firebase
        .fetchAdverts({ size: "small", limit: 5 })
        .then((snapshots) => {

          if (!snapshots.data.length) return;

          setAdList(snapshots.data);
        })
        .catch(e => {
          console.log('error fetching ads: ', e);
        })
    } catch (e) {
      console.log('error get adverts: ', e);
    }
    return () => {
      // update Ad view counts
    };
  }, []);

  return (
    <React.Fragment>
      <div
        className="row dg__blog__area bg--white"
        style={{ paddingTop: "50px" }}
      >
        {adList.length > 0 && (
          <div className="col-lg-12">
            <div className="section__title--2 mt--30">
              <h3>Sponsored Posts</h3>
            </div>
          </div>
        )}
      </div>
      {adList.length > 0 &&
        adList.map((data) => {
          return <AdItem key={data.id} ad={data} firebase={props.firebase} />;
        })}
    </React.Fragment>
  );
};

export default compose(withFirebase)(SmallAds);
