import React from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from '../../validation';
import FormInput from '../../components/FormInput';
import { Button } from 'reactstrap';

const MoreDetailsForm = (props) => {
  const { handleSubmit, previousPage } = props;
  return (
    <form onSubmit={handleSubmit} style={{ marginTop: '15px' }}>
      <div className='row'>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field
              name='firstname'
              type='text'
              component={FormInput}
              label='First Name'
            />
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field
              name='lastname'
              type='text'
              component={FormInput}
              label='Last Name'
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field
              name='user_address'
              type='text'
              component={FormInput}
              label='Address'
            />
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field name='user_city' type='text' component={FormInput} label='City' />
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field
              name='user_state'
              type='text'
              component={FormInput}
              label='Region/State/Province'
            />
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='input__box'>
            <Field
              name='user_country'
              type='text'
              component={FormInput}
              label='Country'
            />
          </div>
        </div>
        <div className='col-sm-6'>
          <Button
            color='primary'
            className='account__btn'
            onClick={previousPage}
          >
            &nbsp; Previous
          </Button>
        </div>
        <div className='col-sm-6'>
          <Button color='primary' className='account__btn' type='submit'>
            Next &nbsp;
          </Button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'wizardForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(MoreDetailsForm);
