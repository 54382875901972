import React from 'react';
import { FormGroup, FormText, Input } from 'reactstrap';

const FormInput = ({
  input,
  label,
  type,
  inputPlaceHolder,
  maxDate,
  minDate,
  meta: { error, touched },
}) => (
  <FormGroup>
    <span>{label}</span>
    <Input
      {...input}
      type={type}
      placeholder={inputPlaceHolder}
      max={maxDate}
      min={minDate}
    />
    {touched && <FormText className='help-block error-color'>{error}</FormText>}
  </FormGroup>
);

export default FormInput;
