import React from 'react';
import MyVessels from '../../components/Dashboard/MyVessels';
import MetaTags from 'react-meta-tags';
import { withAuthorization } from '../../components/Session';


class VesselsContainer extends React.Component {
  constructor() {
    super();
    this.state = { details: JSON.parse(localStorage.getItem('marexUser')) };
  }

  componentDidMount() {
    if (!this.state.details) {
      // console.log('You need to fetch again');
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Manage Fleet | Marex</title>
          <meta
            name='description'
            content="Manage the vessels you have registered"
          />
        </MetaTags>
        <MyVessels details={this.state.details} />
      </React.Fragment>
    );
  }
}
const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(VesselsContainer);
