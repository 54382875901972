import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import AccountRegistrationForm from '../forms/AccountRegistration';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';
import { withFirebase } from '../components/Firebase';
import Modal from '../components/Modal/modal';
import { useSelector } from 'react-redux';
import toast from 'toasted-notes';
import _ from 'lodash';
import 'toasted-notes/src/styles.css';
import Breadcrumb from '../components/breadcrumbs/Breadcrumb';
import { changeRegLoading, changeOTPModal } from '../redux/actions';
import { useDispatch } from 'react-redux';

const MySwal = withReactContent(Swal);

function Register(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const cac7 = useSelector((store) => store.auth.cac7Blob);
  const incorpCert = useSelector((store) => store.auth.incorpCertBlob);
  const captchaId = useSelector((store) => store.auth.captchaId);

  async function getFileBlob(url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.addEventListener('load', function () {
      cb(xhr.response);
    });
    xhr.send();
  }

  const uploadIncorpCertImg = (imageURL, userId) => {
    return new Promise(async (resolve, reject) => {
      if (!imageURL) reject('No file selected');

      await getFileBlob(imageURL, (blob) => {
        let path = '';
        if (blob.type.endsWith('pdf')) {
          path = `users/${userId}/docs/incorpCert.pdf`;
        } else if (blob.type.endsWith('jpeg') || blob.type.endsWith('png')) {
          path = `users/${userId}/docs/incorpCert.jpeg`;
        }

        const upload = props.firebase.storage.ref(path).put(blob);
        upload.on(
          'state_changed',
          () => [],
          (err) => {
            reject(err);
          },
          async () => {
            await upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    });
  };

  const uploadCAC7Img = (imageURL, userId) => {
    return new Promise(async (resolve, reject) => {
      if (!imageURL) reject('No file selected');

      await getFileBlob(imageURL, (blob) => {
        let path = '';
        if (blob.type.endsWith('pdf')) {
          path = `users/${userId}/docs/cac7.pdf`;
        } else if (blob.type.endsWith('jpeg') || blob.type.endsWith('png')) {
          path = `users/${userId}/docs/cac7.jpeg`;
        }

        const upload = props.firebase.storage.ref(path).put(blob);
        upload.on(
          'state_changed',
          () => [],
          (err) => {
            reject(err);
          },
          async () => {
            await upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    });
  };

  const deleteFolderContents = async (path) => {
    const ref = props.firebase.storage.ref(path);
    ref
      .listAll()
      .then((dir) => {
        dir.items.forEach((fileRef) => {
          deleteFile(ref.fullPath, fileRef.name);
        });
        dir.prefixes.forEach((folderRef) => {
          deleteFolderContents(folderRef.fullPath);
        });
      })
      .catch((error) => {
        toast.notify(`Delete Error: ${error.message}`, {
          duration: null,
        });
      });
  };

  const deleteFile = (pathToFile, fileName) => {
    const ref = props.firebase.storage.ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete();
  };

  async function submit(values) {
    dispatch(changeOTPModal(false));
    dispatch(changeRegLoading(true));
    const credential = props.firebase.phone.credential(captchaId, values.otp);

    await props.firebase.auth
      .signInWithCredential(credential)
      .then(() => {
        const currentUser = props.firebase.auth.currentUser;
        values.UID = currentUser.uid;
        const emailCredential = props.firebase.emailProvider.credential(
          values.email,
          values.password
        );

        currentUser
          .linkWithCredential(emailCredential)
          .then(() => {
            props.firebase
              .registerAccount(values)
              .then(() => {
                dispatch(changeRegLoading(false));
                MySwal.fire({
                  title: 'Welcome to Oceanic Links!',
                  text: 'Your account has been created',
                  confirmButtonText: 'Dashboard',
                  icon: 'success',
                }).then(async () => {
                  localStorage.setItem('marexUser', JSON.stringify(values));
                  history.push('/dashboard');
                });
              })
              .catch((error) => {
                currentUser.delete().then(() => {
                  dispatch(changeRegLoading(false));
                  MySwal.fire({
                    icon: 'error',
                    text: `An error occurred while saving your details ${error.message}`,
                  });
                });
              });
          })
          .catch((error) => {
            dispatch(changeRegLoading(false));
            currentUser.delete().then(() => {
              dispatch(changeRegLoading(false));
              MySwal.fire({
                icon: 'error',
                text: `An error occurred. ${error.message}`,
              });
            });
          });
      })
      .catch(() => {
        dispatch(changeRegLoading(false));
        MySwal.fire({
          icon: 'error',
          text: `Invalid OTP entered! Please re-enter code`,
        });
      });

    // props.firebase
    //   .createUser(values.email, values.password)
    //   .then(() => {
    //     const currentUser = props.firebase.auth.currentUser;

    //     return Promise.all([
    //       uploadIncorpCertImg(incorpCert, currentUser.uid),
    //       uploadCAC7Img(cac7, currentUser.uid),
    //     ])
    //       .then((val) => {
    //         if (_.isEmpty(val[0]) && _.isEmpty(val[1]))
    //           return dispatch(changeRegLoading(false));

    //         values.incorpCert = val[0];
    //         values.cac7 = val[1];
    //         values.UID = currentUser.uid;

    //         props.firebase
    //           .registerAccount(values)
    //           .then(() => {
    //             dispatch(changeRegLoading(false));
    //             MySwal.fire({
    //               title: 'Welcome to Marex!',
    //               text: 'Your account has been created',
    //               confirmButtonText: 'Dashboard',
    //               icon: 'success',
    //             }).then(async () => {
    //               await localStorage.setItem(
    //                 'marexUser',
    //                 JSON.stringify(values)
    //               );
    //               history.push('/dashboard');
    //             });
    //           })
    //           .catch((error) => {
    //             currentUser.delete().then(() => {
    //               dispatch(changeRegLoading(false));
    //               MySwal.fire({
    //                 icon: 'error',
    //                 text: `An error occurred while saving your details ${error.message}`,
    //               });
    //             });
    //           });
    //       })
    //       .catch(async (error) => {
    //         const docsPath = `users/${currentUser.uid}/docs/`;
    //         await deleteFolderContents(docsPath);
    //         currentUser.delete().then(() => {
    //           dispatch(changeRegLoading(false));
    //           MySwal.fire({
    //             icon: 'error',
    //             text: `An error occurred while uploading your documents, select a valid document and try again!!!`,
    //           });
    //         });
    //       });
    //   })
    //   .catch((error) => {
    //     dispatch(changeRegLoading(false));
    //     MySwal.fire({
    //       icon: 'error',
    //       text: `An error occurred while creating your account ${error.message}`,
    //     });
    //   });
  }

  return (
    <Fragment>
      <MetaTags>
        <title>Register Account</title>
        <meta name='description' content='Create an Oceanic Links account' />
      </MetaTags>
      <Breadcrumb title='Create Account'/>
      <AccountRegistrationForm onSubmit={submit} />
    </Fragment>
  );
}

export default withFirebase(Register);
