import {
  GET_VESSEL_REG_IMAGES,
  GET_LETTER_OF_AUTH,
  RELOAD_MY_VESSELS,
  GET_MY_VESSELS_SEARCH_STATUS,
  GET_MY_VESSELS_SEARCH_ERROR,
  MY_VESSELS_DATA,
  CHANGE_SELECTED_DATA,
  RELOAD_VESSELS,
  GET_VESSELS_SEARCH_STATUS,
  GET_VESSELS_SEARCH_ERROR,
  VESSELS_DATA,
  CHANGE_SELECTED_VESSEL,
  SET_VESSEL_DOC_URL,
  CHANGE_FORMAT_TEXT_LENGTH,
  GET_LAST_RECORD,
  CHANGE_BASIC_SEARCH_VESSEL,
  CHANGE_SEARCH_QUERY,
  CHANGE_SEARCH_FILTERS,
} from '../actions/types';

const initialState = {
  vesselImages: [],
  authLetter: '',
  myVesselsData: [],
  selectedData: {},
  myVesselsSearchStatus: false,
  myVesselsSearchError: '',
  myVesselsStatus: false,
  vesselsData: [],
  vesselsStatus: false,
  vesselsSearchStatus: false,
  vesselsSearchError: '',
  selectedVessel: {},
  vesselURL: '',
  formatTextLength: 22,
  lastRecord: '',
  searchQuery: '',
  searchVessel: '',
  searchFilters: {},
};

const VesselReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VESSEL_REG_IMAGES:
      return {
        ...state,
        vesselImages: action.payload,
      };
    case CHANGE_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
    case CHANGE_BASIC_SEARCH_VESSEL:
      return {
        ...state,
        searchVessel: action.payload,
      };
    case GET_LAST_RECORD:
      return {
        ...state,
        lastRecord: action.payload,
      };
    case GET_LETTER_OF_AUTH:
      return {
        ...state,
        authLetter: action.payload,
      };
    case GET_MY_VESSELS_SEARCH_STATUS:
      return {
        ...state,
        myVesselsSearchStatus: action.payload,
      };
    case GET_MY_VESSELS_SEARCH_ERROR:
      return {
        ...state,
        myVesselsSearchError: action.payload,
      };
    case MY_VESSELS_DATA:
      return Object.assign({}, state, {
        ...state,
        myVesselsData: action.payload.slice(),
      });
    case RELOAD_MY_VESSELS:
      return {
        ...state,
        myVesselsStatus: action.payload,
      };
    case GET_VESSELS_SEARCH_STATUS:
      return {
        ...state,
        vesselsSearchStatus: action.payload,
      };
    case GET_VESSELS_SEARCH_ERROR:
      return {
        ...state,
        vesselsSearchError: action.payload,
      };
    case VESSELS_DATA:
      return Object.assign({}, state, {
        ...state,
        vesselsData: action.payload.slice(),
      });
    case RELOAD_VESSELS:
      return {
        ...state,
        vesselsStatus: action.payload,
      };
    case CHANGE_SELECTED_DATA:
      return {
        ...state,
        selectedData: action.payload,
      };
    case CHANGE_SELECTED_VESSEL:
      return {
        ...state,
        selectedVessel: action.payload,
      };
    case CHANGE_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: action.payload,
      };
    case CHANGE_FORMAT_TEXT_LENGTH:
      return {
        ...state,
        formatTextLength: action.payload,
      };
    case SET_VESSEL_DOC_URL:
      return {
        ...state,
        vesselURL: action.payload,
      };
    default:
      return state;
  }
};

export default VesselReducer;
