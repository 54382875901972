import { combineReducers } from 'redux';
import { reducer as reducerForm } from 'redux-form';
import AuthReducer from './AuthReducer';
import UserReducer from './UserReducer';
import VesselReducer from './VesselReducer';
import AdvertReducer from "./AdvertReducer";

const rootReducer = combineReducers({
  form: reducerForm,
  auth: AuthReducer,
  vessel: VesselReducer,
  user: UserReducer,
  advert: AdvertReducer
});

export default rootReducer;
