export const mainCollection = {
  USERS: 'Users',
  VESSELS: 'Vessels',
  ADVERTS: 'Adverts',
  ADVERTS_PACKAGES: 'AdvertPackages',
};

export const FETCH_VESSELS_LIMIT = 21;

export const VESSELS = [
  { name: 'Accommodation Jack Up', value: 'Accommodation Jack Up' },
  { name: 'Accommodation Platform', value: 'Accommodation Platform' },
  { name: 'Accommodation Ship', value: 'Accommodation Ship' },
  { name: 'Accommodation Vessel', value: 'Accommodation Vessel' },
  { name: 'Aggregates Barge', value: 'Aggregates Barge' },

  { name: 'Aggregates Carrier', value: 'Aggregates Carrier' },

  {
    name: 'Air Cushion Passenger Ship',
    value: 'Air Cushion Passenger Ship',
  },

  { name: 'Air Cushion Patrol Vessel', value: 'Air Cushion Patrol Vessel' },

  {
    name: 'Air Cushion Ro Ro/Passenger Ship',
    value: 'Air Cushion Ro Ro/Passenger Ship',
  },

  { name: 'Air Cushion Work Vessel', value: 'Air Cushion Work Vessel' },

  { name: 'Anchor Handling Vessel', value: 'Anchor Handling Vessel' },

  { name: 'Articulated Pusher Tug', value: 'Articulated Pusher Tug' },

  { name: 'Asphalt/Bitumen Tanker', value: 'Asphalt/Bitumen Tanker' },

  { name: 'Backhoe Dredger', value: 'Backhoe Dredger' },

  { name: 'Barge', value: 'Barge' },
  { name: 'Barge Carrier', value: 'Barge Carrier' },
  { name: 'Bucket Dredger', value: 'Bucket Dredger' },
  { name: 'Bucket Dredger Pontoon', value: 'Bucket Dredger Pontoon' },

  { name: 'Bucket Hopper Dredger', value: 'Bucket Hopper Dredger' },

  { name: 'Bucket Ladder Dredger', value: 'Bucket Ladder Dredger' },

  {
    name: 'Bucket Wheel Suction Dredger',
    value: 'Bucket Wheel Suction Dredger',
  },

  { name: 'Bulk Aggregates Barge', value: 'Bulk Aggregates Barge' },

  { name: 'Bulk Carrier', value: 'Bulk Carrier' },
  {
    name: 'Bulk Carrier With Vehicle Decks',
    value: 'Bulk Carrier With Vehicle Decks',
  },

  { name: 'Bulk Storage Barge', value: 'Bulk Storage Barge' },

  { name: 'Bulker', value: 'Bulker' },
  { name: 'Bunkering Tanker', value: 'Bunkering Tanker' },

  { name: 'Buoy Laying Vessel', value: 'Buoy Laying Vessel' },

  { name: 'Cable Layer', value: 'Cable Layer' },
  { name: 'Cabu Carrier', value: 'Cabu Carrier' },
  { name: 'Car Park Pontoon', value: 'Car Park Pontoon' },

  { name: 'Cargo', value: 'Cargo' },
  { name: 'Cargo Barge', value: 'Cargo Barge' },
  { name: 'Cargo/Containership', value: 'Cargo/Containership' },

  { name: 'Cargo/Passenger Ship', value: 'Cargo/Passenger Ship' },

  { name: 'Cement Barge', value: 'Cement Barge' },
  { name: 'Cement Carrier', value: 'Cement Carrier' },
  { name: 'Chemical Tanker', value: 'Chemical Tanker' },

  { name: 'Co2 Tanker', value: 'Co2 Tanker' },
  { name: 'Combat Vessel', value: 'Combat Vessel' },
  { name: 'Command Vessel', value: 'Command Vessel' },
  {
    name: 'Construction Support Vessel',
    value: 'Construction Support Vessel',
  },

  { name: 'Container Ship', value: 'Container Ship' },
  { name: 'Crane Barge', value: 'Crane Barge' },
  { name: 'Crane Jack Up', value: 'Crane Jack Up' },
  { name: 'Crane Ship', value: 'Crane Ship' },
  { name: 'Crew Boat', value: 'Crew Boat' },
  { name: 'Crude Oil Tanker', value: 'Crude Oil Tanker' },

  { name: 'Cutter Suction Dredger', value: 'Cutter Suction Dredger' },

  { name: 'Deck Cargo Pontoon', value: 'Deck Cargo Pontoon' },

  { name: 'Deck Cargo Ship', value: 'Deck Cargo Ship' },

  { name: 'Desalination Pontoon', value: 'Desalination Pontoon' },

  { name: 'Diving Support Platform', value: 'Diving Support Platform' },

  { name: 'Diving Support Vessel', value: 'Diving Support Vessel' },

  { name: 'Dredger', value: 'Dredger' },
  { name: 'Dredging Pontoon', value: 'Dredging Pontoon' },
  {
    name: 'Drill Ship',
    value: 'Drill Ship',
  },
  { name: 'Drilling Jack Up', value: 'Drilling Jack Up' },

  { name: 'Drilling Rig', value: 'Drilling Rig' },
  { name: 'Edible Oil Tanker', value: 'Edible Oil Tanker' },

  { name: 'Exhibition Ship', value: 'Exhibition Ship' },

  { name: 'Factory Trawler', value: 'Factory Trawler' },

  { name: 'Ferry', value: 'Ferry' },
  { name: 'Fire Fighting Tractor Tug', value: 'Fire Fighting Tractor Tug' },

  { name: 'Fire Fighting Vessel', value: 'Fire Fighting Vessel' },

  {
    name: 'Fire Fighting/Supply Vessel',
    value: 'Fire Fighting/Supply Vessel',
  },

  { name: 'Fish Carrier', value: 'Fish Carrier' },
  { name: 'Fish Factory', value: 'Fish Factory' },
  { name: 'Fish Storage Barge', value: 'Fish Storage Barge' },

  { name: 'Fishery Patrol Vessel', value: 'Fishery Patrol Vessel' },

  {
    name: 'Fishery Protection/Research',
    value: 'Fishery Protection/Research',
  },

  { name: 'Fishery Research Vessel', value: 'Fishery Research Vessel' },

  { name: 'Fishery Support Vessel', value: 'Fishery Support Vessel' },

  { name: 'Fishing Vessel', value: 'Fishing Vessel' },
  { name: 'Floating Crane', value: 'Floating Crane' },
  { name: 'Floating Dock', value: 'Floating Dock' },
  { name: 'Floating Hotel/Restaurant', value: 'Floating Hotel/Restaurant' },

  { name: 'Floating Linkspan', value: 'Floating Linkspan' },

  { name: 'Floating Sheerleg', value: 'Floating Sheerleg' },

  {
    name: 'Floating Storage/Production',
    value: 'Floating Storage/Production',
  },

  { name: 'Fruit Juice Tanker', value: 'Fruit Juice Tanker' },

  { name: 'General Cargo', value: 'General Cargo' },
  { name: 'Grab Dredger', value: 'Grab Dredger' },
  { name: 'Grab Dredger Pontoon', value: 'Grab Dredger Pontoon' },

  { name: 'Grab Hopper Dredger', value: 'Grab Hopper Dredger' },

  { name: 'Grain Elevating Pontoon', value: 'Grain Elevating Pontoon' },

  { name: 'Heavy Lift Vessel', value: 'Heavy Lift Vessel' },

  { name: 'Heavy Load Carrier', value: 'Heavy Load Carrier' },

  { name: 'Hopper Barge', value: 'Hopper Barge' },
  { name: 'Hopper Dredger', value: 'Hopper Dredger' },
  { name: 'Hospital Ship', value: 'Hospital Ship' },
  { name: 'Houseboat', value: 'Houseboat' },
  { name: 'Hydrofoil', value: 'Hydrofoil' },
  { name: 'Icebreaker', value: 'Icebreaker' },
  { name: 'Incinerator', value: 'Incinerator' },
  { name: 'Inland Cargo', value: 'Inland Cargo' },
  { name: 'Inland Dredger', value: 'Inland Dredger' },
  { name: 'Inland Passengers Ship', value: 'Inland Passengers Ship' },

  { name: 'Inland Ro Ro Cargo Ship', value: 'Inland Ro Ro Cargo Ship' },

  { name: 'Inland Supply Vessel', value: 'Inland Supply Vessel' },

  { name: 'Inland Tanker', value: 'Inland Tanker' },
  { name: 'Jacket Launching Pontoon', value: 'Jacket Launching Pontoon' },
  { name: 'Landing Craft', value: 'Landing Craft' },
  { name: 'Limestone Carrier', value: 'Limestone Carrier' },
  { name: 'Livestock Carrier', value: 'Livestock Carrier' },
  { name: 'Lng Tanker', value: 'Lng Tanker' },
  { name: 'Logistics Naval Vessel', value: 'Logistics Naval Vessel' },
  { name: 'Lpg Tanker', value: 'Lpg Tanker' },
  { name: 'Lpg/Chemical Tanker', value: 'Lpg/Chemical Tanker' },
  { name: 'Maintenance Platform', value: 'Maintenance Platform' },
  { name: 'Maintenance Vessel', value: 'Maintenance Vessel' },
  { name: 'Mine Hunter', value: 'Mine Hunter' },
  { name: 'Minesweeper', value: 'Minesweeper' },
  { name: 'Mining Vessel', value: 'Mining Vessel' },
  { name: 'Mission Ship', value: 'Mission Ship' },
  { name: 'Mooring Buoy', value: 'Mooring Buoy' },
  { name: 'Mooring Vessel', value: 'Mooring Vessel' },
  { name: 'Motor Hopper', value: 'Motor Hopper' },
  {
    name: 'Multi Purpose Offshore Vessel',
    value: 'Multi Purpose Offshore Vessel',
  },
  { name: 'Museum Ship', value: 'Museum Ship' },
  { name: 'Naval Auxiliary Tug', value: 'Naval Auxiliary Tug' },
  { name: 'Naval Patrol Vessel', value: 'Naval Patrol Vessel' },
  { name: 'Naval Research Vessel', value: 'Naval Research Vessel' },
  { name: 'Naval Salvage Vessel', value: 'Naval Salvage Vessel' },
  {
    name: 'Naval/Naval Auxiliary Vessel',
    value: 'Naval/Naval Auxiliary Vessel',
  },
  { name: 'Nuclear Fuel Carrier', value: 'Nuclear Fuel Carrier' },
  { name: 'Obo Carrier', value: 'Obo Carrier' },
  {
    name: 'Offshore Construction Jack Up',
    value: 'Offshore Construction Jack Up',
  },
  { name: 'Offshore Safety Vessel', value: 'Offshore Safety Vessel' },
  { name: 'Offshore Supply Ship', value: 'Offshore Supply Ship' },
  { name: 'Oil Products Tanker', value: 'Oil Products Tanker' },
  { name: 'Oil/Chemical Tanker', value: 'Oil/Chemical Tanker' },
  { name: 'Ore Carrier', value: 'Ore Carrier' },
  { name: 'Ore/Oil Carrier', value: 'Ore/Oil Carrier' },
  { name: 'Pallet Carrier', value: 'Pallet Carrier' },
  { name: 'Passenger/Cargo Ship', value: 'Passenger/Cargo Ship' },
  { name: 'Passengers Landing Craft', value: 'Passengers Landing Craft' },
  { name: 'Passengers Ship', value: 'Passengers Ship' },
  { name: 'Patrol Vessel', value: 'Patrol Vessel' },
  { name: 'Pearl Shells Carrier', value: 'Pearl Shells Carrier' },
  { name: 'Pile Driving Vessel', value: 'Pile Driving Vessel' },
  { name: 'Pilot Ship', value: 'Pilot Ship' },
  { name: 'Pipe Burying Vessel', value: 'Pipe Burying Vessel' },
  { name: 'Pipe Carrier', value: 'Pipe Carrier' },
  { name: 'Pipe Layer', value: 'Pipe Layer' },
  { name: 'Pipe Layer Platform', value: 'Pipe Layer Platform' },
  { name: 'Pipelay Crane Vessel', value: 'Pipelay Crane Vessel' },
  { name: 'Platform', value: 'Platform' },
  { name: 'Pollution Control Vessel', value: 'Pollution Control Vessel' },
  { name: 'Pontoon', value: 'Pontoon' },
  { name: 'Powder Carrier', value: 'Powder Carrier' },
  { name: 'Power Station Pontoon', value: 'Power Station Pontoon' },
  { name: 'Power Station Vessel', value: 'Power Station Vessel' },
  { name: 'Production Testing Vessel', value: 'Production Testing Vessel' },
  { name: 'Pumping Platform', value: 'Pumping Platform' },
  { name: 'Pusher Tug', value: 'Pusher Tug' },
  { name: 'Radar Platform', value: 'Radar Platform' },
  { name: 'Radio Ship', value: 'Radio Ship' },
  { name: 'Rail/Vehicles Carrier', value: 'Rail/Vehicles Carrier' },
  { name: 'Reefer', value: 'Reefer' },
  { name: 'Reefer/Containership', value: 'Reefer/Containership' },
  { name: 'Repair Ship', value: 'Repair Ship' },
  { name: 'Replenishment Vessel', value: 'Replenishment Vessel' },
  { name: 'Research/Survey Vessel', value: 'Research/Survey Vessel' },
  { name: 'Ro Ro Cargo', value: 'Ro Ro Cargo' },
  { name: 'Ro Ro/Container Carrier', value: 'Ro Ro/Container Carrier' },
  { name: 'Ro Ro/Passenger Ship', value: 'Ro Ro/Passenger Ship' },
  { name: 'Sailing Vessel', value: 'Sailing Vessel' },
  { name: 'Salvage/Rescue Vessel', value: 'Salvage/Rescue Vessel' },
  { name: 'Sand Suction Dredger', value: 'Sand Suction Dredger' },
  { name: 'Sealer', value: 'Sealer' },
  {
    name: 'Self Discharging Bulk Carrier',
    value: 'Self Discharging Bulk Carrier',
  },
  { name: 'Sheerlegs Pontoon', value: 'Sheerlegs Pontoon' },
  { name: 'Shuttle Tanker', value: 'Shuttle Tanker' },
  { name: 'Sludge Carrier', value: 'Sludge Carrier' },
  { name: 'Special Vessel', value: 'Special Vessel' },
  { name: 'Standby Safety Vessel', value: 'Standby Safety Vessel' },
  { name: 'Steam Supply Pontoon', value: 'Steam Supply Pontoon' },
  { name: 'Stone Carrier', value: 'Stone Carrier' },
  { name: 'Suction Dredger', value: 'Suction Dredger' },
  { name: 'Suction Dredger Pontoon', value: 'Suction Dredger Pontoon' },
  { name: 'Suction Hopper Dredger', value: 'Suction Hopper Dredger' },
  { name: 'Supply Jack Up', value: 'Supply Jack Up' },
  { name: 'Supply Tender', value: 'Supply Tender' },
  { name: 'Supply Vessel', value: 'Supply Vessel' },
  { name: 'Support Jack Up', value: 'Support Jack Up' },
  { name: 'Support Vessel', value: 'Support Vessel' },
  { name: 'Tanker Barge', value: 'Tanker Barge' },
  { name: 'Tanker', value: 'Tanker' },
  { name: 'Tender', value: 'Tender' },
  { name: 'Theatre Vessel', value: 'Theatre Vessel' },
  { name: 'Torpedo Recovery Vessel', value: 'Torpedo Recovery Vessel' },
  { name: 'Towing Vessel', value: 'Towing Vessel' },
  { name: 'Tractor Tug', value: 'Tractor Tug' },
  { name: 'Trailing Suction Dredger', value: 'Trailing Suction Dredger' },
  {
    name: 'Trailing Suction Hopper Dredger',
    value: 'Trailing Suction Hopper Dredger',
  },
  { name: 'Training Ship', value: 'Training Ship' },
  { name: 'Trans Shipment Barge', value: 'Trans Shipment Barge' },
  { name: 'Trans Shipment Vessel', value: 'Trans Shipment Vessel' },
  { name: 'Trawler', value: 'Trawler' },
  { name: 'Trenching Support Vessel', value: 'Trenching Support Vessel' },
  { name: 'Troopship', value: 'Troopship' },
  { name: 'Tug', value: 'Tug' },
  { name: 'Tug/Ice Breaker', value: 'Tug/Ice Breaker' },
  { name: 'Tug/Supply Vessel', value: 'Tug/Supply Vessel' },
  { name: 'Tug/Tender', value: 'Tug/Tender' },
  { name: 'Utility Vessel', value: 'Utility Vessel' },
  {
    name: 'Vegetable/Animal Oil Tanker',
    value: 'Vegetable/Animal Oil Tanker',
  },
  { name: 'Vehicles Carrier', value: 'Vehicles Carrier' },
  { name: 'Waste Disposal Vessel', value: 'Waste Disposal Vessel' },
  {
    name: 'Water Jet Dredging Pontoon',
    value: 'Water Jet Dredging Pontoon',
  },
  { name: 'Water Tanker', value: 'Water Tanker' },
  { name: 'Well Stimulation Vessel', value: 'Well Stimulation Vessel' },
  { name: 'Whale Factory', value: 'Whale Factory' },
  { name: 'Whaler', value: 'Whaler' },
  { name: 'Wine Tanker', value: 'Wine Tanker' },
  {
    name: 'Wing In Ground Effect Vessel',
    value: 'Wing In Ground Effect Vessel',
  },
  { name: 'Wood Chips Carrier', value: 'Wood Chips Carrier' },
  { name: 'Work Pontoon', value: 'Work Pontoon' },
  { name: 'Work Vessel', value: 'Work Vessel' },
  { name: 'Yacht', value: 'Yacht' },
];
