import React, { Component } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import { withAuthorization } from '../Session';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import {
  changeMyVesselsData,
  changeMyVesselsStatus,
  changeSelectedData,
  fetchMyAdverts,
} from '../../redux/actions';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TabBar from './TabBar';
// import { data } from "jquery";

const MySwal = withReactContent(Swal);

// const errorType = "Error occurred while fetching your vessels.";

class MyAdverts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      adverts: [],
      details: null,
      viewType: props.viewType,
    };

    this.isExpired = this.isExpired.bind(this);
  }

  isExpired(expire_date) {
    const now = new Date();
    const expiry = new Date(expire_date);
    return now > expiry;
  }

  componentDidMount() {
    const details = JSON.parse(localStorage.getItem('marexUser'));

    this.props.firebase
      .fetchMyAdverts(details.UID)
      .get()
      .then((snapshot) => {
        const data = [];
        snapshot.forEach((pack) => data.push(pack.data()));

        if (data.length > 0) {
          this.setState({ isLoading: false, adverts: data });
          this.props.onfetchAdvertsList(data);
        } else {
          this.setState({ isLoading: false });
          MySwal.fire({
            title: 'Error',
            text: 'You have not run any ad package yet',
            icon: 'error',
          });
        }
        // this.onFetchMyAdverts(res);
      });
    // this.onFetchMyAdverts();
  }

  handleEdit(item) {
    this.props.changeSelectedData(item);
    this.props.history.push(`/edit-vessel/${item.docId}`);
  }

  // onFetchMyAdverts = () => {
  //   const {
  //     myVesselsSearchError,
  //     myVesselsSearchStatus,
  //     myVesselsData,
  //     advertList,
  //   } = this.props;
  //   this.props.
  //   // if(advertList)
  //   const checkError = myVesselsSearchError === "";
  //   const checkList = myVesselsData.length === 0;

  //   if (checkError && !myVesselsSearchStatus && checkList) {
  //     this.setState({ isLoading: true, errorMessage: myVesselsSearchError });
  //     setTimeout(() => this.props.changeMyVesselsStatus(true), 6000);
  //   } else if (!checkError && myVesselsSearchStatus && checkList) {
  //     this.setState({ isLoading: false, errorMessage: myVesselsSearchError });
  //   } else if (!checkList) {
  //     this.setState({ isLoading: false });
  //   }
  // };

  render() {
    const { errorMessage } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb title='Vessels With Adverts' />
        <div className='dg__market__data pt--50 pb--140'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='market__title'>
                  <h2>My Adverts</h2>
                </div>
                <TabBar />
                {this.state.isLoading ? (
                  <Row>
                    <Col sm='5' />
                    <Col sm='3'>
                      <Spinner
                        type='grow'
                        style={{ width: '9rem', height: '9rem' }}
                        color='primary'
                      />
                    </Col>
                  </Row>
                ) : this.state.adverts.length === 0 ? (
                  <Col sm={{ size: 5, offset: 4 }}>
                    <h4 style={{ textAlign: 'center' }}>{errorMessage}</h4>
                    <br />
                    <Col
                      sm={{ size: 12, offset: 2 }}
                      style={{ textAlign: 'center' }}
                    >
                      <button
                        className='btn'
                        onClick={() =>
                          this.props.history.push('/register-vessel')
                        }
                      >
                        Register One
                      </button>
                    </Col>
                  </Col>
                ) : (
                  <div className='container list-t-border'>
                    {this.props.advertList.map((vessel, idx) => {
                      return (
                        <div key={idx} className='bg-hover'>
                          <div className='my-pro-list'>
                            <Row>
                              <div className='col-sm-2 col-md-2 col-xs-12'>
                                <img src={vessel.images[0]} alt='vesselImage' />
                              </div>
                              <div className='col-sm-8 col-md-8 col-xs-12'>
                                <div>
                                  <h4>{vessel.vessel_name}</h4>
                                  <p>{vessel.vesselType}</p>
                                  {this.state.viewType === 'manage' ? (
                                    <span>
                                      <b>Status:</b>{' '}
                                      {vessel.order_confirmed === 0
                                        ? 'Pending Confirmation'
                                        : 'Confirmed'}{' '}
                                      <br />
                                      <strong>Expiry date :</strong>{' '}
                                      <span
                                        className={
                                          this.isExpired(vessel.end_date)
                                            ? 'color-danger'
                                            : 'color-success'
                                        }
                                      >
                                        {vessel.end_date}
                                      </span>
                                      <br />
                                      <strong>Views :</strong>{' '}
                                      <span className='color-success'>
                                        {vessel.view_count}
                                      </span>
                                    </span>
                                  ) : (
                                    <span>
                                      <b>Status:</b>{' '}
                                      {vessel.order_confirmed === 0
                                        ? 'Pending Confirmation'
                                        : 'Confirmed'}{' '}
                                      <br />
                                      <strong>Verification:</strong>{' '}
                                      <span
                                        className={
                                          vessel.disabled
                                            ? 'color-danger'
                                            : 'color-success'
                                        }
                                      >
                                        {vessel.order_confirmed
                                          ? 'Pending'
                                          : 'Verified'}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='col-md-2 col-sm-2 col-xs-12'>
                                {/* <div className="select-pro-list">
                                  <span
                                    // onClick={() => this.handleEdit(vessel)}
                                    className="controls-list"
                                  >
                                    <FaEdit />
                                  </span>
                                  <span
                                    onClick={() => this.handleDelete(vessel)}
                                    className="controls-list"
                                  >
                                    <AiFillDelete />
                                  </span>
                                  {!vessel.disabled && (
                                    <span
                                      onClick={() =>
                                        this.onCreateAdvert(vessel)
                                      }
                                      className="controls-list"
                                    >
                                      <FaAd />
                                    </span>
                                  )}
                                </div> */}
                              </div>
                            </Row>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  advertList: state.advert.adverts,
});

const mapDispatchToProps = (dispatch) => ({
  onfetchAdvertsList: (adverts) => {
    dispatch(fetchMyAdverts(adverts));
  },
});

const condition = (authUser) => !!authUser;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withAuthorization(condition),
  withFirebase
)(MyAdverts);
