import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row, Spinner } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { FcAbout } from 'react-icons/fc';
import { v1 as uuidv1 } from 'uuid';
import { withFirebase } from '../Firebase';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import VesselImages from './RegisterVesselImage';
import LetterOfAuth from './LetterOfAuth';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import { useSelector } from 'react-redux';
import { GiShipWheel } from 'react-icons/gi';
import { CgProfile } from 'react-icons/cg';
import MetaTags from 'react-meta-tags';
import { FaShip } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { compose } from 'recompose';
import 'react-datepicker/dist/react-datepicker.css';
import { withAuthorization } from '../../components/Session';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';
import TabBar from './TabBar';
import { VESSELS } from '../../utils';

const MySwal = withReactContent(Swal);

const RegisterVessel = (props) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [yearBuilt, setYearBuilt] = useState(null);
  const [engineYear, setEngineYear] = useState(null);
  const history = useHistory();
  const [details] = useState(JSON.parse(localStorage.getItem('marexUser')));
  const [loading, setLoading] = useState(false);
  const [brokerAcc, setBrokerAcc] = useState(false);
  const authLetterBlob = useSelector((store) => store.vessel.authLetter);
  let urls = [];
  let imageBlobs = [];
  let vesselImages = [];
  const accountType = details.accountType;

  useEffect(() => {
    if (accountType === 'Broker') {
      setBrokerAcc(true);
    }
  }, [accountType]);

  const uploadVesselDocsPromise = (docFile, vesselId) => {
    const path = `vessels/${vesselId}/docs/`;
    return new Promise((resolve, reject) => {
      if (!docFile) return reject('No Vessel document to upload');
      props.firebase.storage
        .ref(path + docFile.name)
        .put(docFile)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL().then((downloadURL) => {
            urls.push(downloadURL);
            console.log('docs', downloadURL);
            resolve(downloadURL);
          });
        });
    });
  };

  var getFileBlob = async function (url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.addEventListener('load', function () {
      cb(xhr.response);
    });
    xhr.send();
  };

  const deleteFolderContents = async (path) => {
    const ref = props.firebase.storage.ref(path);
    ref
      .listAll()
      .then((dir) => {
        dir.items.forEach((fileRef) => {
          deleteFile(ref.fullPath, fileRef.name);
        });
        dir.prefixes.forEach((folderRef) => {
          deleteFolderContents(folderRef.fullPath);
        });
      })
      .catch((error) => {
        toast.notify(`${error.message}`, {
          duration: null,
        });
      });
  };

  const deleteFile = (pathToFile, fileName) => {
    const ref = props.firebase.storage.ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete();
  };

  const uploadVesselImgsPromise = (imgFile, vesselId) => {
    const path = `vessels/${vesselId}/images/`;
    return new Promise((resolve, reject) => {
      if (!imgFile) return reject('No Vessel image to upload');
      props.firebase.storage
        .ref(path + imgFile.name)
        .put(imgFile)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL().then((downloadURL) => {
            vesselImages.push(downloadURL);
            resolve(downloadURL);
          });
        });
    });
  };

  const handleVesselImagesChange = (images) => {
    imageBlobs = images;
  };

  const uploadLetter = (imgURL, vesselId) => {
    return new Promise(async (resolve, reject) => {
      if (!imgURL) return reject(false);
      await getFileBlob(imgURL, (blob) => {
        let path = '';
        if (blob.type.endsWith('pdf')) {
          path = `vessels/${vesselId}/docs/authLetter.pdf`;
        } else if (blob.type.endsWith('jpeg') || blob.type.endsWith('png')) {
          path = `vessels/${vesselId}/docs/authLetter.jpeg`;
        }

        const upload = props.firebase.storage.ref(path).put(blob);
        upload.on(
          'state_changed',
          () => [],
          (err) => {
            reject(err);
          },
          async () => {
            await upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    });
  };

  const onSubmit = async (data) => {
    const existingVessel = await props.firebase.checkVessel(data.imo_number);

    if (existingVessel) {
      return MySwal.fire({
        title: 'Error',
        text: `The vessel with the IMO NUMBER ${data.imo_number} already exists.`,
        icon: 'error',
      });
    }

    setLoading(true);
    const vesselId = uuidv1();
    data.vesselId = vesselId;

    if (brokerAcc) {
      await uploadLetter(authLetterBlob, vesselId)
        .then((url) => {
          data.authorization_letter = url;
        })
        .catch((error) => console.log('letter upload error: ', error));
    }

    if (acceptedFiles.length === 0 || imageBlobs.length === 0) {
      return MySwal.fire({
        title: 'Error',
        text: `An image of the vessel and a Letter of Authorisation has to be uploaded`,
        icon: 'error',
      });
    } else {
      //upload documents
      Promise.all(
        acceptedFiles.map((item) => uploadVesselDocsPromise(item, vesselId))
      )
        .then(async () => {
          //upload images
          return Promise.all(
            imageBlobs.map((imgItem) =>
              uploadVesselImgsPromise(imgItem, vesselId)
            )
          )
            .then(() => {
              data.documents = urls;
              data.images = vesselImages;
              data.vessel_name = data.vessel_name.trim();
              data.decks = Number(data.decks);
              data.masts = Number(data.masts);
              data.year_built = Number(moment(yearBuilt).format('YYYY'));
              data.engines = Number(data.engines);
              data.engines_year = Number(moment(engineYear).format('YYYY'));
              data.engineOutput = Number(data.engineOutput);
              data.length = Number(data.length);
              data.breadth = Number(data.breadth);
              data.moulded_depth = Number(data.moulded_depth);
              data.gross_tonnage = Number(data.gross_tonnage);
              data.net_tonnage = Number(data.net_tonnage);
              data.deadweight = Number(data.deadweight);
              data.lightship_weight = Number(data.lightship_weight);
              data.createdAt = props.firebase.timestamp;
              data.disabled = true;
              data.accountId = details.UID;
              props.firebase
                .registerVessel(data)
                .then(() => {
                  setLoading(false);
                  MySwal.fire({
                    title: 'Vessel Registered!',
                    text:
                      'Your vessel has been created, our team will now verify its details before it will be available on the platform.',
                    confirmButtonText: 'Manage Fleet',
                    icon: 'success',
                  }).then(() => {
                    reset();
                    files = [];
                    history.push('/manage-fleet');
                  });
                })
                .catch(async (error) => {
                  const imgPath = `vessels/${vesselId}/images/`;
                  const docPath = `vessels/${vesselId}/docs/`;
                  await deleteFolderContents(imgPath);
                  await deleteFolderContents(docPath);

                  MySwal.fire({
                    title: 'Error',
                    text: `An error occured while trying to register your vessel. ${error.message}`,
                    icon: 'error',
                  });
                });
            })
            .catch((error) => {
              MySwal.fire({
                title: 'Error',
                text: `An error occured while uploading your vessel images. ${error.message}`,
                icon: 'error',
              });
            });
        })
        .catch((error) => {
          MySwal.fire({
            title: 'Error',
            text: `An error occured while uploading your vessel documents. ${error.message}`,
            icon: 'error',
          });
        });
    }
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
    maxFiles: 2,
    maxSize: 1 * 1024 * 1024,
  });

  let files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register Vessel | Oceanic Links</title>
        <meta name='description' content='Register a vessel' />
      </MetaTags>
      <Breadcrumb title='Register a Vessel' />
      <div className='dg__market__data pt--50 pb--140'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='market__title'>
                <h2>Dashboard</h2>
              </div>
              <TabBar />
              <Col md='8' sm={{ size: 10, offset: 2 }}>
                <h4>Flag and Class Info</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className='currency__box'>
                    <Col sm='6'>
                      <div className='input__box name'>
                        <span>Vessel Name</span>
                        <input
                          type='text'
                          name='vessel_name'
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.vessel_name && (
                          <span className='errorText'>
                            <i className='fas fa-exclamation-triangle'></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div className='input__box name'>
                        <span>Port of Registry</span>
                        <input
                          type='text'
                          name='port'
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.port && (
                          <span className='errorText'>
                            <i className='fas fa-exclamation-triangle'></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div className='input__box name'>
                        <span>Flag</span>
                        <input
                          type='text'
                          name='flag'
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.flag && errors.flag.type === 'required' && (
                          <span className='errorText'>
                            <i className='fas fa-exclamation-triangle'></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div className='input__box name'>
                        <span>Official Number</span>
                        <input
                          type='text'
                          name='official_number'
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.official_number &&
                          errors.official_number.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div className='input__box name'>
                        <span>Type of Vessel</span>
                        <select
                          className='dropdown'
                          name='vesselType'
                          ref={register({
                            required: true,
                          })}
                        >
                          <option value=''>- Select -</option>
                          {VESSELS.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.vesselType &&
                          errors.vesselType.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div className='input__box name'>
                        <span>Status</span>
                        <select
                          className='dropdown'
                          name='status'
                          ref={register({
                            required: true,
                          })}
                        >
                          <option value=''>- Select -</option>
                          <option value='For Lease'>For Lease</option>
                          <option value='For Sale'>For Sale</option>
                        </select>
                        {errors.status && errors.status.type === 'required' && (
                          <span className='errorText'>
                            <i className='fas fa-exclamation-triangle'></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div className='input__box name'>
                        <span>Call Sign</span>
                        <input
                          type='text'
                          name='call_sign'
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.call_sign &&
                          errors.call_sign.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div className='input__box name'>
                        <span>IMO Number</span>
                        <input
                          type='text'
                          name='imo_number'
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.imo_number && (
                          <span className='errorText'>
                            <i className='fas fa-exclamation-triangle'></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div className='input__box name'>
                        <span>Previous Name</span>
                        <input
                          type='text'
                          name='previous_name'
                          ref={register()}
                        />
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div className='input__box name'>
                        <span>Previous Flag</span>
                        <input
                          type='text'
                          name='previous_flag'
                          ref={register()}
                        />
                      </div>
                    </Col>
                    <Col sm='6'>
                      <div className='input__box name'>
                        <span>Classification Society (if any)</span>
                        <input
                          type='text'
                          name='class_society'
                          ref={register()}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className='currency__box'>
                    <Col sm='12'>
                      <h4>Vessel documents</h4>
                    </Col>
                    <Col sm='12'>
                      <div className='input__box name'>
                        <span>
                          Images of the Vessel{' '}
                          <FcAbout data-tip='a maximum of 5 images of less than 1MB each can be uploaded'></FcAbout>
                          <ReactTooltip
                            place='top'
                            type='dark'
                            effect='solid'
                          />
                        </span>
                        <VesselImages
                          onVesselImagesChange={handleVesselImagesChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className='currency__box'>
                    <Col sm='12'>
                      <div className='input__box name'>
                        <span>
                          Certificate of Flag Registry and Classification
                          Society Certificate (if any){' '}
                          <FcAbout data-tip='only images or pdf files of less than 1MB are allowed'></FcAbout>
                          <ReactTooltip
                            place='top'
                            type='dark'
                            effect='solid'
                          />
                        </span>
                        <section className='container'>
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            {isDragAccept && <p>Drop it</p>}
                            {isDragReject && <p>This file will be rejected</p>}
                            {!isDragActive && (
                              <p>
                                Drag and drop your documents here, or click to
                                select files
                              </p>
                            )}
                          </div>
                          <aside>
                            <ul>{files}</ul>
                          </aside>
                        </section>
                      </div>
                    </Col>
                  </Row>
                  {brokerAcc && (
                    <Row className='currency__box'>
                      <Col sm='12'>
                        <div className='input__box name'>
                          <span>
                            Letter of authorisation to list vessel{' '}
                            <FcAbout data-tip='only images or pdf files of less than 1MB are allowed'></FcAbout>
                            <ReactTooltip
                              place='top'
                              type='dark'
                              effect='solid'
                            />
                          </span>
                          <LetterOfAuth />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className='currency__box'>
                    <Col sm='12'>
                      <h4>Build Info</h4>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>Number of Decks</span>
                        <input
                          type='number'
                          min={0}
                          max={100}
                          name='decks'
                          ref={register({ required: true })}
                        />
                        {errors.decks && errors.decks.type === 'required' && (
                          <span className='errorText'>
                            <i className='fas fa-exclamation-triangle'></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>Number of Masts</span>
                        <input
                          type='number'
                          min={0}
                          max={100}
                          name='masts'
                          ref={register({ required: true })}
                        />
                        {errors.masts && errors.masts.type === 'required' && (
                          <span className='errorText'>
                            <i className='fas fa-exclamation-triangle'></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>Year Built</span>
                        <DatePicker
                          onChange={(date) => setYearBuilt(date)}
                          selected={yearBuilt}
                          maxDate={new Date()}
                          showYearPicker
                          dateFormat='yyyy'
                          value={yearBuilt}
                          name='year_built'
                          ref={register({ required: true })}
                        />
                        {errors.year_built &&
                          errors.year_built.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>Place Built</span>
                        <input
                          type='text'
                          name='place_built'
                          ref={register({ required: true })}
                        />
                        {errors.place_built &&
                          errors.place_built.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>Builders</span>
                        <input
                          type='text'
                          name='builders'
                          ref={register({ required: true })}
                        />
                        {errors.builders &&
                          errors.builders.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>Material of Build</span>
                        <input
                          type='text'
                          name='build_material'
                          ref={register({ required: true })}
                        />
                        {errors.build_material &&
                          errors.build_material.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>Number of Engines</span>
                        <input
                          type='number'
                          min={0}
                          max={20}
                          name='engines'
                          ref={register({ required: true })}
                        />
                        {errors.engines && errors.engines.type === 'required' && (
                          <span className='errorText'>
                            <i className='fas fa-exclamation-triangle'></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>Type of Engines</span>
                        <input
                          type='text'
                          name='engine_type'
                          ref={register({ required: true })}
                        />
                        {errors.engine_type &&
                          errors.engine_type.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>Year of Engines Built</span>
                        <DatePicker
                          onChange={(date) => setEngineYear(date)}
                          selected={engineYear}
                          maxDate={new Date()}
                          showYearPicker
                          dateFormat='yyyy'
                          value={engineYear}
                          name='engines_year'
                          ref={register({ required: true })}
                        />
                        {errors.engines_year &&
                          errors.engines_year.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>Place of Engines Built</span>
                        <input
                          type='text'
                          name='engines_place_built'
                          ref={register({ required: true })}
                        />
                        {errors.engines_place_built &&
                          errors.engines_place_built.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>Engine Make</span>
                        <input
                          type='text'
                          name='engine_make'
                          ref={register({ required: true })}
                        />
                        {errors.engine_make &&
                          errors.engine_make.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>
                          Engine Output <i>(kW)</i>
                        </span>
                        <input
                          min={0}
                          type='number'
                          name='engineOutput'
                          ref={register({ required: true })}
                        />
                        {errors.engineOutput &&
                          errors.engineOutput.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                  </Row>
                  <Row className='currency__box'>
                    <Col sm='12'>
                      <h4>Tonnage</h4>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>
                          Length Overall <i>(m)</i>
                        </span>
                        <input
                          min={0}
                          type='number'
                          name='length'
                          ref={register({ required: true })}
                        />
                        {errors.length && errors.length.type === 'required' && (
                          <span className='errorText'>
                            <i className='fas fa-exclamation-triangle'></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>
                          Breadth <i>(m)</i>
                        </span>
                        <input
                          min={0}
                          type='number'
                          name='breadth'
                          ref={register({ required: true })}
                        />
                        {errors.breadth && errors.breadth.type === 'required' && (
                          <span className='errorText'>
                            <i className='fas fa-exclamation-triangle'></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm='4'>
                      <div className='input__box name'>
                        <span>
                          Moulded Depth <i>(m)</i>
                        </span>
                        <input
                          type='number'
                          min={0}
                          name='moulded_depth'
                          ref={register({ required: true })}
                        />
                        {errors.moulded_depth &&
                          errors.moulded_depth.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='3'>
                      <div className='input__box name'>
                        <span>Gross Tonnage</span>
                        <input
                          type='number'
                          min={0}
                          name='gross_tonnage'
                          ref={register({ required: true })}
                        />
                        {errors.gross_tonnage &&
                          errors.gross_tonnage.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='3'>
                      <div className='input__box name'>
                        <span>Net Tonnage</span>
                        <input
                          type='number'
                          min={0}
                          name='net_tonnage'
                          ref={register({ required: true })}
                        />
                        {errors.net_tonnage &&
                          errors.net_tonnage.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='3'>
                      <div className='input__box name'>
                        <span>Deadweight</span>
                        <input
                          type='number'
                          min={0}
                          name='deadweight'
                          ref={register({ required: true })}
                        />
                        {errors.deadweight &&
                          errors.deadweight.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    <Col sm='3'>
                      <div className='input__box name'>
                        <span>Lightship Weight</span>
                        <input
                          type='number'
                          min={0}
                          name='lightship_weight'
                          ref={register({ required: true })}
                        />
                        {errors.lightship_weight &&
                          errors.lightship_weight.type === 'required' && (
                            <span className='errorText'>
                              <i className='fas fa-exclamation-triangle'></i>{' '}
                              This field is required
                            </span>
                          )}
                      </div>
                    </Col>
                    {loading ? (
                      <Col sm={{ size: 6 }} className='mt-3'>
                        <Spinner color='primary' />
                      </Col>
                    ) : (
                      <Col>
                        <div className='submit__btn'>
                          <button type='submit'>Submit</button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </form>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition)
)(RegisterVessel);
