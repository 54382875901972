import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './helpers/ScrollToTop';
import './md-styles.css';
import routes from './routes';
import 'react-multi-carousel/lib/styles.css';

import { withAuthentication } from '../src/components/Session';
import Layout from '../src/layouts';

function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => <Component {...props} />} />
  );

  function withLayout(WrappedComponent) {
    return class extends React.Component {
      render() {
        return (
          <Layout>
            <WrappedComponent></WrappedComponent>
          </Layout>
        );
      }
    };
  }
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop>
          <Switch>
            {routes.map((route, idx) => (
              <PrivateRoute
                path={route.path}
                component={withLayout(route.component)}
                key={idx}
              />
            ))}
          </Switch>
        </ScrollToTop>
      </Router>
    </React.Fragment>
  );
}

export default withAuthentication(App);
