import {
  GET_TOKEN,
  SET_TEST,
  SET_TOKEN,
  GET_INCORP_CERT_BLOB_IMG,
  GET_CAC7_BLOB_IMG,
  CHANGE_REGISTRATION_LOADING,
  SHOW_OTP_MODAL,
  SET_CAPTCHA_ID,
} from '../actions/types';

const initialState = {
  token: '',
  test: '',
  serialToken: '',
  incorpCertBlob: '',
  cac7Blob: '',
  registrationLoading: false,
  showOTPModal: false,
  captchaId: '',
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_TEST:
      return {
        ...state,
        test: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        serialToken: action.payload,
      };
    case GET_INCORP_CERT_BLOB_IMG:
      return {
        ...state,
        incorpCertBlob: action.payload,
      };
    case GET_CAC7_BLOB_IMG:
      return {
        ...state,
        cac7Blob: action.payload,
      };
    case CHANGE_REGISTRATION_LOADING:
      return {
        ...state,
        registrationLoading: action.payload,
      };
    case SHOW_OTP_MODAL:
      return { ...state, showOTPModal: action.payload };
    case SET_CAPTCHA_ID:
      return { ...state, captchaId: action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
