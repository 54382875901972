import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        marexUser: JSON.parse(localStorage.getItem('marexUser')),
      };
    }
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        (marexUser) => {
          localStorage.setItem('marexUser', JSON.stringify(marexUser));
          this.setState({ marexUser });
        },
        () => {
          localStorage.removeItem('marexUser');
          this.setState({ marexUser: null });
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.marexUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withFirebase(WithAuthentication);
};

export default withAuthentication;
