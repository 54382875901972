import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from '../../components/Firebase';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  changeVesselsData,
  getVesselsSearchStatus,
  getVesselsSearchError,
  changeVesselsStatus,
} from '../../redux/actions';
import { FETCH_VESSELS_LIMIT } from '../../../src/utils';

class HomeNav extends Component {
  constructor(props) {
    super(props);
    this.unsubscribeVerifiedVessels = null;
  }

  componentDidMount() {
    const { vesselsData } = this.props;
    const checkList = vesselsData.length === 0;
    if (checkList) {
      this.onFetchVerifiedVessels();
    }
  }

  componentDidUpdate(prevProps) {
    const { vesselsStatus } = this.props;

    if (prevProps.vesselsStatus !== vesselsStatus) {
      if (vesselsStatus) {
        this.onFetchVerifiedVessels();
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribeVerifiedVessels = null;
  }

  onFetchVerifiedVessels = async () => {
    this.props.getVesselsSearchError('');
    this.props.getVesselsSearchStatus(false);
    this.props.changeVesselsStatus(false);

    try {
      this.unsubscribeVerifiedVessels = this.props.firebase
        .fetchVerifiedVessels()
        .orderBy('createdAt', 'desc')
        .limit(FETCH_VESSELS_LIMIT)
        .onSnapshot(
          async (querySnapshot) => {
            if (querySnapshot.empty) {
              this.props.getVesselsSearchError(
                'There are no registered vessels.'
              );
              this.props.getVesselsSearchStatus(true);
              this.props.changeVesselsData([]);
            }
            await querySnapshot.docChanges().forEach(
              async (change) => {
                const doc = change.doc;
                const item = doc.data();
                item.docId = doc.id;
                const pastList = [...this.props.vesselsData, item];

                if (change.type === 'added') {
                  const filterList = _.uniqBy(pastList, 'docId');
                  await this.props.changeVesselsData(filterList);
                  this.props.getVesselsSearchStatus(true);
                } else if (change.type === 'removed') {
                  const newData = pastList.filter(
                    (task) => task.docId !== doc.id
                  );
                  const filterList = _.uniqBy(newData, 'docId');
                  await this.props.changeVesselsData(filterList);
                } else if (change.type === 'modified') {
                  const newData = pastList.filter(
                    (task) => task.docId !== doc.id
                  );
                  const newList = [...newData, item];
                  const filterList = _.uniqBy(newList, 'docId');
                  await this.props.changeVesselsData(filterList);
                }
              },
              (err) => {
                this.props.getVesselsSearchError(
                  `Error occurred while fetching vessels.`
                );
                this.props.getVesselsSearchStatus(true);
                this.props.changeVesselsData([]);
              }
            );
          },
          (err) => {
            this.props.getVesselsSearchError(
              `Error occurred while fetching vessels.`
            );
            this.props.getVesselsSearchStatus(true);
            this.props.changeVesselsData([]);
          }
        );
    } catch (e) {
      this.props.getVesselsSearchError(
        `Error occurred while fetching vessels.`
      );
      this.props.getVesselsSearchStatus(true);
      this.props.changeVesselsData([]);
    }
  };

  handleNav = () => {
    const { vesselsData } = this.props;
    const checkList = vesselsData.length === 0;
    if (checkList) {
      this.onFetchVerifiedVessels();
    }
    this.props.history.push('/');
  };

  render() {
    return (
      <li>
        <Link to="#" onClick={() => this.handleNav()}>
          Home
        </Link>
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  vesselsData: state.vessel.vesselsData,
  vesselsStatus: state.vessel.vesselsStatus,
});

const mapDispatchToProps = (dispatch) => ({
  changeVesselsData: (vesselsData) => {
    dispatch(changeVesselsData(vesselsData));
  },
  getVesselsSearchError: (vesselsSearchError) => {
    dispatch(getVesselsSearchError(vesselsSearchError));
  },
  getVesselsSearchStatus: (vesselsSearchStatus) => {
    dispatch(getVesselsSearchStatus(vesselsSearchStatus));
  },
  changeVesselsStatus: (vesselsStatus) => {
    dispatch(changeVesselsStatus(vesselsStatus));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withFirebase
)(HomeNav);
