import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Row, Spinner } from "reactstrap";
// import { useDropzone } from "react-dropzone";
// import ReactTooltip from "react-tooltip";
// import { FcAbout } from "react-icons/fc";
import { v1 as uuidv1 } from "uuid";
import { withFirebase } from "../Firebase";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import VesselImages from "./RegisterVesselImage";
// import LetterOfAuth from "./LetterOfAuth";
// import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import { useSelector, useDispatch } from "react-redux";
import { changeSelectedData, getAdvertPackages } from "../../redux/actions";
import { GiShipWheel } from "react-icons/gi";
import { CgProfile } from "react-icons/cg";
import MetaTags from "react-meta-tags";
import { FaCheckCircle, FaShip } from "react-icons/fa";
import { useHistory, Link, useParams } from "react-router-dom";
import { compose } from "recompose";
import { withAuthorization } from "../Session";
import _ from "lodash";
import DatePicker from "react-date-picker";
import TabBar from "./TabBar";

const formatPrice = (number, currency) =>
  new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
    style: "currency",
    currencySign: "accounting",
    currency: currency,
  }).format(number);

// Ad Package selector
const AdvertPackage = ({ item, selectPackage, selected }) => {
  return (
    <div className="select_ad__card">
      <div className="select_ad__body">
        <p className="package_name">{item.name}</p>
        <p className="package_plan">
          <span className="package_price">
            {formatPrice(item.price, item.currency)}
          </span>
          /{item.plan}
        </p>
        <div className="package_perks">
          <ul>
            {item.perks.map((perk, i) => (
              <li key={perk}>
                <FaCheckCircle color="#1852b5" />{" "}
                <span className="perk_title">{perk}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="select_ad__footer">
        <button
          className={`select_ad__button ${selected && "selected"}`}
          onClick={(e) => selectPackage(e, item.id)}
        >
          Select package
        </button>
      </div>
    </div>
  );
};

const MySwal = withReactContent(Swal);

const adSizes = ["small", "medium", "large"];

const CreateAdvert = (props) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset } = useForm();
  const currentVessel = useSelector((store) => store.vessel.selectedData);
  const advertPackages = useSelector((store) => store.advert.advertPackages);
  const history = useHistory();
  const [details] = useState(JSON.parse(localStorage.getItem("marexUser")));

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [size, setSize] = useState(adSizes[0]);
  const [dateError, setDateError] = useState("");
  const [selectedPackageId, selectAdPackage] = useState(null);

  useEffect(() => {
    fecthVesselData(uid);
    fecthAdPackageData();
    if (_.isEmpty(currentVessel)) {
      setLoading(true);
    }
  }, [uid]);

  function fecthVesselData(id) {
    props.firebase
      .fetchVesselDetails(id)
      .get()
      .then((doc) => {
        setLoading(false);

        if (doc.exists) {
          dispatch(changeSelectedData(doc.data()));
        } else {
          props.history.push("/dashboard");
        }
      });
  }

  function fecthAdPackageData() {
    props.firebase
      .fetchAdPackageData()
      .get()
      .then((snapshot) => {
        const data = [];
        snapshot.forEach((pack) => data.push(pack.data()));
        if (data.length > 0) {
          dispatch(getAdvertPackages(data));
        } else {
          // handle error
        }
      });
  }

  const onPackageChange = (e, id) => {
    e.preventDefault();
    if (id === selectedPackageId) return;
    selectAdPackage(id);
  };

  const createAdvert = async (data) => {
    const vesselId = uuidv1();
    data.vesselId = currentVessel.vesselId;
    data.vesselType = currentVessel.vesselType;
    data.id = vesselId;
    data.size = size;
    data.images = currentVessel.images;
    data.vessel_name = currentVessel.vessel_name;
    data.user_id = details.UID;
    data.package_id = selectedPackageId;
    data.order_confirmed = 0;
    data.expired = false;

    data.start_date = startDate.toDateString();
    data.end_date = endDate.toDateString();
    
    setLoading(true);
    props.firebase
      .createVesselAd(data)
      .then((res) => {
        setLoading(false);
        MySwal.fire({
          title: "Vessel Registered!",
          text:
            "Your Advert has been created, our team will now verify its details before it will be available on the platform.",
          confirmButtonText: "Manage Fleet",
          icon: "success",
        }).then(() => {
          reset();
          // files = [];
          history.push("/manage-fleet");
        });
      })
      .catch(async (error) => {
        setLoading(false);
        MySwal.fire({
          title: "Error",
          text: `An error occured while trying to creating your Advert. ${error.message}`,
          icon: "error",
        });
      });
  };

  const onSubmit = async (data) => {
    if (startDate.toDateString() === endDate.toDateString()) {
      return setDateError("The end date is the same as the start date!!!");
    }

    if (!selectedPackageId) return;

    data.vesselId = currentVessel.vesselId;
    const exists = await props.firebase.checkAdvert(data);
    if (exists) {
      return MySwal.fire({
        title: "Error",
        text: "You can't create duplicate Ads",
        icon: "error",
      });
    }

    const ref = uuidv1();
    const adPackage = advertPackages.find(
      (advert) => advert.id === selectedPackageId
    );
    // return;
    window.FlutterwaveCheckout({
      public_key: "FLWPUBK_TEST-40df7c4ea508bed83df0952367be2a88-X",
      tx_ref: ref,
      amount: adPackage.price,
      currency: adPackage.currency,
      country: "NG",
      payment_options: "card,mobilemoney,ussd",
      customer: {
        email: details.email,
        phone_number: details.phone,
        name: details.username,
      },
      callback: function (payment_details) {
        // specified callback function
        if (payment_details.status === "successful") {
          createAdvert({ payment_details, ...data });
        }
      },
      // customizations: {
      //   title: "My store",
      //   description: "Payment for items in cart",
      //   logo: "https://assets.piedpiper.com/logo.png",
      // },
    });
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Create Advert | Marex</title>
        <meta name="description" content="Register a vessel" />
      </MetaTags>
      <div className="dg__market__data pt--130 pb--140">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="market__title">
                <h2>Dashboard</h2>
              </div>
              <TabBar />
              <Col md="8" sm={{ size: 10, offset: 2 }}>
                <h4>Flag and Class Info</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    {advertPackages.map((adPackage) => (
                      <Col key={adPackage.id} sm="12" md="4" lg="4">
                        <AdvertPackage
                          item={adPackage}
                          selected={selectedPackageId === adPackage.id}
                          selectPackage={onPackageChange}
                        />
                      </Col>
                    ))}
                  </Row>
                  <br />
                  <Row className="currency__box">
                    <Col sm="6">
                      <div className="input__box name">
                        <span>Vessel Name</span>
                        <input
                          type="text"
                          name="vessel_name"
                          defaultValue={currentVessel.vessel_name}
                          disabled
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.vessel_name && (
                          <span className="errorText">
                            <i className="fas fa-exclamation-triangle"></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="input__box name">
                        <span>Advert size</span>
                        <select
                          className="dropdown"
                          name="size"
                          ref={register({
                            required: true,
                          })}
                          onChange={(e) => setSize(e.target.value)}
                        >
                          {adSizes.map((size, i) => (
                            <option key={size} value={size} >
                              {size}
                            </option>
                          ))}
                        </select>
                        {errors.size && (
                          <span className="errorText">
                            <i className="fas fa-exclamation-triangle"></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="input__box name">
                        <span>Start date</span>
                        <DatePicker
                          onChange={setStartDate}
                          minDate={new Date()}
                          value={startDate}
                        />
                        {/* <input
                          type="number"
                          name="duration"
                          min={30}
                          placeholder="Enter duration in seconds"
                          ref={register({
                            required: true,
                          })}
                        /> */}
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="input__box name">
                        <span>End date</span>
                        <DatePicker
                          onChange={setEndDate}
                          minDate={startDate}
                          value={endDate}
                        />
                        {dateError && (
                          <span className="errorText">
                            <i className="fas fa-exclamation-triangle"></i>
                            {dateError}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="input__box name">
                        <span>Type of Vessel</span>
                        <select
                          className="dropdown"
                          name="vesselType"
                          ref={register({
                            required: true,
                          })}
                          defaultValue={currentVessel.vesselType}
                          disabled
                        >
                          <option value={currentVessel.vesselType}>
                            {currentVessel.vesselType}
                          </option>
                        </select>
                        {errors.vesselType && (
                          <span className="errorText">
                            <i className="fas fa-exclamation-triangle"></i> This
                            field is required
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="currency__box">
                    {loading ? (
                      <Col sm={{ size: 6 }} className="mt-3">
                        <Spinner color="primary" />
                      </Col>
                    ) : (
                      <Col>
                        <div className="submit__btn">
                          <button type="submit">Make Payments</button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </form>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition)
)(CreateAdvert);
